const langRU = {
  langText: "Russian",

  /**
   * İlk açılan pop-up içeriği
   */

  definePopup: {
    title: "Мы определяем наиболее подходящие для вас функции.",
    description:
      "Хотели бы вы использовать нашу функцию выставления счетов и учета в дополнение к календарю встреч в своем бизнесе?",
    buttons: {
      approve: "Да",
      cancel: "Нет",
    },
    succesMessage:
      "Ваши предпочтения успешно сохранены. Пожалуйста, войдите снова.",

    appTitleOnBrowser: "🕐 Управляйте своим временем эффективно! - "
  },

  /**
   * Giriş sayfasının bulunduğu key.
   * https://app.salonrandevu.com/login
   */
  login: {
    title: "Авторизоваться",
    mailPhoneInputLabel: "Электронная почта / номер телефона",
    passInputLabel: "Пароль",
    loginButtonTitle: "Авторизоваться",
    registerButtonTitle: "У вас нет аккаунта? Зарегистрируйтесь сейчас!",
    forgotPassButtonTitle: "я забыл мой пароль",
    rememberMe: "Запомни меня",
    loginWithGoogle: "Войти через Google",
    loginwithApple: "Войти через Apple",

    license: {
      // Modal
      title: "Срок использования вашей лицензии истек!",
      description:
        "Уважаемая {company}, срок использования вашей панели истек. Вы можете продолжить пользоваться панелью, купив тот пакет, который, по вашему мнению, подойдет для вашего бизнеса.",
      buttonTitle: "Позвоните нам, чтобы купить пакеты - 0(212) 706 0510",

      standart: {
        type: "МАЛЫЙ БИЗНЕС",
        title: "СТАНДАРТ",
        items: [
          "Неограниченные встречи",
          "Страница назначения для вас",
          "Панель управления назначениями",
          "Профиль Компании",
          "Профиль сотрудника",
        ],
      },

      premium: {
        type: "СРЕДНИЕ И КРУПНЫЕ ПРЕДПРИЯТИЯ",
        title: "ПРЕМИУМ",
        items: [
          "Все функции стандартного пакета",
          "Управление билетами",
          "Доход - отслеживание расходов",
          "Система клиентских баллов",
          "Анализ удовлетворенности клиентов",
        ],
      },
    },

    downloadApp: "Загрузите приложение прямо сейчас, отсканировав QR-код.",

    loginErrorToast: "Операция не удалась! Проверьте свои учетные данные и попробуйте снова.",
  },
  /**
   * login vaidate bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  validation: {
    enterField: "Пожалуйста, заполните обязательные поля.",
    wrongEmailOrPassword:
      "Введенный вами адрес электронной почты или номер телефона не привязан к учетной записи.",
    wrongPassword: "Введенный вами пароль неверен.",
  },
  /**
   * Kayıt sayfasının bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  register: {
    title: "регистр",
    trialText: "7 дней все функции абсолютно БЕСПЛАТНЫ!",
    noPaymentText: "Без оплаты, без обязательств, без платы за отмену.",
    privacyPolicyText: "Я прочитал и принимаю Политику конфиденциальности.",

    continueButtonTitle: "Продолжай",
    completeButtonTitle: "Завершить регистрацию",
    alreadyUserButtonTitle: "Уже вступил? Войти!",

    companyNameInputLabel: "Наименование фирмы",
    phoneInputLabel: "Телефонный номер",
    campaingCodeInputLabel: "Если у вас есть код кампании, введите его",
    staffNameInputLabel: "Авторизованное имя",
    staffSurnameInputLabel: "Авторизованная фамилия",
    staffMailInputLabel: "Адрес электронной почты",
    passwordInputLabel: "Пароль",
    verificationCode: "Код подтверждения успешно отправлен на номер!",
    registerError:
      "Не удалось создать запись! Пожалуйста, проверьте свои данные и попробуйте снова.",
    registerNavigate:
      "Ваша запись успешно создана! Вы перенаправляетесь в панель.",
    registerErrorSecond:
      "Не удалось выполнить операцию! Проверьте свои данные для входа и попробуйте снова.",
    registerAlreadyExist: "Запись с этой электронной почтой уже существует!",
    fieldCompany: "Название компании",
    fieldNumber: "Номер телефона",
    fieldStaffName: "Имя ответственного лица",
    fieldStaffSurname: "Фамилия ответственного лица",
    fieldStaffEmail: "Адрес электронной почты",
    fieldStaffPassword: "Пароль",
    fieldVerificationCode: "Код подтверждения",
    securityWarning: "Принятие соглашения о конфиденциальности обязательно!",
    approveCode: "Код подтверждения",
    smsSubmit: "SMS верификация",
    finishRegister: "Завершить регистрацию",
  },

  /**
   * Şifremi unuttum sayfasının bulunduğu key.
   * https://app.salonrandevu.com/forgot-pass
   */
  forgotPass: {
    title: "Забыли пароль и сброс",

    stepText: "ADIM",
    firstStepText:
      "На этом этапе ваш номер телефона, связанный с вашим профилем сотрудника, должен быть введен в поле ниже. Вы можете создать новый пароль, введя код подтверждения в SMS-сообщении, которое мы отправим на ваш телефон на следующем шаге.",
    secondStepText:
      "Мы отправили код подтверждения в виде SMS на указанный вами номер телефона. Вы можете обновить свой пароль, введя новый пароль и код подтверждения, отправленный вам в поля ниже.",

    continueButtonTitle: "Продолжай",
    backToHomeButtonTitle: "Вернуться на главную страницу",
    updatePassButtonTitle: "Обновить пароль",

    phoneInputLabel: "Телефонный номер",
    verificationCodeInputLabel: "Проверочный код",
    newPassInputLabel: "Ваш новый пароль",

    verificationSuccessfullySentToast: "Код подтверждения успешно отправлен.",
    verificationErrorToast: "Не удалось отправить код подтверждения.",
    validPhoneErrorToast: "Пожалуйста, введите свой полный номер телефона.",
    passwordSuccessfullyChangedToast: "Ваш пароль был успешно изменен.",
    warningStatusToast: "Неверный статус, ваш пароль не может быть изменен.",
  },

  /**
   * Panel içerisindeki sol menü ve alt elemanların bulunduğu key.
   */
  menuItems: {
    dashboard: { title: "Обзор" },
    profile: { title: "Управление профилями" },
    wallet: { title: "Балансовые операции" },
    notify: { title: "Уведомление об оплате" },
    todos: { title: "Список дел" },

    appointment: {
      title: "Управление назначением",
      children: {
        index: "Календарь встреч",
        filter: "Список приёмов",
        online: "Онлайн-запросы на прием",
        repeat: "Повторяющиеся встречи",
        add: "+ Создать новую встречу",
      },
    },
    receipt: {
      title: "Управление билетами",
      children: {
        index: "Список билетов",
        add: "+ Создать новый билет",
        recordAdd: "Создание счетов для зарегистрированного клиента",
        newAdd: "Создать заявку для нового клиента",
      },
    },
    packages: {
      title: "Управление пакетами",
      children: {
        sales: "Список продаж пакетов",
        salesAdd: "+ Создать новую распродажу пакетов",
        list: "Товарная накладная",
        add: "+ Создать пакет",
        unbookedPackages: "Незапланированные сеансы",
      },
    },
    products: {
      title: "Управление запасами",
      children: {
        index: "Список продуктов",
        add: "+ Создать новый продукт",
        addWithoutPlus: "Создать новый продукт",
      },
    },
    customers: {
      title: "Транзакции клиентов",
      children: {
        active: "Список клиентов",
        passive: "Пассивные клиенты",
        risky: "Потерянные клиенты",
        rating: "Комментарии клиентов",
        add: "+ Создать нового клиента",
        addBulk: "+ Создавайте массовых клиентов с помощью Excel",
      },
    },
    services: {
      title: "Управление услугами",
      children: {
        index: "Перечень услуг",
        add: "+ Создать новую услугу",
        requests: "Запросы имени службы",
        bounty: "Создать пользовательский бонус",
      },
    },
    employees: {
      title: "Управление персоналом",
      children: {
        index: "Список сотрудников",
        add: "+ Создать нового сотрудника",
      },
    },
    sms: {
      title: "Message-управление",
      children: {
        index: "Message-управление",
        sendSelective: "Отправить массовую рассылку Message",
        sendFilter: "Отправить отфильтрованное Message",
        sendGroup: "Отправить Message в группы",
        settings: "Настройки СМС",
        blacklist: "Черный список Message",
        template: "Настройки шаблона",
        wpSettings: "настройки WhatsApp",
      },
    },
    accounting: {
      title: "Управление бухгалтерским учетом",
      children: {
        income: "Операции с доходом",
        debt: "Долговые операции",
        bounty: "Премиум-транзакции",
        expense: "Расходные операции",
        expenseTypes: "Статьи расходов",
        credit: "кредит",
        debit: "Списание",
      },
    },
    reports: {
      title: "Расширенные отчеты",
      children: {
        company: "Деловые отчеты",
        service: "Сервисные отчеты",
        stock: "Отчеты о продуктах",
        staff: "Отчеты сотрудников",
        customer: "Отчеты клиентов",
        appointment: "Отчеты о назначении",
      },
    },
    platform: {
      title: "Управление платформой",
      children: {
        photo: "витрина фото",
        settings: "Настройки платформы",
        category_settings: "Настройки категории",
      },
    },
    settings: {
      title: "Системные настройки",
      children: {
        index: "Бизнес-настройки",
        hours: "Рабочие часы",
        gallery: "Бизнес-галерея",
        service_gallery: "Галерея услуг",
        logs: "Записи транзакций",
        branchAdd: "Создать новую ветку",
        giveAReferans: "Стать рекомендателем",
      },
    },
    gallery: {
      title: "Управление галереей",
      children: {
        platform_photo: "витрина фото",
        enterprise_gallery: "Бизнес-галерея",
        service_gallery: "Галерея услуг",
      },
    },
    support: {
      title: "Живая поддержка",
    },
  },

  /**
   * Panel içerisindeki üst menü ve açılan popuplarının bulunduğu key.
   */
  scaffold: {
    translateViewHeading: "СИСТЕМНЫЙ ЯЗЫК",
    logsViewHeading: "СИСТЕМНЫЕ ЖУРНАЛЫ",
    notificationViewHeading: "УВЕДОМЛЕНИЯ",
    quickActionsViewHeading: "БЫСТРЫЕ СДЕЛКИ",

    logsViewAllButtonTitle: "Увидеть все",

    notificationFound: "У вас есть новые уведомления!",
    notFoundNotification: "У вас пока нет уведомлений!",

    QAItems: {
      createCustomer: "Создать нового клиента",
      createAppointment: "Создать новую встречу",
      createReceipt: "Создать новый билет",
      createPackage: "Создать новый пакет",
      createPackageSale: "Распродажа новых пакетов",
      createService: "Создать новую услугу",
      createEmployee: "Создать нового сотрудника",
      showIncomes: "Просмотр доходов",
      showExpenses: "Просмотр расходов",
      showDebts: "Посмотреть долги",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/branches
   */
  branches: {
    heroHeadingText: "Salonappointment Управление несколькими филиалами",
    greetingText: "Добро пожаловать",
    greetingBottomText:
      "Ваши филиалы под вашим управлением перечислены в поле ниже.",
    greetingBottomText2:
      "Вы можете обновить информацию для входа в любую ветку или войти в соответствующую ветку с помощью кнопок в правой части таблицы.",

    logoutButtonTitle: "выход",
    loginBranchButtonTitle: "Вход в филиал",
    editStaffButtonTitle: "Редактировать информацию",
    completeEditingButtonTitle: "Полное редактирование",
    discardEditingButtonTitle: "сдаться",
    createBranchButtonTitle: "Создать ветку",
    licenseExpiredToast: "Пользователь с истекшим сроком действия лицензии",

    tabBranchListTitle: "Список филиалов",
    tabBranchReportsTitle: "Отчеты филиалов",
    tabBranchAddTitle: "+ Создать новую ветку",

    headings: {
      listTable: {
        id: "Бизнес-код",
        companyName: "Название бизнеса/филиала",
        staffFullName: "Авторизованное имя Фамилия",
        staffPhoneNumber: "Авторизованный телефон",
        staffMail: "Авторизованный адрес электронной почты",
        licenseEndDate: "Конец лицензии",
        refresh: "Обновить",
      },
      reportsTable: {
        companyName: "Название бизнеса/филиала",
        staffCount: "количество работников",
        appointmentCount: "Количество назначений",
        receiptCount: "Количество билетов",
        serviceIncome: "Доход от услуг",
        saleIncome: "Доход от продажи продукта",
        staffBounty: "Премиум платный",
        totalIncome: "Общий доход",
        totalExpense: "Общий расход",
        totalCount: "Чистая прибыль",
      },
    },

    createBranch: {
      companyNameInputLabel: "Наименование фирмы",
      staffNameInputLabel: "Авторизованное имя",
      staffSurnameInputLabel: "Авторизованная фамилия",
      commonMailInputLabel: "Адрес электронной почты",
      commonPhoneInputLabel: "Телефонный номер",
      passInputLabel: "Пароль",
    },

    licenseExpiredLoginToast:
      "Срок действия лицензии учетной записи, в которую вы хотите войти, истек.",
    licenseExpiredEditToast:
      "Срок действия лицензии учетной записи, которую вы хотите изменить, истек.",
    staffUpdateToast: "Рабочий успешно обновлен.",
    newBranchSuccessToast: "Новый филиал успешно создан.",
    staffUpdateSuccessToast: "Информация о сотруднике успешно обновлена!",
    updateStaffTitle: " Редактировать сотрудника",

    inputLabels: {
      name: "Имя сотрудника",
      surname: "Фамилия сотрудника",
      mail: "Электронная почта сотрудника",
      phone: "Телефон сотрудника",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/give-reference
   */
  referance: {
    createReferanceButtonTitle: "Стать рекомендателем",
    createReferance: {
      companyNameInputLabel: "Наименование фирмы",
      staffNameInputLabel: "Авторизованное имя и фамилия",
      commonMailInputLabel: "Адрес электронной почты",
      commonPhoneInputLabel: "Телефонный номер",
    },
  },
  platformSettings: {
    approve: "Обновить настройки",
    appointmentApprovelCode: {
      title: "Код подтверждения записи",
      detail:
        "Настройка кода подтверждения для записей, полученных с платформы. Когда эта настройка включена, клиенту будет отправлен код подтверждения при получении записи с платформы.",
    },
    appointmentApprovelCodeType: {
      title: "Тип отправки кода подтверждения записи",
      detail:
        "Настройка отправки для записей, полученных с платформы. Когда эта настройка включена, она указывает, какой тип отправки будет использоваться для отправки клиенту кода подтверждения при получении записи с платформы.",
    },
    appointmentConfirmationDeadline: {
      title: "Срок действия ссылки на запись",
      detail:
        "Эта настройка указывает максимальное количество часов, которое клиент может подтвердить ссылку на запись.",
      startInputLabel: "Срок действия ссылки на запись(часы)",
    },
    confirmationDeadlineSelect: {
      noLimit: "Без ограничения времени",
      hour: "час",
    },
  },

  /**
   * Genel Bakış sayfasının bulunduğu key.
   * https://app.salonrandevu.com/dashboard
   */
  dashboard: {
    accountInstallCard: {
      heading:
        "Вы можете выполнить настройку своей учетной записи, выполнив следующие действия!",
      description:
        "Вы можете перейти на страницу транзакции, нажав на соответствующий шаг.",

      setShift: "Установите свое рабочее время",
      createService: "Создайте свои услуги",
      setServicePicked: "Выберите услуги, предлагаемые сотрудниками",
      createCustomer: "Создайте своих клиентов",
    },

    tutorialModal: {
      startHeading: "Начать настройку учетной записи",
      startDescription:
        "Завершите настройку учетной записи, выполнив системные требования всего за три основных шага.",

      startTutorialButton: "Начать установку",
      dontShowAgainButton: "Больше не показывать",
    },

    descriptionTutorial: {
      prevButtonTitle: "Бывший",
      nextButtonTitle: "Следующий",
      finishButtonTitle: "Закончи это",
    },

    businessURLCard: {
      heading:
        "Легко управляйте своими встречами, делясь своим служебным адресом со своими клиентами!",
      inputLabel: "URL-адрес деловой встречи",
      openBrowserButton: "Открыть в браузере",
      copyLinkButton: "Копировать ссылку",
      copySuccessToast: "Ссылка успешно скопирована!",
    },

    usageCard: {
      heading: "Вы можете легко отследить оставшееся использование ниже.",

      licenseDetail: "Сведения о лицензии",
      packageText: "УПАКОВКА",
      licenseRemaining: "дней осталось",

      smsDetail: "Остаток СМС кредита",

      avaible_appointment_credit: "Остаток кредита встречи",
    },

    stats: {
      appointment: {
        heading: "Статистика приема",
        createdAppointments: "Созданные встречи",
        successAppointments: "Заключительные встречи",
        closedAppointments: "Незавершенные встречи",
      },

      customer: {
        heading: "Статистика клиентов",
        newCustomers: "Новые входящие клиенты",
        oldCustomers: "Входящие старые клиенты",
        appointmentCustomers: "Назначение клиентов",
      },

      accounting: {
        heading: "Статистика дел",
        income: "Общий доход ",
        expense: "Суммарные расходы ",
        debt: "Общий долг ",

        licenseUpgradeText: "Обновите ваш аккаунт!",
      },

      meta: {
        total: "Всего назначений",
        arrived: "Пришел",
        notArrived: "Не пришел",
        waiting: "Ожидающий",
      },
    },

    cardTitle: {
      receipt_income_card_title: "Статистика дел",
      appointment_calender_card_title: "Календарь встреч",
      appointments_card_title: "Сведения о назначении",
    },

    cardDescription: {
      favorites_description:
        "Вы можете создавать собственные ярлыки, помечая наиболее часто используемые вкладки. Вы также можете изменить места карт с помощью перетаскивания.",
      receipt_income_card_description:
        "Вы можете следить за своей ежедневной, 7-дневной или 30-дневной денежной статистикой на графике доходов.",
      appointment_calender_card_description:
        "Нажмите на день, когда вы хотите просмотреть информацию о встрече.",
      appointment_statistic_card_description:
        "Вы можете сообщать о количестве встреч, созданных через платформу, бизнес-ссылку и панель, с помощью статистических данных с ежедневными, 7-дневными или 30-дневными интервалами.",
      appointments_card_description:
        "Вы можете отслеживать количество встреч, которые ваша компания проводит ежедневно, с интервалом в 7 или 30 дней, а также отслеживать, приходят ли ваши клиенты или нет.",
      online_appointment_request_card_description:
        "Вы можете утвердить или отклонить встречу, следуя онлайн-запросам на встречу от ваших клиентов по вашей бизнес-ссылке.",
    },
    PlatformLink: {
      btnText: "Страница бронирования",
      title: "Страница бронирования",
      description:
        "Из этой области вы можете получить доступ к ссылке, необходимой для ваших клиентов, чтобы создать запрос на запись на прием в вашем бизнесе. С этой ссылки ваши клиенты могут создать запрос на запись на прием, введя информацию о сотруднике, услуге, дне и времени.",
      copyLink: "Скопировать ссылку",
      goToLink: "Перейти на страницу",
      linkCopied: "Ссылка скопирована.",
      Qr: "См. QR-код",
    },

    favoritePage: {
      sectionHeading: "Избранное",
    },

    report: {
      company: {
        typeCASH: "Денежные средства",
        typeCARD: "Карта",
        typeEFT: "Банковский перевод/Электронный платеж",
        totalIncome: "Общий доход",
        noDataText:
          "График не может быть отображен, потому что ваши доходы наличными, кредитной/дебетовой картой и денежным переводом/электронным переводом недоступны.",
      },
    },

    appointmentAnalys: {
      platform: "SalonRandevu.com",
      company: "Бизнес-сайт",
      panel: "По бизнесу",
      packageWarningText:
        "Только учетные записи пакета Premium могут просматривать статистику назначений.",
      upgradePackage: "Пакет обновлений",
      noDataText: "Нет ожидающих открытых запросов на квитанции.",
    },

    waitingAppointments: {
      sectionHeading: "Онлайн-запросы на прием",
      headings: {
        appointmentStartDate: "Время встречи",
        customerFullName: "Интересующий клиент",
        staffFullName: "Заинтересованный сотрудник",
        serviceName: "Действие, которое следует предпринять",
      },
      noDataText: "У вас нет ожидающих запросов на запись.",

      buttons: {
        openReceiptTitle: "Открытый билет",
      },
    },

    appointmentList: {
      title: "Сегодняшние записи",
    },

    onlineAppointments: {
      headings: {
        appointmentStartDate: "Дата - время",
        customerFullName: "Связанный клиент",
        customerPhone: "Телефон",
        staffFullName: "Рабочий",
        serviceName: "Услуга",
        note: "примечание",
        customerType: "Тип клиента",
        statistics: "Статистика",
      },

      approveButtonTitle: "Утвердить",
      rejectButtonTitle: "отклонять",

      rejectConfirm: {
        title: "Вы подтверждаете отмену встречи?",
        confirmButtonTitle: "Да",
        discardButtonTitle: "Нет",

        alertBoldText: "Это действие не может быть отменено!",
        alertText:
          "Если вы отклоните этот запрос, вы больше не сможете получить доступ к этому запросу о встрече.",
      },

      approveToast: "Заявка на прием успешно одобрена!",
      rejectToast: "Запрос на встречу успешно отклонен!",
    },
    openAppointments: {
      headings: {
        date: "Дата - времяt",
        customerFullName: "Связанный клиент",
        products: "Продукты",
        serviceName: "Услуга",
        totalPayment: "Общая сумма",
      },
      title: "Открытые билеты",

      approveButtonTitle: "Утвердить",
      rejectButtonTitle: "отклонять",

      rejectConfirm: {
        title: "Вы подтверждаете отмену встречи?",
        confirmButtonTitle: "Да",
        discardButtonTitle: "Нет",

        alertBoldText: "Это действие не может быть отменено!",
        alertText:
          "Если вы отклоните этот запрос, вы больше не сможете получить доступ к этому запросу о встрече.",
      },

      approveToast: "Заявка на прием успешно одобрена!",
      rejectToast: "Запрос на встречу успешно отклонен!",
    },
    creditors: {
      headings: {
        customerFullName: "Связанный клиент",
        type: "Лекарство",
        paymentDate: "Запланированная дата платежа",
        totalPayment: "Общая сумма",
      },

      noDataText: "Нет ожидающих кредиторов.",
      title: "кредиторы",

      approveButtonTitle: "Утвердить",
      rejectButtonTitle: "отклонять",

      rejectConfirm: {
        title: "Вы подтверждаете отмену встречи?",
        confirmButtonTitle: "Да",
        discardButtonTitle: "Нет",

        alertBoldText: "Это действие не может быть отменено!",
        alertText:
          "Если вы отклоните этот запрос, вы больше не сможете получить доступ к этому запросу о встрече.",
      },

      approveToast: "Заявка на прием успешно одобрена!",
      rejectToast: "Запрос на встречу успешно отклонен!",
    },
    birthDay: {
      headings: {
        customerFullName: "Связанный клиент",
        customerPhone: "Номер телефона",
        birthDate: "Дата рождения",
      },
      noDataText: "Нет предстоящих дней рождения.",
      title: "Предстоящие дни рождения",
    },
    openReceiptOnSuccessToast: "Билет на встречу успешно создан!",
    packageTitle: "Первая покупка специальная",
    packageTitleSpan: "Скидки !",
    packageBuyButton: "Купить сейчас",
    packageMoreInfo: "Узнать больше",
    packageCardDetails: [
      {
        title: "Золотой пакет",
        listItems: ["Неограниченное количество записей для 5 сотрудников", "Управление несколькими филиалами", "Персональный менеджер по работе с клиентами", "Галерея бизнеса", "Отправка SMS", "Специальный подарок '250 SMS' для новых участников"],
        price: "₺599/месяц",
        oldPrice: "₺839",
        discount: "₺240 дохода",
      },
      {
        title: "Платиновый пакет",
        listItems: ["Неограниченное количество записей для 7 сотрудников", "Управление записями", "Управление пакетами", "Управление складом", "Управление бонусами", "Специальный подарок '500 SMS' для новых участников"],
        price: "₺849/месяц",
        oldPrice: "₺1199",
        discount: "₺350 дохода",
      },
      {
        title: "Алмазный пакет",
        listItems: ["Неограниченное количество записей для 10 сотрудников", "Добавление электронной подписи", "Управление договорами", "Управление записями", "Управление пакетами", "Специальный подарок '1000 SMS' для новых участников"],
        price: "₺1249/месяц",
        oldPrice: "₺1749",
        discount: "₺500 дохода",
      }
    ],

    packageTitleRenewal: "Пакет обновления",
    packageBuyButtonRenewal: "Купить сейчас",
    packageMoreInfoRenewal: "Узнать больше",
    packageCardDetailsRenewal: [
      {
        title: "Золотой пакет",
        info: "Для компаний, которые хотят управлять записями",
        listItems: ["Неограниченное количество записей для 5 сотрудников", "Управление несколькими филиалами", "Персональный менеджер по работе с клиентами", "Галерея бизнеса", "Отправка SMS", "Специальный подарок '250 SMS' для новых участников"],
        price: "₺839 / месяц",
      },
      {
        title: "Платиновый пакет",
        info: "Для компаний, которые хотят управлять записями",
        listItems: ["Неограниченное количество записей для 7 сотрудников", "Управление записями", "Управление пакетами", "Управление складом", "Управление бонусами", "Специальный подарок '500 SMS' для новых участников"],
        price: "₺1199 / месяц",
      },
      {
        title: "Diamond Paket",
        info: "Для компаний, которые хотят управлять записями",
        listItems: ["Неограниченное количество записей для 10 сотрудников", "Добавление электронной подписи", "Управление договорами", "Управление записями", "Управление пакетами", "Специальный подарок '1000 SMS' для новых участников"],
        price: "₺1749 / месяц",
      }
    ],
  },

  /**
   * Randevu tablosun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments
   */
  appointments: {
    dayInputLabel: "Дата назначения на шоу",
    beforeDayButtonTitle: "Прошлый день",
    afterDayButtonTitle: "Следующий день",
    today: "Сегодня",
    allStaff: "Все работники",
    dayWeekSelectLabel: "День-Неделя-Ежемесячно",
    customerStateSelectLabel: "Фильтр по статусу",

    repeatButtons: {
      showAppointment: 'Просмотр назначений',
      goAppointment: 'Перейти к Биллу',
      cancelAppointment: 'Отменить прием',
      appointmentDate: 'Дата назначения',
      appointmentStatus: 'Статус назначения',
      startEnd: 'Начало-Конец'
    },

    // Salon Randevu
    definitionGreenText: "Билет представляет открытые встречи.",
    definitionRedText:
      "Назначения представляют собой встречи, дата и время которых уже прошли, а тикет не был открыт.",
    definitionYellowText:
      "Дата-время встречи не прошло, и билет представляет встречи, ожидающие открытия.",
    definitionLightText: "Представляет собой входящие и открытые счета.",
    definitionPurpleText: "Пришли и производится обработка.",
    definitionLinkText: "Представляет встречи, которые были записаны через онлайн-ссылку.",
    // En Randevu
    definitionGreenText_en: "Представляет встречи, помеченные как «прибывшие».",
    definitionRedText_en:
      "Представляет встречи, помеченные как «не прибывшие».",
    definitionYellowText_en:
      "Представляет встречи, которые еще не были обработаны и находятся в состоянии «Ожидание».",

    create: {
      title: "Создать встречу на выбранное время",
      completeButtonTitle: "Полный",
      discardButtonTitle: "сдаться",
      notInterval: "Интервал не установлен",
      minutes: "минуты",
      printButtonTitle: "Печать",
      contractButtonTitle: "Договор",

      selectedTimeInputLabel: "День и время приема",
      selectedStaffInputLabel: "Подбор сотрудников",
      selectedServiceInputLabel: "Услуги, предоставляемые работником",
      selectedPackageInputLabel: "Предлагаемые сотрудником пакеты",
      selectedCustomerInputLabel: "Клиент, который будет назначен",
      selectedCustomerInputPlaceholder:
        "Введите в это поле имя или номер телефона клиента.",
      createNewCustomerButtonTitle: "Создать нового клиента",
      createNewWalkinCustomerButtonTitle:
        "Создать незарегистрированного клиента",

      closeAllDayTitle: "Закрыть весь день",

      selecting_service: "Выбор услуги",
      selecting_package: "Выбор пакета",
      select_package_alert:
        "Выбранный пакет содержит удаленные услуги. Пожалуйста, выберите другой пакет!",

      // sendSmsInputLabel: "Уведомление об отправке СМС",
      sendSmsInputLabel: "Отправить SMS-уведомление",
      sendSmsTrue: "Отправь это",
      sendSmsFalse: "Не отправлять",
      description: "объяснение",
      defaultValue: "Выберите клиента",

      groupAppointment: "Массовое создание встреч",
      clockOff: "Отключить часы",
      addEmployeeButtonTitle: "Добавить сотрудника + услугу",

      noteInputLabel: "Записка о встрече (необязательно)",

      onCreateErrorToast: "Существует существующая запись, начинающаяся в то время, когда вы пытаетесь создать!",
      selectStaffWarnToast: "Пожалуйста, сначала выберите сотрудника для записи на прием!",

      onWarningToast:
        "Заполните поля выбора клиента и выбора услуги полностью!",

      noDataText:
        "Чтобы создать массовую встречу, вы должны выбрать как минимум 2 сотрудников и услуги.",
    },

    detail: {
      title: "Сведения о назначении",
      deleteButtonTitle: "Отменить встречу",
      closeButtonTitle: "Закрывать",
      allStaffSelectedTitle: "Все",

      deleteButton: "Удалить",
      editButton: "редактировать",
      clockOffDetail: "Деталь выключения часов",
      clockOffDateInputLabel: "Дата/время закрытия",
      clockOffStaffInputLabel: "Соответствующий персонал",
      clockOffNoteInputLabel: "Объяснение",

      appointmentTime: "Время встречи (минута)",
      appointmentTimeExtension: "Продлить время встречи",
      appointmentalAlertMessage:
        "Ваше время приема было продлено. Вы можете просмотреть текущее время встречи в календаре встреч.",
      appointmentalWarningMessage: "Пожалуйста, введите время встречи.",

      dateInputLabel: "Дата/время встречи",
      serviceInputLabel: "Прикладной сервис",
      staffInputLabel: "Персонал, о котором нужно заботиться",
      customerInputLabel: "Имя-Фамилия клиента",
      customerPhoneInputLabel: "Номер телефона клиента",
      noteInputLabel: "Записка о назначении",
      noteInputPlaceholder: "Введите заметку о записи",
      customerNote: "Примечание для клиентов",
      appointmentStatus: {
        title: "Статус встречи",
        attended: "Пришел",
        notAttended: "Не пришел",
        accepted: "Подтверждено",
        inProcess: "В процессе",
        new: "Новый",


        changeStatusSuccessToast: "Статус встречи успешно изменен.",
      },

      appointmentStatusOnline: "Онлайн записан",
      appointmentStatusTable: "Тип записи",
      appointmentStatusOnlineList: "Онлайн запись",
      appointmentStatusPlatformList: "Запись через платформу",


      statusAttended: "Пришел",
      statusNotAttended: "Не пришел",
      statusAccepted: "Подтверждено",
      statusInProcess: "В процессе",
      statusNew: "Новый",
      repeatButtonTitle: 'Повторяющаяся встреча',
      repeatFrequency: 'Частота повторения',
      repeatCount: 'Количество повторений',
      logButtonTitle: "Перейти к записям о встречах",
      sendSms: "Отправить SMS",
      send: "Отправить",
      cancel: "Отмена",
    },

    deleteConfirm: {
      title: "Подтверждение отмены встречи",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если ваши настройки отправки SMS включены и у вас есть баланс SMS, клиенту будет отправлено SMS об отмене записи.",
    },

    pastCreateErrorToast:
      "Вы пытались назначить встречу на прошедшую дату и время.",
    onMouseOverCardToast:
      "Вы можете нажать на карточку, которую вы хотите, чтобы увидеть подробности встречи.",
    insertToast: "Встреча успешно создана.",
    deleteToast: "Встреча была успешно удалена.",

    weeklyAppointmentsStaffErrorToast:
      "Пожалуйста, выберите сотрудника для еженедельных встреч.",
    weeklyAppointmentsDisplayErrorToast:
      "Чтобы записаться на прием, просмотрите ежедневные встречи.",

    checkButton: "Я понимаю",
    firstLoginAppointmentsTitle: "ДОВЕСТИ ДО СВЕДЕНИЯ",
    firstLoginAppointmentsDescription:
      "Образец информации о персонале и карточка назначения были созданы для вас. Вы можете обновить информацию о персонале и назначениях, нажав на доступные вкладки.",

    updateAppointment: {
      title: "Вы хотите изменить назначенного работника и/или время?",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы обновите текущую встречу, информация о сотруднике и/или часах изменится.",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",

      service: "наименование услуги",
      staff: "Рабочий",
      time: "Час",
      customer: "Клиент",

      updateAppointmentSuccessToast: "Назначение успешно обновлено.",
    },

    orderStaffsButtonTitle: "Сортировать сотрудников",

    rightButtonTooltip: "Перетащите таблицу вправо",
    leftButtonTooltip: "Перетащите стол влево",

    createAppointmentButtonTitle: "Создать встречу",
    appointmentsComp: {
      green: "Зеленые часы обозначают часы, доступные для записи на прием.",
      red: "Красные часы означают, что на этот час уже назначена встреча.",
      purple:
        "Фиолетовые часы могут пересекаться с существующими записями или закрытыми часами при создании записи.",
      gray: "Серые часы означают, что это часы, закрытые для записи, либо это связано с подробной информацией о работнике или календаре встреч.",
    },

    comunicateWithWhatsApp: "Связаться через WhatsApp",
    tooltipExplanations: {
      selectStaff:
        "Здесь вы можете выбрать персонал, которого хотите видеть в календаре",
      staffCount:
        "Здесь вы можете отредактировать количество людей, которых вы хотите видеть на странице",
      appointmentRequest: "Ваши запросы на онлайн-встречу",
      closeToGetAppointment: "Запись на прием закрыта",
      appointmentList: "Ваш список встреч",
      appointmentTypes:
        "Здесь вы можете изменить календарь встреч для просмотра ежедневно, еженедельно или ежемесячно.",
    },
    staffSelect: {
      all: "Все",
    },
    staffViewCount: {
      person3: "вид на 3 человека",
      person5: "вид на 5 человека",
      person7: "вид на 7 человека",
      person9: "вид на 9 человека",
      all: "Tümü",
    },

    repetition: {
      dailyPeriod: "Каждый день",
      twoDayPeriod: "Каждые 2 дня",
      threeDayPeriod: "Каждые 3 дня",
      fourDayPeriod: "Каждые 4 дня",
      fiveDayPeriod: "Каждые 5 дней",
      weeklyPeriod: "Раз в неделю",
      twoWeekPeriod: "Каждые 2 недели",
      threeWeekPeriod: "Каждые 3 недели",
      fourWeekPeriod: "Каждые 4 недели",
      monthlyPeriod: "Каждый месяц",
      fortyFiveDayPeriod: "Каждые 45 дней",
      twoMonthPeriod: "Каждые 2 месяца",
      threeMonthPeriod: "Каждые 3 месяца",
      sixMonthPeriod: "Каждые 6 месяцев",
    },
    gotRepetition: "Повторяющийся",
    cancelRepetition: "Отмена",
    repetitionFrequencyTitle: "Частота повторений",
    repetitionCountTitle: "Количество повторений",

    recurenctModalAccept: "Да",
    recurenctModalRefuse: "Нет",
    recurenctModalCancel: "Отмена",
    recurenctModalWarn: "Это повторяющееся событие. Вы хотите удалить и другие повторяющиеся события?",

    servicesInReceiptWarn: "Чтобы открыть счет, вам необходимо установить интервал сеансов для услуг в пакете.",
    serviceName: "Название услуги",

    selectStaffWarn: "Пожалуйста, выберите сотрудника.",
    onlineAppointmentTitle: "Онлайн запись",

    closeAppointmentClockTimeSuccess: "Успешно добавлено.",
    closeAppointmentClockTimeError: "Произошла ошибка",

    youtubeTitle: "Создание новой записи",
    youtubeTitle2: "Просмотр истории записей",
    youtubeTitle3: "Ежедневный и еженедельный календарь записей",
    updateAppointmentWarn: "Время окончания не может быть ранее времени начала!",
    transferAppointmentWarn: "Перенос закрытых часов невозможен.",
    allDayClosedText: "Закрыто весь день",
    calendarTodayButtonTitle: "Этот месяц",
    descriptionNoteTitle: "Примечание: ",
    saveButtonTitle: "Сохранить",
    quitButtonWarn: "Не забудьте сохранить ваши изменения!",
    routeToReceipt: "Перейти к счету",
    openReceipt: "Открыть счет",
    permissionWarn: "Чтобы использовать эту функцию, вы должны купить пакет Premium.",
    historyTitle: "История записей",
    appliedPackageTitle: "Применяемый пакет",
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/filter
   */
  "appointments/filter": {
    alertBoldText: "Вы можете отфильтровать свои встречи, используя поля ниже.",
    alertText:
      "Если вы вносите изменения в поля, фильтрация выполняется автоматически.",

    startInputLabel: "Дата начала",
    endInputLabel: "Дата окончания",
    staffInputLabel: "Подбор сотрудников",
    allStaffsText: "Все работники",
    serviceInputLabel: "наименование услуги",
    customerInputLabel: "Имя клиента Фамилия",

    tabMenuLeft: {
      customerBill: "Информация для покупателей",
      customerAppointment: "Билеты клиента",
      customerDetails: "Назначения клиента",
      customerPhotos: "Фотографии до и после",
      updateCustomerDetailsTrue: "Информация о клиенте успешно обновлена!",
      updateCustomerDetailsFalse: "Не удалось обновить информацию о клиенте!",
    },

    statusInputLabel: "Выбор статуса встречи",
    statusInputItems: [
      "Все встречи",
      "Прибывшие встречи",
      "Пропущенные встречи",
      "Ожидающие встречи",
      "Отмененные встречи",
    ],

    filterTitle: "Фильтр",

    canceled: "Отменено",
    situations: "Ситуации",

    headings: {
      appointmentStartDate: "Назначенная дата",
      customerFullName: "Клиент",
      serviceName: "Услуга",
      appointmentNote: "Записка о назначении",
      staffFullName: "Заинтересованный сотрудник",
      customerPhone: "Телефон клиента",
      createdAt: "Дата создания встречи",
    },
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/online
   */
  "appointments/online": {
    alertText:
      "В поле ниже перечислены онлайн-запросы на прием, на которые вы можете подать заявку, если они будут одобрены. Только представитель салона может утвердить/отклонить эти встречи.",
    headings: {
      appointmentStartDate: "Дата - время",
      customerFullName: "Связанный клиент",
      customerPhone: "Телефон клиента",
      staffFullName: "Заинтересованный сотрудник",
      serviceName: "Услуга",
      note: "Записка о встрече клиента",
      customerType: "Тип клиента",
    },

    approveButtonTitle: "Утвердить",
    rejectButtonTitle: "отклонять",

    rejectConfirm: {
      title: "Вы подтверждаете отмену встречи?",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",

      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы отклоните этот запрос, вы не сможете снова получить доступ к этому запросу на встречу с подтверждениемButtonTitler.",
    },

    conflictConfirm: {
      title: "Вы хотите подтвердить запрос на запись ?",
      alertBoldText: "Запрос на запись пересекается с существующей записью. Вы все равно хотите подтвердить ?",
    },

    approveToast: "Заявка на прием успешно одобрена!",
    rejectToast: "Запрос на встречу успешно отклонен!",
    blackListWarning:
      "После включения номера в черный список с этим номером нельзя делать запросы на запись на прием.",
    addToNumberBlackList: "Хотите добавить этот номер в черный список?",
  },

  /**
   * Randevu Oluştur sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/add
   */
  "appointments/add": {
    staffInputLabel: "Подбор сотрудников",
    serviceInputLabel: "Услуги, предоставляемые работником",
    customerInputLabel: "Клиент, который будет назначен",
    customerInputPlaceholder: "Введите имя клиента в это поле",
    //sendSmsInputLabel: "Bilgilendirme SMS Gönderimi",
    sendSmsInputLabel: "Отправить SMS-уведомление",
    sendSmsTrue: "Отправь это",
    sendSmsFalse: "Не отправлять",
    noteInputLabel: "Записка о встрече (необязательно)",

    availableHoursButtonTitle: "Принесите подходящее время встречи",
    confirmButtonTitle: "Подтвердить выбор встречи",
    confirmButtonHoursTitle: "Подтвердить выбор часов",
    createNewCustomerButtonTitle: "Создать нового клиента",
    createNewWalkinCustomerButtonTitle: "Создать нового клиента",

    insertToast: "Встреча успешно создана.",
    onlyActiveSwitch: 'Получайте только активные часы'
  },

  /**
   * Adisyonların listeletildiği sayfaların bulunduğu key.
   * https://app.salonrandevu.com/receipts
   * https://app.salonrandevu.com/packages/sales
   */
  receipts: {
    filter: {
      title: "Фильтрация по дате и клиенту",
      startInputLabel: "Дата начала",
      endInputLabel: "Дата окончания",
      typeOfSales: "Тип продажи",

      allSales: "Все продажи",
      openTitle: "Все",
      open: "Открытые продажи",
      close: "Закрытые продажи",
    },

    previous: "Предыдущая страница",
    next: "Следущая страница",

    headings: {
      id: "Номер билета",
      type: "Тип продажи",
      billType: "Тип билета",
      createdAt: "Дата открытия",
      customerFullName: "Имя Клиента",
      info: "Содержание билета",
      allAmount: "Общее",
      paid: "Оплаченный",
      debtTotal: "Остаток",
      processDate: "Дата совершения операции",
    },

    replacements: {
      undefinedCustomer: "Неизвестный клиент",
      allSales: "Все продажи",
      packageSale: "Упаковка",
      standartSale: "Стандарт",
    },

    showButtonTitle: "Посмотреть",

    detailButtons: {
      confirmButton: "Утвердить",
      closeButton: "Закрывать",
    },
    alert_receipt_installment:
      "На квитанции есть просроченные платежи с истекшими сроками.",

    deleteReceiptSuccessToast: "Квитанция и платежи успешно удалены!",

    customerStatus: {
      attend: "Пришел",
      notAttend: "Не пришел",
      waiting: "Ожидается"
    }
  },

  packages: {
    filter: {
      title: "Пакетная фильтрация",
      packageInputLabel: "Имя пакета",
    },
  },

  /**
   * Adisyonların oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/receipts/add
   */
  "receipts/add": {
    searchCustomerInputLabel: "Поиск клиентов",
    searchCustomerInputPlaceholder: "Введите имя и фамилию клиента в это поле",
    receiptNoteInputLabel: "Примечание к билету (необязательно)",

    openReceiptBySelectedButtonTitle: "Создать тикет для выбранного клиента",
    openReceiptByNewCustomerButtonTitle: "Создать тикет для нового клиента",
    openReceiptByRandomCustomerButtonTitle:
      "Создать тикет для незарегистрированного клиента",
    openReceiptWithCreatedNewCustomerButtonTitle: `Создать клиента и квитанцию`,

    insertToast: "Билет успешно создан!",
    infoToast: "Сначала вам нужно выбрать клиента в поле поиска!",
  },

  /**
   * Tekli adisyonun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/receipts/detail/:id
   */
  "receipt/detail/id": {
    single: {
      warnTitle: 'Предупреждение об обновлении законопроекта',
      warnDescription: 'Выбранная услуга не предоставляется выбранным персоналом. Хотите продолжить?',
      warnApprove: 'Принимать'
    },
    customerPoint: "Накопленные клиентские баллы",
    saveChangesButtonTitle: "Сохранить изменения",

    applyDiscountButtonTitle: "Применить скидку",
    addPackageSaleButtonTitle: "Добавить пакетную распродажу",
    addServiceTransactionButtonTitle: "Добавить услугу продажи",
    addStockSaleButtonTitle: "Добавить распродажу нового продукта",
    addPaymentButtonTitle: "Добавить новый платеж",

    installmentButtonTitle: "Добавить платеж в рассрочку",
    installmentCountInputLabel: "Количество платежей",
    installmentStaffInputLabel: 'Выберите персонал',
    installmentProcess: "Процесс рассрочки",

    deleteReceiptButtonTitle: "Удалить билет",
    alertText:
      "Сообщение было сохранено, но вы должны сначала сохранить билет, чтобы сообщение было переадресовано.",

    sms: {
      sendButtonTitle: "Сохранить СМС",
      discardButtonTitle: "Отмена",
      addSmsButtonTitle: "Отправить сообщение о коллекции",
      smsButtonTitle: "Отправить информацию о долге / сообщение о сборе",
      languageLabel: "Выберите язык для примера сообщения :",
    },

    creationdate: "Дата создания",

    point: "точка",
    packageInner: {
      title: "В пакет включено",
      headings: {
        // Table Headings
        name: "наименование услуги",
        count: "Общая сессия",
        completed: "Завершенная сессия",
        pending: "Сеанс ожидания",
      },
    },

    serviceTransactions: {
      // Section
      print: "Распечатать",
      soldTitle: "Продажа новых услуг",
      title: "Продажа услуг",
      dateInputLabel: "Дата совершения операции",
      nameInputLabel: "Название транзакции",
      transactionStatus: "Статус транзакции",
      staffInputLabel: "Заинтересованный сотрудник",
      amountInputLabel: "Сумма сделки ",
      totalAmount: "Общая сумма продаж услуг ",
      noDataText: "Продажа услуг еще не добавлена ​​в тикет!",
      searchPlaceholder: "Поиск услуг...",
    },

    stockSales: {
      // Section
      title: "Продажи продуктов",
      nameInputLabel: "Название продукта",
      deletedName: "Удаленный элемент",
      staffInputLabel: "Продавец",
      amountInputLabel: "Цена за единицу",
      countInputLabel: "Кусок",
      totalAmount: "Общая сумма продаж продукта ",
      noDataText: "В тикете еще не добавлено ни одного продукта.",
      selectWithBarcode: "Выберите продукт со штрих-кодом",
      selectStock: "Выберите продукт",
      barcodeCheckAlert:
        "Платный контент не активен. Пожалуйста, свяжитесь с представителем для активации.",
    },

    barcodeAlert: {
      pleaseEnterACode: "Пожалуйста, введите код продукта",
    },

    productTransactions: {
      productSaleTitle: "Продажи новых продуктов",
      successAdd: "Продукт успешно добавлен",
    },

    payments: {
      // Section
      title: "Платежи",
      dateInputLabel: "Дата платежа",
      typeInputLabel: "Способ оплаты",
      amountInputLabel: "Количество ",
      pointInputLabel: "Очки для использования",

      typeCASH: "Денежные средства",
      typeCARD: "Кредитная / дебетовая карта",
      typeEFT: "Банковский перевод/Электронный платеж",
      typePOINT: "Оценка клиента",

      totalAmount: "Всего билетов ",
      totalPaidAmount: "Выплаченная сумма ",
      totalDebtAmount: "Сумма долга ",

      noDataText: "В билет еще не добавлен способ оплаты!",
    },

    notes: {
      // Section
      title: "Примечания",
      receipt: "Примечание к билету",
      customer: "Описание клиента",
      appointment: "Записка о назначении",
    },

    applyDiscount: {
      // Modal
      title: "Применить скидку",
      typeInputLabel: "Тип скидки",
      typeCASH: "TL Скидка на основе",
      typePERCENT: "Процентная скидка",
      amountInputLabel: "Сумма скидки",
      discardButtonTitle: "сдаться",
    },

    packageSale: {
      // Modal
      title: "Распродажа новых пакетов",
      completeButtonTitle: "Полная распродажа",

      packageNameInputLabel: "Выбор пакета",
      serviceInputLabel: "Выбор услуги",
      staffInputLabel: "Подбор сотрудников",
      startInputLabel: "Дата начала",
      periodInputLabel: "Количество сеансов",
      periodBetweenInputLabel: "Интервал сеанса",
      periodAmountInputLabel: "Стоимость сеанса",

      noDataText: "Сервис-сеанс еще не добавлен в пакетную продажу!",
      totalAmount: "Сумма пакета ",

      servicePickToast: "Полностью заполните свой выбор услуг!",
      customerPickToast: "Завершите выбор клиента!",
    },

    serviceTransaction: {
      // Modal
      title: "Продажа новых услуг",
      addButtonTitle: "Добавлять",
      discardButtonTitle: "сдаться",

      staffInputLabel: "Подбор персонала",
      serviceInputLabel: "Выбор услуги",
      customerStateInputLanel: "Статус клиента",
      successAdd: "Услуга успешно добавлена",
    },

    stockSale: {
      // Modal
      title: "Продажи новых продуктов",
      addButtonTitle: "Добавлять",
      discardButtonTitle: "сдаться",

      staffInputLabel: "Торговый персонал",
      stockInputLabel: "Выбор продукта",
    },

    delete: {
      // Modal
      title: "Вы подтверждаете удаление?",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
      alertText:
        "Этот процесс необратим! Если вы удалите этот тикет, транзакции в нем будут удалены вместе. Это может повлиять на ваши бухгалтерские записи!",
    },

    discountBeforePaymentToast:
      "Чтобы применить скидку, необходимо удалить платежи.",
    saveChangesToast: "Изменения успешно сохранены.",

    transactionSuccessToast: "Запись успешно сохранена",
    agreementSuccessToast: "Соглашение успешно отправлено.",
    uploadAgreementErrorToast: "Размер соглашения не должен превышать 2 МБ.",
    uploadAgreementWarnToast: "Подготовка соглашения...",
    uploadAgreementSuccessToast: "Соглашение успешно загружено.",
    stockItemErrorToast: "Запись не найдена",
    pointPaidWarnToast: "Использовано больше баллов, чем накоплено у клиента. Пожалуйста, отрегулируйте свои платежи!",

    headingTitles: {
      receiptId: "Номер чека: ",
      services: "Услуги",
      staff: "Персонал",
      service: "Услуга",
      amount: "Стоимость",
      products: "Товары",
      product: "Товар",
      quantity: "Количество",
      receiptTotalAmount: "Итого по чеку: ",
      discount: "Скидка: ",
      netTotal: "Чистая сумма: ",
      paidAmount: "Оплаченная сумма: ",
      remaintAmount: "Оставшаяся сумма: "
    },

    agreementSaveButtonWarnToast1: "Пожалуйста, сохраните внесенные вами изменения.",
    agreementSaveButtonWarnToast2: "Чтобы использовать эту функцию для пакета лицензии Diamond, вам нужно купить пакет лицензии Diamond.",
    routeToLogs: "Перейти к журналу операций по чекам",
    previewTitle: "Предварительный просмотр",
    previewErrorToast: "Недействительный номер удостоверения личности",

    selectStaff: "Выбор персонала (необязательно)",
    paymentAmount: "Сумма платежа",
    installments: {
      two: "2 Рассрочка",
      three: "3 Рассрочка",
      four: "4 Рассрочка",
      five: "5 Рассрочка",
      six: "6 Рассрочка",
      seven: "7 Рассрочка",
      eight: "8 Рассрочка",
      nine: "9 Рассрочка",
      ten: "10 Рассрочка",
      eleven: "11 Рассрочка",
      twelve: "12 Рассрочка"
    },

    charLimitTitle: "Ваш лимит символов",

    greenHourTitle: "Доступные временные слоты для записи на прием отображаются зеленым цветом.",
    redHourTitle: "Временные слоты, которые уже были забронированы или закрыты в деталях сотрудника, отображаются красным цветом.",
    orangeHourTitle: "Когда время закрыто уполномоченным лицом, оно отображается оранжевым цветом.",

    uploadAgreementTitle: "Загрузить соглашение",
    uploadAgreementText: "Нажмите здесь, чтобы загрузить соглашение, относящееся к этому чеку.",
    uploadAgreementWarnToast2: "Пожалуйста, заполните недостающие поля: Название соглашения.",
    savedAgreementTitle: "Сохраненные соглашения",
    selectAgreementTemplateTitle: "Выберите шаблон соглашения",

    nationalIDTitle: "Номер национального удостоверения личности",
    taxIDTitle: "Номер налога",
    addressTitle: "Адрес",

    sendToSignatureTitle: "Отправить на подпись",
    sendToSignatureAlertDialogTitle: "Подтверждаете ли вы отправку отредактированного соглашения на мобильную подпись?",
    sendToSignatureAlertDialogText: "Вы можете просматривать, редактировать и подписывать отправленное соглашение в разделе Список контрактов вашего мобильного приложения.",

    getServices: "Получить доступные услуги",
    clear: "Очистить",
    deletedService: "Удаленная услуга",

    saveBeforeGetAppointmentWarnToast: "Пожалуйста, сохраните ваши изменения перед записью на прием.",
    addNote: "Добавить заметку",

    getProducts: "Получить продукты",
    installmentText: "рассрочка",

    packageSessionUpdateTitle: "Обновление сеанса пакета",
    packageSessionUpdateText1: `Все сеансы в пакете будут обновлены на "%s". Вы подтверждаете?`,
    packageSessionUpdateText2: "Примечание: Не забудьте сохранить изменения после этого обновления.",

    packageDateUpdateTitle: "Обновление даты пакета",
    packageDateUpdateText: "Все даты сеансов в пакете будут обновлены в соответствии с диапазоном дат. Вы подтверждаете?",

    packageDeleteTitle: "Удаление пакета",
    packageDeleteText1: "Пакет и все услуги в нем будут удалены. Вы подтверждаете?",
    packageDeleteText2: "Если вы подтвердите удаление, пакет и все услуги в нем будут удалены. Вы подтверждаете?",

    stockSaleErrorToast: "Выбранного товара нет в наличии.",
    barcodeInputPlaceholder: "Введите штрих-код.",
  },

  "packages/sales": {
    filter: {
      title: "Фильтрация по дате",
    },

    loaderTitle: "Пакеты загружаются. Пожалуйста, подождите...",
  },

  /**
   * Paket satışının yapıldığı sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/sales/add
   */
  "packages/sales/add": {
    searchPackegeInputLabel: "Пакет поиска",
    customerInputLabel: "Клиент для продажи",
    customerInputPlaceholder: "Введите имя клиента в это поле",

    importPackageButtonTitle: "Пакет импорта",
    addServiceButtonTitle: "Добавить услугу в пакет",
    completeButtonTitle: "Полная распродажа",

    enterPackageName: "Введите имя пакета",

    serviceInputLabel: "Выбор услуги",
    staffInputLabel: "Подбор сотрудников",
    startInputLabel: "Дата начала",
    periodInputLabel: "Количество сеансов",
    periodBetweenInputLabel: "Интервал сеанса",
    periodAmountInputLabel: "Стоимость сеанса",
    totalInputLabel: "Пакет Всего ",

    noDataText: "Сервис-сеанс еще не добавлен в пакетную продажу!",

    addPackageInSaleWarnToast: "Убедитесь, что выбрали пакет, который хотите импортировать!",
    validatePackageSelectServiceWarnToast: "Полностью завершите выбор услуг!",
    validatePackageSelectCustomerWarnToast: "Полностью завершите выбор клиента!",

    dayTitle: "%s День",

    modal: {
      title: "Импорт подготовленного пакета",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
      packageInputLabel: "Выбор пакета",
    },
  },

  /**
   * Paketlerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/list
   */
  "packages/list": {
    headings: {
      name: "Имя пакета",
      amount: "Сумма пакета",
    },
  },

  /**
   * Paketlerin oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/add
   */
  "packages/add": {
    name: "Имя пакета",
    completeButtonTitle: "Создать пакет",
    addServiceButtonTitle: "Добавить новую услугу в пакет",

    serviceInputLabel: "Выбор услуги",
    periodInputLabel: "Количество сеансов",
    periodAmountInputLabel: "Цена единицы сеанса ",
    totalInputLabel: "Общая стоимость пакета ",

    noDataText: "В пакет еще не добавлено ни одного сервис-сеанса!",

    validateServiceToast: "Полностью заполните свой выбор услуг!",
    insertToast: "Пакет успешно создан.",
  },

  /**
   * Paket detayı sayfasının bulunduğu key.
   * https://app.salonrandevu.com/packages/detail/:id
   */
  "packages/detail/id": {
    updatePackageButtonTitle: "Обновить пакет",
    deletePackageButtonTitle: "Удалить пакет",
    addServiceButtonTitle: "Добавить новую услугу в пакет",

    nameInputLabel: "Имя пакета",
    serviceInputLabel: "Выбор услуги",
    countInputLabel: "Количество сеансов",
    periodAmountInputLabel: "Цена единицы сеанса",

    totalInputLabel: "Общая стоимость пакета ",
    noDataText: "В пакет еще не добавлено ни одного сервис-сеанса!",

    delete: {
      title: "Подтвердите удаление пакета",
      confirmButtonTitle: "Да",
      discardButtonTitle: "сдаться",
      alertText:
        "Вы подтверждаете удаление пакета? Ваши предыдущие продажи, счета и бухгалтерские записи не будут затронуты.",
    },

    validateServiceToast: "Завершите выбор услуг!",
    deleteToast: "Пакет успешно удален.",
    updateToast: "Пакет успешно обновлен",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  products: {
    deleteButtonTitle: "Удалить продукт",

    headings: {
      name: "Название продукта",
      amount: "Плата за продукт",
      limit: "Количество на складе",
      bounty: "Премиум процент",
    },
    delete: {
      // Modal
      title: "Подтверждение удаления продукта",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",

      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы удалите его, вы больше не сможете продавать этот товар!",
    },

    deleteToast: "Продукт был успешно удален.",
    stockName: "Название акции",

    insertStockSaleSuccessToast: "Операция продажи успешно выполнена!",
    customerNameInput: "Введите полное имя клиента в это поле",
    goWithSelectedCustomer: "Продолжить с выбранным клиентом",
    goWithSelectedCustomerWarnToast: "Сначала нужно выбрать клиента.",
    goWithoutSelectedCustomer: "Продолжить с неназванным клиентом",

    productList: "Список товаров",
    productName: "Название товара",
    productAmount: "Цена товара",
    stockPiece: "Количество на складе",
    addToCart: "Добавить в корзину",

    cartList: "Товары в корзине",
    singleAmount: "Цена за единицу",
    totalAmount: "Общая цена",
    removeFromCart: "Удалить из корзины",

    completeStockSale: "Завершить продажу",

    codeLabel: "Код",
    saleDate: "Дата продажи",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  "products/add": {
    searchProductInputLabel: "Поиск продукта",
    searchProductInputPlaceholder: "Введите название продукта",

    createButtonTitle: "Создать продукт",

    nameInputLabel: "Название продукта",
    nameInputPlaceholder:
      "Введите название продукта, который будет указан в приложении управления.",
    amountInputLabel: "Плата за продукт ",
    amountInputHint:
      "Указанная вами сумма является суммой по умолчанию. Вы можете изменить цену сделки при добавлении к тикету.",
    bountyInputLabel: "Премиум процент (%)",
    bountyInputHint:
      "Если фиксированный процент премии ваших сотрудников не определен, они будут получать премии в размере, который будет указан.",
    pointInputLabel: "Очки, которые нужно заработать",
    addBarcode: "Создайте продукт со штрих-кодом",
    addBarcodeExplain: "Введите код, чтобы создать штрих-код для продукта.",
    pointInputHint: "100 клиентских баллов эквивалентны 1 TL.",
    limitInputLabel: "Количество на складе",
    limitInputHint:
      "Введите количество товара на складе. Вы можете изменить это значение позже.",

    insertToast: "Товар успешно создан.",
    barcodeCheckTitle:
      "Платный контент не активен. Пожалуйста, свяжитесь с представителем для активации.",
  },

  /**
   * Ürün detay sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products/detail/:id
   */
  "products/detail/id": {
    updateButtonTitle: "Обновить продукт",

    nameInputLabel: "Название продукта",
    nameInputPlaceholder:
      "Введите название продукта, который будет указан в приложении управления.",
    amountInputLabel: "Плата за продукт ",
    amountInputHint:
      "Указанная вами сумма является суммой по умолчанию. Вы можете изменить цену сделки при добавлении к тикету.",
    bountyInputLabel: "Премиум процент (%)",
    bountyInputHint:
      "Если фиксированный процент премии ваших сотрудников не определен, они будут получать премии в размере, который будет указан.",
    pointInputLabel: "Очки, которые нужно заработать",
    pointInputHint: "100 клиентских баллов эквивалентны 1 TL.",
    limitInputLabel: "Количество на складе",
    limitInputHint:
      "Введите количество товара на складе. Вы можете изменить это значение позже.",

    updateToast: "Продукт успешно обновлен.",
  },

  /**
   * Aktif müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers
   */
  customers: {
    alertTExt:
      "Это настройка для включения/выключения отправки смс вашим клиентам, которые добавлены в ваш список клиентов. При выполнении этой операции настройки отправки смс у всех ваших клиентов будут обновлены.",
    filter: {
      title: "Фильтр поиска клиентов",
      inputLabel: "Имя Фамилия или номер телефона",
    },
    amount: {
      openSmsSendingStatus: "Открыть статус доставки SMS",
      closeSmsSendingStatus: "Отключить статус отправки SMS",
      title: "Посмотреть суммы",
      updateSmsStatus: "Обновить статус SMS",
      allCustomerSmsUpdate: "Статус доставки SMS всех клиентов изменится!",
      excelTooltip: "Этот параметр включается, если вы хотите добавить дополнительные данные в список клиентов, такие как сумма долга, сумма заработка и статус доставки SMS, в Excel-файл.",
    },
    headings: {
      code: "Клиентский код",
      fullName: "Имя Фамилия",
      phone: "Телефонный номер",
      colorSelect: "Выбор цвета",
      sex: "Пол",
      incomeAll: "Сумма заработка",
      debtAll: "Сумма долга",
      message_state: "Состояние сообщения",
      message_open: "Открыто",
      message_close: "Закрыто",
    },
    replacements: {
      totalCustomerNumber: "Общее количество клиентов",
      sex: {
        undef: "Неопределенные",
        male: "Мальчик",
        female: "Женщина",
      },
    },
    passiveAll: {
      passiveAllButtonTitle: "Сделать все пассивным",
      title: "Вы одобряете деактивацию всех ваших клиентов?",
      alertText:
        "Если вы деактивируете всех клиентов, вы больше не сможете видеть клиентов в таких областях, как встречи/счета.Вы можете получить доступ к информации о клиентах на вкладке «Пассивные клиенты» в любое время.",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",

      successToast: "Все клиенты успешно деактивированы.",
    },
    passiveSelected: {
      passiveSelectedButtonTitle: "Отключить выбранное",
      title: "Вы одобряете деактивацию выбранных клиентов?",
      alertText:
        "Если вы деактивируете выбранных клиентов, вы больше не сможете видеть клиентов в таких областях, как встречи / счета.Вы всегда можете получить доступ к информации о клиентах на вкладке «Пассивные клиенты».",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",

      successToast: "Выбранные клиенты были успешно деактивированы.",
    },
    months: {
      jan: "Январь",
      feb: "Февраль",
      mar: "Март",
      apr: "Апрель",
      may: "Май",
      jun: "Июнь",
      jul: "Июль",
      aug: "Август",
      sep: "Сентябрь",
      oct: "Октябрь",
      nov: "Ноябрь",
      dec: "Декабрь"
    },
    customerFullNameLabel: "Неопределенный клиент",
    ispackageTrueLabel: "Продажа пакета",
    ispackageFalseLabel: "Стандартная продажа",
    createSuccessToast: "Запись клиента успешно создана!",
    wrongDateWarnToast: "Вы ввели неверную дату. Пожалуйста, выберите 'Дата рождения' еще раз.",
    errorOccuredToast: "Произошла неожиданная ошибка. Пожалуйста, свяжитесь с вашим менеджером по работе с клиентами.",
    deletedStaffLabel: "(Удаленный сотрудник)",
    photoLimitErrorToast: "Вы превысили лимит фотографий для текущего пакета.",
    photoUploadErrorToast: "Вы не можете загрузить фотографию.",
    updateToPassiveSuccessToast: "Клиент успешно установлен в пассивный режим!",
    updateToActiveSuccessToast: "Клиент успешно установлен в активный режим!",
    customerDeleteSuccessToast: "Клиент успешно удален.",
    suitableClockWarnToast: "Пожалуйста, выберите время, подходящее для дня записи.",
    staffUpdateWarn1: "Вы не можете вносить изменения в информацию о клиенте в соответствии с вашими полномочиями!",
    staffUpdateWarn2: "Только уполномоченные сотрудники компании могут редактировать информацию о клиентах и удалять клиентов.",
    whatsappTooltipTitle: "Связаться через Whatsapp",
    whatsappWarnToast: "Платный контент неактивен. Пожалуйста, свяжитесь с вашим представителем.",
    sendMessageTooltipTitle: "Отправить сообщение",

    contractHeading: {
      date: "Дата",
      agreement: "Название договора",
      bytes: "Размер",
      transactions: "Транзакции"
    },

    sendContractViaSMS: "Вы хотите отправить зарегистрированный контракт по SMS?",
    sendContractMessage: "Вы уверены, что хотите отправить '%v' клиенту с именем %s по SMS?",
    sendContractWP: "Вы уверены, что хотите отправить '%v' клиенту с именем %s через Whatsapp?",
    sendContractViaWP: "Вы хотите отправить зарегистрированный контракт через Whatsapp?",

    deleteContractTitle: "Вы подтверждаете удаление контракта?",
    deleteContractAccept: "Подтвердить и удалить",
    deleteContractWarn1: "Контракт будет удален навсегда.",
    deleteContractWarn2: "Это действие нельзя отменить. Контракт будет удален навсегда.",

    serviceTableLabel: "Пакеты",
    routeToAppointment: "Перейти к записи",
    acceptAppointmentDecline: "Вы подтверждаете отмену записи?",
    appointmentDeclineWarn1: "Это действие нельзя отменить!",
    appointmentDeclineWarn2: "Запись будет удалена из календаря. Вы подтверждаете?",

    beforePhoto: "Предыдущее фото",
    afterPhoto: "Следующее фото",

    routeToLogs: "Перейти к записям транзакций клиентов",
  },

  "logs/index": {
    filter: {
      title: "Фильтр расширенного поиска",
      form: {
        description: "Сведения о транзакции/имя клиента/имя сотрудника",
        startDate: "Дата начала",
        endDate: "Дата окончания",
        allDate: "Все время",
        findCustomer: {
          label: "Имя Клиента",
          placeholder: "Поиск клиентов",
          helper: "Фильтровать только по конкретному клиенту",
        },
      },
    },
  },

  /**
   * Riskli müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/risky
   */
  "customers/risky": {
    offsetInputLabel: "Выбор дневного диапазона",
    offsetItem15: "Те, кто был неактивен более 15 дней",
    offsetItem30: "Те, кто был неактивен более 30 дней",
    offsetItem45: "Те, кто был неактивен более 45 дней",
    offsetItem60: "Те, кто был неактивен более 60 дней",

    headings: {
      code: "Клиентский код",
      fullName: "Имя Фамилия",
      phone: "Телефонный номер",
      sex: "Пол",
      incomeAll: "Сумма заработка",
      debtAll: "Сумма долга",
      lastTransaction: "Время с момента последней транзакции",
    },

    sendMessageSuccessToast: "Сообщение успешно отправлено.",
    sendMessageButtonTitle: "Отправить агитационное сообщение",
    selectedAllButtonTitle: "Выбрать все",
    cancelAllButtonTitle: "Удалить все",
    listButtonTitle: "Получить потерянных клиентов",
    noDataTextInitial: "Пожалуйста, выберите дату, а затем нажмите кнопку \"Получить потерянных клиентов\".",
    noDataText: "Клиенты, которые не посетили ваш салон в течение выбранного периода, не найдены.",
    infoText: "Информация!",
  },

  /**
   * Değerlendirme anketlerinin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/rating
   */
  "customers/rating": {
    headings: {
      createdAt: "Дата оценки",
      staffFullName: "Заинтересованный сотрудник",
      serviceName: "Реализованный сервис",
      companyStar: "Оценка бизнеса",
      staffStar: "Оценка сотрудника",
      description: "объяснение",
    },

    deleteButtonTitle: "Удалить оценку",
    replyToComment: "Отвечать",
    viewToComment: "Посмотреть",
    delete: {
      title: "Вы подтверждаете удаление?",
      alertText:
        "Этот процесс необратим! Если вы удалите эту оценочную анкету, вы больше не сможете получить к ней доступ.",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
    },

    message: {
      title: "Ответить на комментарий",
      comment: "Комментарий сделан",
      alertText: "Напишите свой комментарий.",
      confirmButtonTitle: "Отправлять",
      discardButtonTitle: "Отмена",
    },

    deleteToast: "Указанный рейтинг успешно удален!",
    deleteBeforePlanUpgradeToast:
      "Чтобы удалить отзыв клиента, вам необходимо обновить свой пакет до премиум-пакета.",
  },

  /**
   * Müşterilerin eklendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/add
   */
  "customers/add": {
    nameInputLabel: "Имя Клиента",
    nameInputPlaceholder: "Введите имя клиента",

    surnameInputLabel: "Фамилия клиента",
    surnameInputPlaceholder: "Введите фамилию клиента",

    phoneInputLabel: "Мобильный телефон",

    genderInputLabel: "Пол",
    genderMale: "Мальчик",
    genderFemale: "Женщина",


    disableOnlineBooking: "Online запись",
    disableOnlineBookingLabelTrue: "Не может",
    disableOnlineBookingLabelFalse: "Может",
    secondPhoneNumberWarning: "Второй номер телефона не может быть пустым",
    firstPhoneNumberWarning: "Первый номер телефона не может быть пустым",

    bothPhoneNumberWarning: "Один или оба номера телефонов не могут быть пустыми",

    sendSmsTooltipTitle: "Отправка сообщений, таких как напоминание о встрече и подтверждение встречи, указывает на статус отправки сообщений.",



    blacklist: {
      primary: "1. телефонный номер в черном списке. Сообщения не могут быть отправлены на этот номер.",
      secondary: "2. телефонный номер в черном списке. Сообщения не могут быть отправлены на этот номер.",
      both: "1. и 2. телефонный номер в черном списке. Сообщения не могут быть отправлены на эти номера.",
      removeBlacklist: "Нажмите, чтобы снять блокировку.",
    },

    birthdayInputLabel: "Дата рождения",
    birthdayInputInvalidMessage:
      "Пожалуйста, введите в желаемом формате. День месяц год",

    maritalStatusInputLabel: "семейное положение",
    maritalStatusMarried: "женатый",
    maritalStatusSingle: "Один",

    secondPhoneInputLabel: "Второй номер телефона",
    secondPhoneInputPlaceholder: "Если есть, введите второй номер телефона.",

    mailInputLabel: "Адрес электронной почты",
    mailInputPlaceholder: "Если есть, введите свой адрес электронной почты.",

    descriptionInputLabel: "Аннотации",

    workStatusInputLabel: "Рабочий статус",
    workStatusWorking: "работающий",
    workStatusUnemployed: "Не работает",
    workStatusEmployer: "Работодатель",
    workStatusStudent: "Ученик",
    workStatusHouseWife: "Домохозяйка",

    selectPhoneNumberForMessage: "Выберите номер для отправки сообщений",
    firstPhoneNumber: "Первый номер телефона",
    secondPhoneNumber: "Второй номер телефона",
    allPhoneNumber: "Оба",

    relatedBusinessInputLabel: "Соответствующее учреждение",
    relatedBusinessInputPlaceholder:
      "Пожалуйста, укажите, из какого учреждения пришел клиент.",

    sendSmsInputLabel: "Статус отправки СМС",
    sendSmsTrue: "Отправь это",
    sendSmsFalse: "Не отправлять",

    createNewCustomerButtonTitle: "Создать клиента",
  },

  "customers/passive": {
    makeActiveButtons: {
      selectedButtonTitle: "Сделать выбранное активным",
      allButtonTitle: "Сделать все активным",
    },

    selectedCustomersSuccessToast:
      "Выбранные клиенты были успешно активированы",
    allCustomersSuccessToast: "Все клиенты успешно активированы",
    allCustomersDeletedSuccessToast: "Все клиенты успешно удалены.",
    selectedCustomersDeletedSuccessToast: "Выбранные клиенты успешно удалены.",
    selectedCustomersDeleteWarnToast: "Клиенты без активных записей были удалены. Если вы хотите удалить клиентов с активными записями, вы должны отменить их активные записи.",
  },

  /**
   * Toplu müşteri ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/customers/add/bulk
   */
  "customers/add/bulk": {
    orginzeProcess: "Контакты редактируются. Пожалуйста, подождите.",
    uploadAreaText: "Щелкните это поле, чтобы загрузить файл Excel.",
    stepsHeading: "Массовая загрузка клиентов с помощью Excel",
    steps: [
      "Для того, чтобы добавить ваших клиентов в систему через Excel, вам необходимо загрузить свои данные в подготовленном нами формате excel.",
      "Загрузите шаблон Excel, нажав кнопку на следующем шаге.",
      "Скачать формат Excel",
      "Сопоставьте данные клиента с форматом Excel, который вы скачали на предыдущем шаге, и загрузите его в систему.",
    ],
    boldText:
      "ВАЖНО: Для без потерь и безошибочной передачи данных места и названия столбцов в шаблоне Excel менять нельзя!",

    preview: {
      heading: "Предварительный просмотр загрузки в Excel",
      completeButtonTitle: "Завершите массовую регистрацию клиентов",

      nameInputLabel: "Имя",
      surnameInputLabel: "Фамилия",
      nationalID: "национальный идентификатор",
      phoneInputLabel: "Мобильный телефон",
      sendSmsInputLabel: "Отправка СМС",
      sendSmsTrue: "Открытым",
      sendSmsFalse: "Закрыто",
      selectedPhoneLabel: "Выбранный номер",
      descriptionLabel: "Дополнительные примечания",

      genderInputLabel: "Пол",
      genderMale: "Мальчик",
      genderFemale: "Женщина",

      mailInputLabel: "Адрес электронной почты",
      birthdayInputLabel: "Дата рождения",

      maritalStatusInputLabel: "семейное положение",
      maritalStatusMarried: "женатый",
      maritalStatusSingle: "Один",
    },

    duplicateRecords: "Удалено повторяющихся записей: %s.",

    duplicatedPhoneWarnToast: "Номера телефонов уже зарегистрированы в системе.",

    insertToast:
      "Ваша массовая загрузка успешно поставлена ​​в очередь! Вы перенаправлены на страницу со списком.",
  },

  /**
   * Hizmet listesinin bulunduğu key.
   * https://app.salonrandevu.com/services
   */
  services: {
    filter: {
      title: "Фильтр поиска услуг",
      inputLabel: "наименование услуги",
    },

    headings: {
      name: "наименование услуги",
      processTime: "время обработки",
      bounty: "Премиум процент",
      amount: "Плата за обслуживание",
      showAtUrl: "Настройка онлайн-встречи",
    },

    replacements: {
      showAtUrlTrue: "Открытым",
      showAtUrlFalse: "Закрыто",
    },

    deleteButtonTitle: "Удалить службу",
    delete: {
      title: "Вы подтверждаете удаление услуги?",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы удалите эту услугу, вы не сможете выполнять транзакции в управлении билетами, и вы не сможете назначить встречу через эту услугу во время назначения/определения встречи. Ваши существующие встречи для этой службы будут сохранены.",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
    },

    dataNotFound: "Данные не найдены!",

    addAllService: {
      warningTitle: "ПРЕДУПРЕЖДЕНИЕ!",
      addButtonTitle: "Добавить массовую услугу",
      informationDialog: {
        addServiceDescription:
          "Информация о продолжительности и стоимости переданных вами услуг назначается системой автоматически. Обновите информацию о периоде обслуживания и ценах, которую вы указали в своем бизнесе.",
        confirmButtonTitle: "Я понимаю",
      },
    },

    deleteAll: {
      title: "Вы подтверждаете удаление услуг?",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы удалите все сервисы, вы не сможете выполнять транзакции в управлении билетами, и вы не сможете назначить встречу через эти сервисы во время записи/определения встречи. Ваши существующие записи на эти услуги будут сохранены.",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
      deleteAllServicesToast: "Службы успешно удалены",
      deleteAllButtonTitle: "Удалить все",
    },

    deleteSelectedServices: {
      title: "Вы подтверждаете удаление выбранных услуг?",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы удалите выбранные услуги, вы не сможете выполнять транзакции в управлении билетами, и вы не сможете записаться на прием через эти сервисы во время записи/определения встречи. Ваши существующие записи на эти услуги будут сохранены.",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
      deleteSelectedButtonTitle: "Удалить выбранные службы",
    },

    deleteToast: "Услуга успешно удалена.",

    amountBasisTitle: "По сумме",
    percentBasisTitle: "(%) В процентах",
  },

  /**
   * Hizmet detay/ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/services/add
   * https://app.salonrandevu.com/services/detail/id
   */
  "services/detail": {
    placeholder: {
      search: "Пожалуйста, введите услугу, которую вы ищете",
    },
    validate: {
      serviceCategory: "Категория услуги",
      servicesName: "Название услуги",
      servicePrice: "Стоимость услуги",
      servicesProcessTime: "Время обработки услуги",
    },
    selcetAllEmployees: "Выбрать всех сотрудников",

    serviceName: "ты ищешь",
    lookingFor: " Наименование услуги",
    ourList: "нет в нашем списке?",
    createRequest: "Создайте спрос.",
    newCreateRequest: "Создать запрос на обслуживание",
    createNewRequest: "Создать новый запрос.",
    nameInputLabel: "наименование услуги",
    nameInputHint:
      "Введите название службы, которое будет указано в вашем приложении для управления и на странице онлайн-встречи.",

    amountInputLabel: "Плата за обслуживание ",
    amountInputHint:
      "Указанная вами сумма является суммой по умолчанию. Вы можете изменить цену сделки при добавлении к тикету.",

    bountyInputLabel: "Премиум процент (%)",
    bountyMoneyBased: "На основе денежного бонуса",
    bountySelectMoney: "Денежный бонус",
    bountySelectPer: "Процент бонуса",
    bountyInputHint:
      "Если для ваших сотрудников не определен фиксированный процент надбавок, они будут получать надбавки за эту услугу в указанном проценте.",

    pointInputLabel: "Очки, которые нужно заработать",
    pointInputHint: "100 клиентских баллов соответствуют 1 TL.",

    timeInputLabel: "Среднее время обработки (минута)",
    timeInputHint:
      "Время обработки — это информация, которая будет отображаться, когда клиент назначает встречу. Сотрудник, выполняющий операцию данной услуги, не сможет получить новую запись в течение периода обработки.",

    repeatInputLabel: "Время повторения (дни)",
    repeatInputHint:
      "Если эта услуга представляет собой процесс, требующий повторения, это позволит вам информировать ваших клиентов по SMS/E-Mail, когда придет время повторить.",

    urlInputLabel: "Настройка онлайн-встречи",
    urlInputHint:
      "Это настройка, будет ли действие отображаться/отображаться в онлайн-встречах с бизнес-URL.",

    urlInputTrue: "Открытым",
    urlInputFalse: "Закрыто",

    staffSectionTitle: "Сотрудники для реализации услуги",
    timeWarnAlertText:
      "Вносимое вами изменение будет действовать для вновь созданных встреч, оно не повлияет на созданные встречи.",

    createButtonTitle: "Создать сервис",
    updateButtonTitle: "Служба обновления",
    deleteButtonTitle: "Удалить службу",

    delete: {
      title: "Вы подтверждаете удаление услуги?",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы удалите эту услугу, вы не сможете выполнять транзакции в управлении билетами, и вы не сможете назначить встречу через эту услугу во время назначения/определения встречи. Ваши существующие встречи для этой службы будут сохранены.",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
    },

    lastTransactions: {
      headings: {
        createdAt: "Дата совершения операции",
        staffFullName: "Заинтересованный сотрудник",
        amount: "Сумма сделки",
      },
      showOnReceiptButton: "Показать в билете",
    },

    serviceCategoryInput: "Категория услуги",
    serviceNameInput: "наименование услуги",

    serviceCategoryHint:
      "Категория услуги и название услуги, которые вы выберете, будут использоваться на платформе Salon Appointment.",

    serviceNameHint:
      "Чтобы выбрать имя службы, вы должны сначала выбрать категорию в разделе «Категория службы».",

    deleteToast: "Услуга успешно удалена.",
    insertToast: "Сервис успешно создан.",
    updateToast: "Сервис успешно обновлен.",

    newServiceInputTitle: "Новое имя службы",
    newServiceRequestToast: "Ваш новый запрос на обслуживание успешно создан.",
    newServiceRequestErrorToast: "Имя новой службы не может быть пустым.",
    newServiceRequestDialogTitle: "Новый запрос на обслуживание",
    newServiceCategoryHint:
      "Категория услуги, от которой будет зависеть новая услуга.",
    createNewServiceRequest: "Создать запрос",
    cancelNewServiceRequest: "Отмена",

    setCampaignTitle: "Сделать кампанией",
    campaignNameTitle: "Название кампании",
    campaignHint:
      "Продукты, помеченные как Кампании, появятся в поле Кампания на вашей бизнес-странице.",

    serviceDescriptionLabel: "Описание услуг",
    serviceDescriptionHint:
      "Описание услуги позволяет вашим клиентам лучше узнать услугу, которую вы предлагаете.",
      continueWithoutCategory: "Продолжить без категории"
  },

  "payment-notify": {
    copyIban: "Копировать IBAN",
    ibanSuccess: "IBAN успешно скопирован.",
    depositBank: "Банк денежных переводов",
    depositAmonut: "Сумма перевода",
    depositDate: "Дата перевода",
    makePaymentNotice: "Сделать платежное уведомление",
  },

  "services/requests": {
    headings: {
      time: "Дата совершения операции",
      name: "наименование услуги",
      state: "Ситуация",
      category: "Категория",
      actionHeadingText: "Транзакции",
      stateCompleted: "Запрос одобрен",
      stateIncompleted: "Не удалось подтвердить запрос",
      stateWaiting: "Ожидает утверждения запроса",
    },

    deletedRequestToast: "Запрос запроса был удален",

    cancel: {
      cancelButton: "Отмена",
      cancelTitle: "Вы хотите удалить новый запрос на обслуживание?",
      cancelConfirmButtonTitle: "Да",
      cancelDiscardButtonTitle: "Нет",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы отмените этот запрос, мы не сможем выполнить транзакцию, и новая проверка обслуживания не будет выполнена.",
    },
  },

  "services/bounty": {
    addBountyTitle: "Добавить бонус",

    bountyDescription:
      "Здесь вы можете добавить специальную надбавку за свои услуги и сотрудников, у которых нет фиксированного процента надбавки, с возможностью определения специальных надбавок.",

    staffInputLabel: "Сотруднику будет добавлен бонус",
    categoryInputLabel: "Категория услуги",
    serviceInputLabel: "наименование услуги",
    bountyAmountInputLabel: "Премиум процент",

    headings: {
      bounty: "Премиум процент(%)",
      serviceName: "наименование услуги",
      staffName: "Имя работника",
      deleteBountyButtonTitle: "Удалить Премиум",
    },

    deleteBountyDialog: {
      title: "Вы уверены, что хотите удалить премиум?",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "Если вы удалите этот премиум-аккаунт, у вашего сотрудника не будет премиум-аккаунта, связанного с этой услугой.",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
    },

    addBountySuccessToast: "Бонус для сотрудников успешно добавлен.",
    deleteBountySuccessToast: "Надбавка сотрудника за услугу успешно удалена.",
  },

  /**
   * Çalışanların listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees
   */
  employees: {
    editButtonTitle: "Изменить сотрудника",
    staffPageTitle: "Список сотрудников",
    createNewEmployee: "Создать нового сотрудника",
    orderStaffs: "Изменить порядок",
    insertStaffSuccessToast: "Сотрудник успешно создан!",
    updateStaffSuccessToast: "Сотрудник успешно обновлен!",
    deleteStaffSuccessToast: "Сотрудник успешно удален!",

    addAllService: "Добавить все",
    addAllServiceWarn: "Выбранные вами услуги в пакете не входят в перечень услуг, предоставляемых сотрудником. Вы не можете продолжать без добавления услуги в перечень услуг, предоставляемых сотрудником.",
    routeToStaffLogs: "Перейти к записям операций сотрудников",
    packagesTitle: "Предлагаемые пакеты",
  },

  /**
   * Geri Bildirim verilen sayfa
   * https://app.salonrandevu.com/feedback
   */
  feedback: {
    insertFeedbackSuccessToast: "Ваш отзыв был успешно получен. Спасибо!",
    insertFeedbackWarnToast: "Пожалуйста, напишите ваш отзыв!",
    feedbackTitle: "Оставить отзыв",
    feedbackLabel1: "Мы ценим мнения, мысли и предложения наших пользователей.",
    feedbackLabel2: process.env.APP_NAME === "salon" ? "Salonrandevu" : process.env.APP_NAME === "en" ? "EnRandevu" : "Salon Management",
    feedbackLabel3: "приложение для управления",
    feedbackLabel4: "пользовательский опыт",
    feedbackLabel5: "мы продолжаем работать над тем, как улучшить пользовательский опыт. Для этой цели мы разработали канал обратной связи, где можем постоянно взаимодействовать с нашими ценными пользователями.",
    feedbackLabel6: "канал обратной связи",
    feedbackLabel7: "Через форму на странице вы можете поделиться с нами любыми идеями, мнениями и предложениями.",
    feedbackInputLabel: "Вы можете написать здесь",
  },

  /**
   * Çalışan profil sayfalarının listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees/detail/id
   * https://app.salonrandevu.com/employees/add
   * https://app.salonrandevu.com/profile
   */
  profile: {
    detail: {
      //Section
      title: "Информация о сотруднике",
      profileTitle: "Информационный профиль",

      nameInputLabel: "Имя",
      surnameInputLabel: "Фамилия",

      genderInputLabel: "Пол",
      genderMale: "Мальчик",
      genderFemale: "Женщина",

      birthdayInputLabel: "Дата рождения",

      jobTitleInputLabel: "Заголовок",
      bountyInputLabel: "Фиксированный процент премии",

      permissionInputLabel: "Статус полномочий панели",
      permissionOwner: "Официальный",
      permissionStaff: "Рабочий",
      permissionLimited: "Ограниченная власть",

      sendSmsInputLabel: "Отправка СМС",
      sendSmsTrue: "Отправь это",
      sendSmsFalse: "Не отправлять",

      urlInputLabel: "Показ по URL-адресу компании",
      showAtUrlTrue: "быть показанным",
      showAtUrlFalse: "Не показывай",
      branch_list: "Список филиалов",
      branch_change: "Смена филиала",
      branch_enter: "Перейти в филиал",
      branch_change_alert: "Изменение филиала выполнено.",
    },

    contact: {
      //Section
      title: "Контакты",

      mailInputLabel: "Адрес электронной почты",
      phoneInputLabel: "Мобильный телефон",
      passwordInputLabel: "Пароль панели",
      newPasswordInputLabel: "Новый пароль панели",
    },

    services: {
      //Section
      title: "Услуги, которые он предлагает",

      alertBoldText:
        "Услуги, предлагаемые сотрудником, не выбираются при нажатии на услуги ниже!",
      alertText:
        "В противном случае услуги, оказываемые работником по адресу назначения предприятия, будут пустыми.",
    },

    booking: {
      //Section
      title: "Часы приема",
      alertBoldText: "Измените частоту встреч!",
      week: [
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
        "рынок",
      ],
      dayOpenText: "Откройте день для встречи",
      dayCloseText: "Закрыть день до встречи",
    },

    photo: {
      //Section
      uploadButtonTitle: "Загрузить фото",
      deleteButtonTitle: "Удалить фото",
    },

    createButtonTitle: "Создать сотрудника",
    updateButtonTitle: "Сохранить изменения",
    deleteButtonTitle: "Удалить сотрудника",

    logoutButtonTitle: "выход",

    delete: {
      //Modal
      title: "Вы подтверждаете удаление?",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
      alertBoldText: "Этот процесс необратим!",
      alertText:
        "Если вы удалите этого сотрудника, вы больше не сможете получить доступ к профилю сотрудника и другой информации.",
    },

    photoUploadToast: "Фото успешно загружено.",
    photoDeleteToast: "Фотография была успешно удалена.",
    insertToast: "Рабочий создан успешно.",
    updateToast: "Рабочий успешно обновлен.",
    deleteToast: "Сотрудник успешно удален.",

    limited_permission_label: {
      no_permission: "недоступный",
      display: "Можно посмотреть",
      full_permission: "Полностью авторизован",
      all: "Все",
      just_itself: "Только сам"
    },
  },

  /**
   * SMS Yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms
   */
  sms: {
    foundWpDevice:
      "Устройство WhatsApp, зарегистрированное в вашей учетной записи, обнаружено.",
    noFoundWpDevice:
      "Устройства WhatsApp, зарегистрированные в вашей учетной записи, не найдены.",

    creditSMSLabel: "Доступный/оставшийся кредит SMS",
    creditWPLabel: "Доступный/оставшийся кредит WP",
    senderLabel: "Имя отправителя",

    sendType: {
      label: "Способ отправки сообщения",
      sendSMS: "Отправить смс",
      sendWP: "Отправить сообщение в WhatsApp",
      both: "отправить оба",
    },
    loginWithQr: "Сканировать QR-код",
    loginWithNo: "Введите номер телефона",

    addDevice: "Добавить устройство",
    removeDevice: "Удалить устройство",

    deviceStatus: "Статус",
    wpPhone: "Номер WhatsApp",
    wpCredits: "Кредиты WhatsApp",

    phoneNotConnected: "Ваш номер не подключен",

    deviceConnected: "Устройство подключено",
    deviceNotConnected: "Устройство не подключено",

    wpQrTutorial1: "Откройте приложение WhatsApp, затем нажмите на ︙.",
    wpQrTutorial2: "Нажмите на кнопку 'Подключить устройство'.",
    wpQrTutorial3: "Отсканируйте QR-код камерой.",

    qrPopupTitle: "Отсканировать QR-код",
    qrPopupContext:
      "Отсканируйте QR-код с помощью камеры вашего устройства, чтобы подключиться к приложению.",

    withPhoneNumberTitle:
      "Введите номер, для которого вы хотите получить код WhatsApp.",
    codeForThisPhone: "Код подтверждения WhatsApp для этого номера",

    alertBoldText: "Получение информации об отправителе SMS...",
    alertText:
      "Этот процесс может занять некоторое время, пожалуйста, подождите.",

    undefinedAlerts: {
      wp_heading:
        "В вашем аккаунте не найдено устройство для отправки сообщений WhatsApp.",
      wp_content:
        "В WhatsApp нет ветки, которую вы могли бы отправить с указанием названия своей компании. Если вы хотите иметь возможность отправлять своим клиентам специальные сообщения WhatsApp, отличные от фиксированных информационных сообщений платформы.",
      wp_span: "  oпределите устройство для своей учетной записи.",
      sms_heading: "Заголовок SMS не определен для индивидуальных доставок!",
      sms_content:
        "  Нет заголовка SMS, который можно было бы отправить с названием вашей компании. Если вы хотите иметь возможность отправлять своим клиентам специальные SMS-сообщения, отличные от фиксированных информационных сообщений платформы, вам необходимо приобрести услугу SMS.",
    },

    sendButtonText: "Посмотреть",
    dialogTitle: "Отображение смс",
    buttonApprove: "Одобрить",
    buttonCancel: "Отмена",

    groupSMSReports: "Групповые SMS-отчеты",
    singleSMSReports: "Автоматические SMS-отчеты",
    groupWPReports: "Групповые отчеты по WP",
    singleWPReports: "Автоматические отчеты WP",

    lastSendingsTitle: "Отправка SMS",
    creditPaybackButtonTitle: "Получить возврат SMS кредита",
    whatsappPaybackButtonTitle: "Получить возврат WhatsApp кредита",
    searchInputLabel: "Введите ключевое слово",
    headings: {
      date: "Дата",
      sender: "Имя отправителя",
      status: "Статус отправки",
      content: "Содержание сообщения",
    },
    replacements: {
      status: [
        "Отправлено",
        "Не переадресовано",
        "Неправильный номер",
        "Вернулся",
        "в ожидании",
      ],
    },

    send: {
      // Section
      messageInputLabel: "Содержание сообщения",
      charType: "Тип персонажа",
      charTR: "турецкий",
      charNormal: "Нормальный",
      buttonTitle: "Отправить смс",
      discardButtonTitle: "Закрывать",
      sendDate: "Дата отправки",
      futureDate: "Расширенные СМС",
      platformRequired: "Платформа для отправки не выбрана! Пожалуйста, выберите платформу.",
      platformSelect: "Платформа для отправки",
      sampleMessage: "Выберите примерное сообщение",

      autoText: {
        nameSurname: "Имя Фамилия",
        amountPaid: "Выплаченная сумма",
        remainingDebt: "Остаток долга",
        businessUrl: "URL-адрес компании",
        shortCode: "Короткий номер",
        forSmsSelection: "Для отмены смс",
        writeIt: "напишите на 4607.",
      },

      autoTextShortCode: {
        nameSurname: "|name surname|",
        remainingDebt: "|remaining debt|",
        paidDebt: "|paid debt|",
        businessUrl: "|business url|",
      },

      check: {
        // Modal
        title: "Вы одобряете это действие?",
        confirmButtonTitle: "Да",
        discardButtonTitle: "Нет",
        alertText:
          "После вашего одобрения, если ваш кредит достаточен, начнется процесс доставки. Этот процесс необратим!",
      },

      template: "Выбор шаблона",

      nameSurnameShortcut: "Имя Фамилия",
      successToast: "Отправка СМС началась успешно!",
    },

    planAlertLabel: "Вы можете связаться с нами по телефону 0(212) 706 0510, чтобы приобрести SMS-пакет.",
    availableCreditLabel: "Доступный/Остаточный кредит",
  },

  /**
   * Seçimli SMS Gönder sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/selective
   */
  "sms/selective": {
    continueButtonTitle: "Продолжай",
    backToSelectButtonTitle: "Вернуться к подборке",

    warningText: "Список отправки не может совпадать со списком исключений!",

    customer: {
      // Section
      title: "Список клиентов",
      headings: {
        fullName: "Имя Фамилия",
        phone: "Телефонный номер",
      },

      searchInputLabel: "Поиск клиентов",
      selectAll: "Выбрать всех клиентов",
      unselectAll: "Убрать выделение со всего",
    },

    approved: {
      // Section
      title: "Список дел",
      notFound: {
        alertBoldText: "Отбор клиентов для отправки не производился!",
        alertText:
          "Вы можете начать выбирать своих клиентов, нажав кнопку с галочкой в ​​списке клиентов.",
      },

      alertBoldText: "Все ваши клиенты успешно отобраны!",
    },

    excepted: {
      // Section
      title: "Список исключений",
      notFound: {
        alertBoldText:
          "Клиенты, которых нужно исключить из доставки, не выбраны!",
        alertText:
          "Если у вас есть клиенты-исключения, которые не будут отправлены из числа ваших клиентов, вы можете исключить клиента из отправки, нажав кнопку блокировки в таблице.",
      },
    },
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/filter": {
    applyFilterButtonTitle: "Применить фильтр",
    continueButtonTitle: "Продолжай",
    backToFilterButtonTitle: "Назад к фильтрации",

    tabs: {
      // Section
      debt: "Статус долга",
      debtTrue: "должник",
      debtFalse: "В чистоте",

      risk: "Статус риска",
      risk15: "Нет транзакций за последние 15 дней",
      risk30: "Нет транзакций за последние 30 дней",
      risk45: "Нет транзакций за последние 45 дней",
      risk60: "Нет транзакций за последние 60 дней",

      work: "Рабочий статус",
      workWorking: "работающий",
      workUnemployed: "Не работает",
      workEmployer: "Работодатель",
      workStudent: "Ученик",
      workHouseWife: "Домохозяйка",

      gender: "Пол",
      genderMale: "Мальчик",
      genderFemale: "Женщина",

      marital: "Семейное положение",
      maritalMarried: "женатый",
      maritalSingle: "Один",

      service: "Услуга получена",
      staff: "Персонал, совершивший сделку",
      age: "Возрастной диапазон",
    },

    customer: {
      // Section
      title: "Список клиентов",
      headings: {
        fullName: "Имя Фамилия",
        phone: "Мобильный телефон",
        gender: "Пол",
      },
      replacements: {
        genderNull: "Неопределенные",
        genderFemale: "Женщина",
        genderMale: "Мальчик",
      },
    },

    except: {
      title: "Фильтрация списка исключений",
      alertBoldText:
        "Клиенты, которых нужно исключить из доставки, не выбраны!",
      alertText:
        "Если у вас есть клиенты-исключения, которые не будут отправлены из вашего списка клиентов после фильтрации, вы можете исключить клиента из отправки, нажав кнопку блокировки в таблице.",
    },

    sendFilteredSmsErrorToast: "Выберите один из методов отправки.",
    sendFilteredSmsSuccessToast: "Отправка SMS была успешно начата!",
    shortCodeButtonMessage: "%s Напишите %v, чтобы отменить SMS, и отправьте на номер 4607.",

    smsMessageTitle: "SMS Сообщение",
    wpMessageTitle: "Сообщение WhatsApp",
  },

  "sms/group": {
    continueButtonTitle: "Продолжай",
    backToSelectButtonTitle: "Назад к подборкам",

    newGroupButtonTitle: "Создать группу",

    group: {
      title: "Список групп",

      headings: {
        groupName: "Название группы",
        customerCount: "Количество клиентов",
      },

      searchInputLabel: "Группа поиска",
      searchInputPlaceholder: "Введите название группы ",
      selectAll: "Выбрать все группы",
      unselectAll: "Убрать выделение со всего",
    },

    approved: {
      title: "Список дел",
      notFound: {
        alertBoldText: "Среди групп для отправки не был сделан выбор!",
        alertText:
          "Вы можете начать выбор своих групп, нажав кнопку с галочкой в ​​списке групп.",
      },

      alertBoldText: "Все ваши группы успешно отобраны!",
    },

    excepted: {
      title: "Список исключений",
      notFound: {
        alertBoldText: "Группы для исключения из публикации не выбраны!",
        alertText:
          "Если среди ваших групп есть группы-исключения, которые не будут отправляться, вы можете исключить группу из отправки, нажав кнопку блокировки в таблице.",
      },
    },

    newGroup: {
      title: "Новая группа",

      groupName: "Название группы",
      groupEditButtonTitle: "Редактировать",

      customersTitle: "ваши клиенты",
      selectedCustomersTitle: "Ваши избранные клиенты",

      searchCustomer: "Поиск клиентов",
      searchCustomerDescription: "Напишите имя или номер клиента",
      selectionDescription:
        "Вы можете передать желаемого клиента, выбрав или используя метод перетаскивания.",
      notFoundCustomer: "Клиенты, которых вы ищете, не найдены.",

      selectAllButtonTitle: "Выбрать всех клиентов",
      unselectAllButtonTitle: "Удалить всех клиентов",
      deleteAllButtonTitle: "Удалить все",

      alertBoldText: "Все ваши клиенты успешно отобраны!",

      createButtonTitle: "Создавать",
      cancelButtonTitle: "Отмена",

      successToast: "Новая группа успешно создана.",
      emptyGroupName: "Имя группы не может быть пустым.",

      edit: {
        editGroupTitle: "Обновить группу",
        editButtonTitle: "Обновлять",
        deleteGroupButtonTitle: "Удалить группу",

        updateSuccessToast: "Группа успешно обновлена.",
        deleteSuccessToast: "Группа успешно удалена.",
      },
    },

    successToast: "Ваше сообщение было успешно отправлено.",
    connectionWarnToast: "Устройство не активно, пожалуйста, обновите его!",
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/settings": {
    updateButtonTitle: "Обновить настройки",
    customerSwitchTitle: "Клиент",
    staffSwitchTitle: "Рабочий",
    onSwitchTitle: "Вкл",
    offSwitchTitle: "Выкл",

    padiContent:
      "Платный контент не активен. Пожалуйста, свяжитесь с вашим представителем по работе с клиентами.",

    reminder: {
      title: "Напоминание о встрече",
      description:
        "Это настройка для отправки/не отправки SMS о напоминаниях о встречах.",
    },

    longTermReminder: {
      title: "Напоминание о долгосрочной встрече",
      description:
        "Если до даты встречи осталось более одного дня, это настройка для отправки/не отправки SMS за один день до даты встречи.",
    },

    requestInfo: {
      title: "Информация о запросе на прием",
      description:
        "Это настройка для отправки/не отправки SMS при получении нового онлайн-запроса/запроса на встречу.",
    },

    whenApproved: {
      title: "Когда запрос о назначении одобрен",
      description:
        "Это настройка для отправки/не отправки SMS при утверждении входящего запроса/запроса на онлайн-встречу.",
    },

    whenRejected: {
      title: "Когда запрос о встрече отклонен",
      description:
        "Это настройка для отправки/не отправки SMS, когда входящий запрос/запрос онлайн-встречи отклонен.",
    },

    whenCreated: {
      title: "Когда создается назначение",
      description:
        "Это настройка для отправки/не отправки SMS во время создания встречи.",
    },

    whenCancelled: {
      title: "В активной отмене встречи",
      description:
        "Это настройка для отправки/не отправки SMS при отмене созданной встречи.",
    },

    whenBirthday: {
      title: "День Рождения Отправить",
      description:
        "Это настройка отправлять или не отправлять поздравительные SMS клиентам, у которых день рождения. Этот параметр работает, если у вас есть собственное имя отправителя для вашего бизнеса/себя.",
    },

    whenCustomerCreate: {
      title: "Когда клиент добавлен",
      description:
        "После регистрации клиента можно настроить отправку или не отправку SMS-уведомления вашему клиенту о том, что ваш бизнес зарегистрирован в списке клиентов.",
    },

    dragAndDrop: {
      title: "Перетащите встречу",
      description:
        "Это настройка для отправки/не отправки SMS клиенту при перетаскивании встречи.",
    },

    smsShortcode: {
      title: "Короткий код смс",
      description:
        "Это настройка для отправки/не отправки SMS при добавлении номера клиента в черный список.",
    },

    reminderNoncomerCustomer: {
      title: "Напоминание клиенту, который не пришел на прием",
      description: [
        "Автоматическое напоминание вашим клиентам, которые не посещали ваш бизнес в течение 60 дней",
        "СМС отправляется.",
        "Пример содержания сообщения: Дорогая Фиген Челик, мы так соскучились по вам!",
        "Мы заметили, что вы не совершали никаких транзакций в течение 60 дней. Надеемся увидеть вас снова, Желаем вам счастливого дня..",
        "Чтобы назначить встречу : https://app.salonrandevu.com/isletme/2037",
      ],
    },

    appointmentUpdate: {
      title: "Обновление назначения",
      description:
        "Это настройка для отправки/отправки обновленного времени и даты встречи по SMS.",
    },

    smsAttendedApproval: {
      title: "Отправка ссылки для записи на прием по СМС",
      description:
        "Ссылка отправляется, чтобы узнать, следует ли посетить встречу, созданную клиентом. Эта ссылка является настройкой, позволяющей клиенту выбрать, следует ли ему посещать встречу позитивно или негативно.",
    },

    afterAppointment: {
      title: "Оценка после назначения",
      description:
        "Это настройка для отправки/не отправки оценочного SMS после встречи.",
    },

    reminderHour: {
      title: "Время доставки напоминания о встрече",
      description:
        "Это настройка того, за сколько часов до встречи будет отправлено SMS.",

      inputLabel: "За сколько часов до отправки?",
      options: ["Закрыто", "1 час", "2 час", "3 час", "4 час"],
    },

    updateToast: "Настройки SMS успешно обновлены.",
    saveSMSSettingsErrorToast: "Напоминания о длительных записях отправляют SMS за 24 часа. Пожалуйста, обновите время напоминания или отключите долгосрочную запись.",
    routeToSmsLogs: "Перейти к записям SMS-транзакций",
  },
  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/wp/settings": {
    updateButtonTitle: "Обновить настройки",
    customerSwitchTitle: "Клиент",
    staffSwitchTitle: "Рабочий",
    onOffSwitchTitle: "Вкл выкл",

    padiContent:
      "Платный контент не активен. Пожалуйста, свяжитесь с вашим представителем по работе с клиентами.",

    reminder: {
      title: "Напоминание о встрече",
      description:
        "Это настройка для отправки/не отправки WP о напоминаниях о встречах.",
    },

    longTermReminder: {
      title: "Напоминание о долгосрочной встрече",
      description:
        "Если до даты встречи осталось более одного дня, это настройка для отправки/не отправки WP за один день до даты встречи.",
    },

    requestInfo: {
      title: "Информация о запросе на прием",
      description:
        "Это настройка для отправки/не отправки WP при получении нового онлайн-запроса/запроса на встречу.",
    },

    whenApproved: {
      title: "Когда запрос о назначении одобрен",
      description:
        "Это настройка для отправки/не отправки WP при утверждении входящего запроса/запроса на онлайн-встречу.",
    },

    whenRejected: {
      title: "Когда запрос о встрече отклонен",
      description:
        "Это настройка для отправки/не отправки WP, когда входящий запрос/запрос онлайн-встречи отклонен.",
    },

    whenCreated: {
      title: "Когда создается назначение",
      description:
        "Это настройка для отправки/не отправки WP во время создания встречи.",
    },

    whenCancelled: {
      title: "В активной отмене встречи",
      description:
        "Это настройка для отправки/не отправки WP при отмене созданной встречи.",
    },

    whenBirthday: {
      title: "День Рождения Отправить",
      description:
        "Это настройка отправлять или не отправлять поздравительные WP клиентам, у которых день рождения. Этот параметр работает, если у вас есть собственное имя отправителя для вашего бизнеса/себя.",
    },

    whenCustomerCreate: {
      title: "Когда клиент добавлен",
      description:
        "После регистрации клиента можно настроить отправку или не отправку WP-уведомления вашему клиенту о том, что ваш бизнес зарегистрирован в списке клиентов.",
    },

    dragAndDrop: {
      title: "Перетащите встречу",
      description:
        "Это настройка для отправки/не отправки WP клиенту при перетаскивании встречи.",
    },

    smsShortcode: {
      title: "Короткий код смс",
      description:
        "Это настройка для отправки/не отправки WP при добавлении номера клиента в черный список.",
    },

    smsApprovalLink: {
      title: "WhatsApp'dan Randevu Katılımı İçin Link Gönderimi",
      description:
        "Müşterinin oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link müşterinin randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    reminderNoncomerCustomer: {
      title: "Напоминание клиенту, который не пришел на прием",
      description: [
        "Автоматическое напоминание вашим клиентам, которые не посещали ваш бизнес в течение 60 дней",
        "WP отправляется.",
        "Пример содержания сообщения: Дорогая Фиген Челик, мы так соскучились по вам!",
        "Мы заметили, что вы не совершали никаких транзакций в течение 60 дней. Надеемся увидеть вас снова, Желаем вам счастливого дня..",
        "Чтобы назначить встречу : https://app.salonrandevu.com/isletme/2037",
      ],
    },

    appointmentUpdate: {
      title: "Обновление назначения",
      description:
        "Это настройка для отправки/отправки обновленного времени и даты встречи по WhatsApp.",
    },

    smsAttendedApproval: {
      title: "Отправка ссылки для записи на прием по WhatsApp",
      description:
        "Ссылка отправляется, чтобы узнать, следует ли посетить встречу, созданную клиентом. Эта ссылка является настройкой, позволяющей клиенту выбрать, следует ли ему посещать встречу позитивно или негативно.",
    },

    afterAppointment: {
      title: "Оценка после назначения",
      description:
        "Это настройка для отправки/не отправки оценочного WP после встречи.",
    },

    reminderHour: {
      title: "Время доставки напоминания о встрече",
      description:
        "Это настройка того, за сколько часов до встречи будет отправлено WP.",

      inputLabel: "За сколько часов до отправки?",
      options: [
        "Закрыто",
        "1 час",
        "2 час",
        "3 час",
        "4 час",
        "5 час",
        "6 час",
        "7 час",
        "8 час",
        "9 час",
        "10 час",
        "11 час",
        "12 час",
        "13 час",
        "14 час",
        "15 час",
        "16 час",
        "17 час",
        "18 час",
        "19 час",
        "20 час",
        "21 час",
        "22 час",
        "23 час",
        "24 час",
      ],
    },

    updateToast: "Настройки WhatsApp успешно обновлены.",
    loginWPDeviceSuccessToast: "Устройство успешно подключено.",
    logoutWPSuccessToast: "Выход с устройства WhatsApp выполнен.",
    infoCodeText: "Код входа в WhatsApp будет отправлен на введённый номер.",
  },

  "sms/template": {
    createTemplateButtonTitle: "Создать новый шаблон",

    templatesHeadings: {
      name: "Имя",
      content: "Содержание",
    },

    deleteButtonTitle: "Удалить шаблон",

    templateDialog: {
      addTitle: "Новый шаблон",
      updateTitle: "Сведения о шаблоне/редактирование",

      templateTitleInputLabel: "Имя Шаблона",
      templateTitleInputPlaceholder: "Введите имя шаблона",

      templateContentInputLabel: "Содержимое шаблона",

      createButtonTitle: "Создавать",
      updateButtonTitle: "Обновлять",
      discardButtonTitle: "Отмена",
    },

    deleteConfirm: {
      title: "Вы подтверждаете удаление шаблона?",
      confirmButtonTitle: "Да",
      discardButtonTitle: "Нет",
      alertBoldText: "Это действие не может быть отменено!",
      alertText:
        "При удалении этого номера телефона из черного списка, при рассылке массовых сообщений этому человеку будет приходить СМС.",
    },

    addSuccessToast: "Шаблон успешно создан.",
    updateSuccessToast: "Шаблон успешно обновлен.",
    deleteSuccessToast: "Шаблон успешно удален.",

    tableButtonWarnToast: "Пожалуйста, заполните обязательные поля.",
  },

  "sms/blacklist": {
    totalNumber: "Общее количество номеров: ",
    blacklistHeadings: {
      number: "Количество",
      addDate: "Дата загрузки",
      disableOnlineBooking: "Статус онлайн-бронирования",
    },
    deleteUserBlacklist: "Удалить номер из списка",
    numberInputLabel: "Введите номера, которые вы хотите заблокировать",
    searchNumberInputLabel: "Введите номер, который хотите найти",
    addToBlacklist: "Добавить в черный список",
    addToBlacklistError:
      "Пожалуйста, введите действительный номер, используйте ',' чтобы добавить несколько номеров",
    addSuccessToast: "Номер успешно добавлен.",
    updateSuccessToast: "Номер успешно обновлен.",
    deleteConfirm: {
      title: "Вы подтверждаете удаление номера из списка?",
      // alertText:"Если вы удалите этот номер, у вас больше не будет доступа.",
    },
  },

  /**
   * Muhasebe Yönetimindeki ortak kullanımda olan key.
   * https://app.salonrandevu.com/accounting/income
   * https://app.salonrandevu.com/accounting/debt
   */
  accounting: {
    showOnReceipt: "Показать в билете",

    startInputLabel: "Дата начала",
    endInputLabel: "Дата окончания",
    typeInputLabel: "Способ оплаты",
    customerName: "Имя Клиента",

    typeCustomer: "Все клиенты",
    typeALL: "Все методы",
    typeCASH: "Денежные средства",
    typeCARD: "Кредитная / дебетовая карта",
    typeEFT: "Банковский перевод/Электронный платеж",

    totalIncome: "Общий доход",
    totalDebt: "Общая задолженность",

    alertBoldText: "Вы можете отфильтровать {desc}, используя поля ниже.",
    alertText:
      "Если вы вносите изменения в поля, фильтрация выполняется автоматически.",

    today: "Сегодня",
    thisWeek: "На этой неделе",
    thisMonth: "Этот месяц",

    incomeMethods: "Доходные операции",

    filterArea: {
      header: "Выберите временной диапазон",
      yesterday: "Вчера",
      lastSevenDays: "Последние 7 дней",
      lastFifteenDays: "Последние 15 дней",
      lastThirtyDays: "Последние 30 дней",
      approve: "Утвердить",
      cancel: "Отмена",
    },

    noRecords: "Данные равны нулю в выбранном вами диапазоне дат.",
  },

  /**
   * Gelir işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/income
   */
  "accounting/income": {
    headings: {
      createdAt: "Указанная дата платежа",
      customerFullName: "Имя Клиента",
      amount: "Сумма сделки",
      type: "Способ оплаты",
    },
    totalIncome: "Общий доход",
  },

  /**
   * Borç işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debt
   */
  "accounting/debt": {
    headings: {
      createdAt: "Дата совершения операции",
      customerFullName: "Имя Клиента",
      amount: "Общее",
      paid: "Оплаченный",
      remaining: "Остаток",
    },
  },

  /**
   * Prim işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/bounty
   */
  "accounting/bounty": {
    payButtonTitle: "Оплатить премиум",
    allPayButtonTitle: "Оплатить все",
    paymentType: "Пожалуйста, выберите тип оплаты",
    routeReceipt: "Перейти к счету",
    routeError: "Что-то пошло не так",

    customerInfo: {
      name: "Имя клиента",
      paid: "Оплаченная сумма",
      primAmount: "Сумма комиссии",
      transactions: "Операции",
    },

    headings: {
      staffFullName: "Имя сотрудника Фамилия",
      bounty: "Сумма премии",
    },

    check: {
      title: "Премиум платеж",
      confirmButtonTitle: "Сделать платеж",
      discardButtonTitle: "сдаться",
      alertBoldText:
        "Накопленный бонус {bounty}₺ для сотрудника {selected_employee}. Вы можете произвести премиальный платеж, указав дату транзакции и варианты способа оплаты.",
    },
  },

  /**
   * Gider işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense
   */
  "accounting/expense": {
    add: {
      title: "Добавить новый расход",
      dialogTitle: "Новый расход",
      dialogQATitle: "Вы подтверждаете удаление расхода?",
      typeInputLabel: "Статья расходов",
      amountInputLabel: "Количество",
      amountInputPlaceholder:
        "Введите соответствующую сумму расходов в это поле.",
      descriptionInputLabel: "объяснение",
      descriptionInputHint:
        "В этом поле вы можете ввести примечания, которые хотите добавить к соответствующей транзакции.",

      buttonTitle: "Добавить расходы",
      cancelButtonTitle: "Отмена",
      paymentType: "Способ оплаты",

      processDate: "Дата совершения операции",

      spending: "расходы",
      spendingPlaceholder: "Введите ваши расходы.",
    },

    payments: {
      cash: "Наличные",
      creditCard: "Кредитная / Дебетовая карта",
      transfer: "Банковский перевод / ЭФТ",
      cusPoint: "Оценка клиента",
    },

    table: {
      title: "Расходные операции",
      headings: {
        createdAt: "Дата создания",
        transactionDate: "Дата совершения операции",
        type: "Статья расходов",
        description: "Описание расходов",
        amount: "Количество",
      },
      deleteButtonTitle: "Удалить расход",
    },

    totalExpense: "Общие расходы",
    dialogQAWarn1: "Это действие нельзя отменить!",
    dialogQAWarn2: "Расход будет удален из списка.",
    insertErrorToast: "Пожалуйста, выберите способ оплаты",
    deleteToast: "Запись о расходах успешно удалена.",
    insertToast: "Расход был успешно создан.",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense/types
   */
  "accounting/expense/types": {
    alertBoldText: "О статьях расходов",
    alertText:
      "В этом поле вы можете определить статьи расходов, характерные для вашего бизнеса, и выполнить транзакции расходов по созданным вами статьям.",

    saveChangesButtonTitle: "Сохранить изменения",
    typeInputLabel: "{i}. Особая статья расходов",

    insertToast: "Операция была проведена успешно.",

    moreButtonTitle: "Более",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/credit
   */
  "accounting/credit": {
    filter: {
      title: "Фильтрация по дате и клиенту",
      satrtDate: "Дата начала",
      endDate: "Дата окончания",
      name: "Имя Клиента",
    },

    add: {
      customerSelection: "Выбор клиента",
      scheduledPaymentDate: "Запланированная дата платежа",
      enterAmount: "Введите сумму",
      explanation: "объяснение",
      saveReceivable: "Сохранить дебиторскую задолженность",
      updateReceivable: "Обновить дебиторскую задолженность",
      collectPayment: "Сбор оплаты",
      paymentDialogTitle: "Платежная информация",
      savePaymnet: "Сохранить платеж",
      closePayment: "Закрыть платеж",

      date: "Дата",
      amount: "Количество",
      paymentType: "Способ оплаты",
      actions: "Транзакции",
      delete: "удалять",
      update: "Обновлять",

      start_amount: "Начальная сумма",
      end_amount: "Конечная сумма",
    },

    payments: {
      typeInputLabel: "Способ оплаты",
      typeInputPlaceholder: "Выберите способ оплаты",
      paid: "Оплачено",
      notPaid: "Не оплачено",

      amountInputLabel: "Введите сумму",

      typeCASH: "Денежные средства",
      typeCARD: "Кредитная / дебетовая карта",
      typeEFT: "Банковский перевод/Электронный платеж",
      typePOINT: "Оценка клиента",
    },

    inserted: "Долг добавлен",
    missingInfo: "Вы ввели неполную информацию",
    error: "Произошла ошибка",
    addCredit: "Добавить кредит",
    paid: "Оплаченный",
    notPaid: "не выплачивается",

    headings: {
      createdAt: "Дата создания",
      scheduledPaymentDate: "Запланированная дата платежа",
      nameSurname: "Имя Фамилия",
      all_amount: "Общее",
      paid: "Оплаченный",
      debt_total: "Остаток",
      view: "Детали и вид",
      delete: "удалять",
      transactions: "Транзакции",
      is_paid: "Статус платежа",
    },
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debit
   */
  "accounting/debit": {
    filter: {
      title: "Фильтрация по дате и клиенту",
      satrtDate: "Дата начала",
      endDate: "Дата окончания",
      name: "Имя Клиента",
    },

    add: {
      customerSelection: "Имя Клиента",
      scheduledPaymentDate: "Запланированная дата платежа",
      enterAmount: "Введите сумму",
      explanation: "объяснение",
      saveReceivable: "Сохранить долг",
      updateReceivable: "Обновить долг",
      collectPayment: "Сбор оплаты",
      paymentDialogTitle: "Платежная информация",
      savePaymnet: "Сохранить платеж",
      closePayment: "Закрыть платеж",

      date: "Дата",
      amount: "Количество",
      paymentType: "Способ оплаты",
      actions: "Транзакции",
      delete: "удалять",
      update: "Обновлять",

      start_amount: "Начальная сумма",
      end_amount: "Конечная сумма",
    },

    payments: {
      typeInputLabel: "Способ оплаты",
      typeInputPlaceholder: "Выберите способ оплаты",
      paid: "Оплачено",
      notPaid: "Не оплачено",

      typeCASH: "Денежные средства",
      typeCARD: "Кредитная / дебетовая карта",
      typeEFT: "Банковский перевод/электронный платеж",
      typePOINT: "Оценка клиента",
    },

    addCredit: "Добавить дебет",
    paid: "Оплаченный",
    notPaid: "не выплачивается",

    headings: {
      createdAt: "Дата создания",
      scheduledPaymentDate: "Запланированная дата платежа",
      nameSurname: "Имя Фамилия",
      all_amount: "Общее",
      paid: "Оплаченный",
      debt_total: "Остаток",
      view: "Детали и вид",
      delete: "удалять",
      transactions: "Транзакции",
      is_paid: "Статус платежа",
    },
  },

  /**
   * Raporlar modülünün genel kullanımlarının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   * https://app.salonrandevu.com/reports/services
   * https://app.salonrandevu.com/reports/stock
   * https://app.salonrandevu.com/reports/staff
   * https://app.salonrandevu.com/reports/customer
   */
  reports: {
    startInputLabel: "Дата начала",
    endInputLabel: "Дата окончания",

    headings: {
      stockName: "Название продукта",
      serviceName: "Название продукта",
      transactionCount: "Количество транзакций",
      soldCount: "Количество продаж",
      income: "Заработанный доход",
    },

    selectedStaffInputLabel: "Подбор сотрудников",
    selectedPageCountInputLabel: "Число страниц",

    loaderTitle: "Ваши отчеты загружаются. Пожалуйста, подождите...",
  },

  /**
   * İşletme raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   */
  "reports/company": {
    soldItem: "Товар продан",
    soldService: "Реализованный сервис",

    serviceIncome: "Доход от услуг",
    itemIncome: "Доход от продукта",

    generalIncome: "Общий доход",
    maxExpense: "Общий расход",
    netIncome: "Чистая прибыль",

    typeCASH: "Денежные средства",
    typeCARD: "Кредитная / дебетовая карта",
    typeEFT: "Банковский перевод/Электронный платеж",

    _maxAppointment: "Общее количество назначений",
    _maxReceipt: "Общее количество билетов",
    _serviceIncome: "Доход от услуг",
    _itemIncome: "Доход от продуктов",
    _paidBounty: "Премия, выплаченная персоналу",

    alertText:
      "Значения, указанные как доход от услуг и доход от продукта, рассчитываются по полностью оплаченным и свободным кредитам.",

    incomeReports: "Отчеты о доходах",
    expensesReports: "Отчеты о расходах",
    summary: "Краткое содержание",

    goToDetail: "Перейти к деталям",
  },

  /**
   * Çalışan raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/staff
   */
  "reports/staff": {
    name: "Имя сотрудника",
    serviceCount: "Количество предоставляемых услуг",
    soldItemCount: "Количество проданных товаров",

    onlyService: "Оборот услуг",
    bountyService: "Сервис Премиум",
    productSum: "Товарооборот",
    bountyProduct: "Продукт Премиум",
    packageSum: "Оборот пакета",
    bountyPackage: "Пакет Премиум",

    endorsement: "Общий оборот",
    bounty: "Общий бонус",
    survey: "Оценка",
  },

  /**
   * Müşteri raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/customer
   */
  "reports/customer": {
    name: "Имя Клиента",
    appointmentCount: "Количество назначений",
    appointmentStatus: "Прибыл/жду/не пришел",

    transactionCount: "Количество совершенных транзакций",
    productCount: "Количество проданных товаров",
    totalEarned: "Общий доход",
  },

  /**
   * Yapılacaklar listesi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/todos
   */
  todos: {
    toastSuccess: "Миссия успешно добавлена!",
    toastWarningMessage: "Содержимое задачи не может быть пустым.",
    toastWarningProcess: "Не удалось добавить задачу.",

    addButtonTitle: "Создать задачу",

    addTodoTitle: "Добавить задачу",
    todoInputLabel: "Содержание квеста",
    todoInputPlaceholder:
      "В этом поле вы можете вводить свои заметки и задачи, касающиеся вашей работы, которую нужно выполнить.",

    dateTitle: "Датированные миссии",
    notFoundText: "Для его истории нет сохраненных квестов.",

    statusWaiting: "ОЖИДАЮЩИЙ",
    statusCompleted: "ЗАВЕРШЕННЫЙ",

    updateToast: "Статус задачи успешно обновлен.",
    insertToast: "Задача успешно добавлена.",
    deleteToast: "Задача успешно удалена.",

    deleteTaskQuestion: "Вы хотите удалить задачу?",
    deleteTaskDescription: "Оно будет удалено из вашего списка задач.",
  },

  /**
   * Cüzdan sayfasının bulunduğu key.
   * https://app.salonrandevu.com/wallet
   */
  wallet: {
    cardTitle: "Ваш общий баланс",
    amountInputLabel: "Сумма к оплате ",
    amountButtonTitle: "Баланс нагрузки",

    remainingSMS: "Остаток СМС кредита",
    licenseDetail: "Используемый пакет",
    licenseRemaining: "Оставшаяся лицензия",
    appointmentRemaining: "Остаток кредита встречи",
    paymentForm: "Банковские счета / Форма уведомления о платеже",
    buy: "Купить",
    package_prices: "Цены на пакеты",
    kdv: "Без учета НДС",

    load_balance: "Пополнить баланс",

    includesAll: "Включено во все лицензии",

    licenseHeading: "Купить лицензию",
    smsHeading: "Купить смс-пакет",
    appointmentHeading: "Получить кредит назначения",

    see_more: "Показать больше",
    see_less: "Показать меньше",
    sms_price: "Стоимость пакета SMS + WhatsApp",
    sms_package: "SMS-пакеты",
    wp_package: "Пакеты WhatsApp",

    licenses: "Лицензии",
    message_packages: "Пакеты сообщений",

    extra_license: "Дополнительные пакеты",

    bardcode_extra_package: "Сканер штрих-кода",
    whatsapp_extra_package: "WhatsApp мгновенных сообщений",
    online_link_extra_package: "Страница онлайн записи",

    bardcode_extra_package_tooltip:
      "При отслеживании запасов вы можете добавлять продукты, отсканировав их штрих-код. С помощью нашего приложения вы можете легко отсканировать штрих-коды с помощью камеры мобильного телефона и проводить продажи",
    whatsapp_extra_package_tooltip:
      "Устанавливается связь с вашим аккаунтом WhatsApp для мгновенного обмена сообщениями. Вы можете отправлять сообщения, щелкнув на иконку WhatsApp, если вы используете веб-версию, или отправлять сообщения через приложение WhatsApp, если вы используете мобильную версию",
    online_link_extra_package_tooltip:
      "Чтобы клиенты могли создавать запросы на запись на прием в ваш бизнес, этот раздел активирует необходимую ссылку. С помощью этой ссылки ваши клиенты могут создавать запросы на запись в любое время суток, указывая дату, время и вид услуги.",

    withEFT: "ТРАНСФЕРОМ",
    buyButtonTitle: "Купить",
    monthly_payment: "Ежемесячный платеж",
    yearly_payment: "Оплатить ежегодно",
    monthly: "Ежемесячно",

    check: {
      title: "Вы подтверждаете покупку?",
      confirmButtonTitle: "Утвердить",
      discardButtonTitle: "сдаться",

      alertText:
        "Вы одобряете покупку {package_name} с текущим балансом в {credit_card_amount}₺?",
      packageChangeAlert:
        "Оставшиеся {remaining_day} дней из вашего пакета {active_package} будут рассчитаны в соответствии с ценой за единицу и добавлены к вашему пакету {new_package} как {formula} дней.",
    },

    paymentType: {
      title: "Пожалуйста, выберите способ оплаты",
      creditCard: "Кредитная карта",
      eft: "Банковский перевод/Электронный платеж",
    },

    balanceErrorToast: "Пожалуйста, введите сумму для загрузки!",
    balanceAddWarnToast: "Транзакцию не удалось завершить!",

    packages: {
      silver: "Серебряный пакет",
      platinium: "Платиновый пакет",
      gold: "Золотой пакет",
      diamond: "Алмазный пакет",
    },

    packagesName: {
      silver: "Серебряный",
      platinium: "Платиновый",
      gold: "Золотой",
      diamond: "Алмазный",
    },

    count: "%s единиц",
  },

  "platform/photo": {
    photoUploadToast: "Загрузка вашей фотографии началась.",
    insertToast: "Загрузка фото успешно завершена.",
    deleteToast: "Фотография была успешно удалена.",
    anexample: "Пример демонстрации",
    uploadPhotoAlertTitle: "Загрузить фото витрины",
    selectPhotoTitle: "Выбрать фото",

    photoUploadErrorToast: "Не удалось добавить фотографии.",
    deletePhotoErrorToast: "Фото не удалось удалить.",

    photoInfo:
      "Представляет фотографию, которая будет отображаться на экранах поиска и списка.",
  },

  "platform/settings": {
    offOn: "Выкл. / Вкл.",
    chooseColor: "Выберите цвет",
    platformVisibleTitle: "Видимость бизнеса",
    platformVisibleDescription:
      "Это параметр для вашего бизнеса, чтобы он отображался/не отображался в результатах поиска клиентов на платформе назначения салонов. Если этот параметр отключен, ваш бизнес не будет отображаться в результатах поиска на платформе назначения салонов.",

    platformPriceTitle: "Видимость платы за услуги",
    platformPriceDescription:
      "Это настройка, позволяющая скрыть комиссию за услуги на сайте salonandevu.com и на странице бронирования встреч вашей компании. Если этот параметр отключен, стоимость ваших услуг не будет отображаться ни в одной из областей.",
    platformCommentTitle: "Видимость отзывов клиентов",
    platformCommentDescription:
      "Это настройка, позволяющая скрыть отзывы о вашей компании на salonandevu.com и на странице бронирования встреч для вашей компании. Если этот параметр отключен, отзывы клиентов о вашей компании не будут отображаться ни в одной из областей.",
    platfromAppointmentTitle: "Otomatik Randevu Onayı",
    platfromAppointmentDescription:
      "Это настройка автоматического подтверждения ваших запросов на встречу. Если этот параметр включен, получаемые вами запросы на встречу автоматически одобряются.",
    platformColorSelectionTitle: "Настройка цвета платформы",
    platformColorSelectionDescription:
      "В этом меню вы можете изменить цвет страницы бронирования встреч для вашего бизнеса.",
    platformVideoURLTitle: "URL-адрес видео платформы",
    platformVideoURLDescription:
      "Введенный вами URL-адрес видео YouTube будет отображаться посетителям на странице бронирования встреч вашей компании.",

    onlineAppointmentTitle: 'Настройка конфликта запроса на встречу',
    onlineAppointmentDesc: 'Когда вы активируете этот параметр, вы можете получать несколько запросов на встречу для одного и того же сотрудника и в одно и то же время. Если вы оставите его неактивным, если у вас есть запрос на встречу для того же сотрудника и на то же время, никаких других запросов на этого сотрудника и время делаться не будет.',

    platformVideoURLInputLabel: "URL-адрес видео",
    platformVideoURLInputPlaceholder: "Введите URL-адрес видео",
    appointmentVerificationCode: "Код подтверждения записи",
    appointmentVerificationCodeDescription:
      "Это настройка кода подтверждения для встреч, сделанных со страницы бронирования встреч вашей компании. Если для этого параметра выбрано значение «Вкл.», со страницы вашей встречи будет отправлен проверочный код SMS / WhatsApp для проверки и подтверждения номеров ваших клиентов, даже если их номера зарегистрированы в системе.",
    appointmentVerificationCodeDeliveryType:
      "Тип отправки кода подтверждения записи",
    appointmentVerificationCodeDeliveryTypeDescription:
      "Этот параметр используется для отправки кодов подтверждения для встреч, назначенных на странице бронирования встреч вашей компании. В зависимости от выбранного вами метода проверки выбирается метод отправки проверочного кода, который будет отправлен вашему клиенту со страницы личной встречи.",
    colorDialogTitle: "Выбор цвета",
    colorDialogCancelTitle: "Отменить цвет",
    colorDialogSelectTitle: "ХОРОШО",

    updateSettingsSuccessToast: "Настройки платформы успешно обновлены.",
    updateSettingsWarnToast: "Не удалось обновить настройки платформы.",
    updateSettingsButtonTitle: "Обновить настройки",
  },

  "platform/categories": {
    howto: {
      title: "Как это делается?",
      content: [
        "Слева — сервисы, которые вы создали (ваши существующие сервисы), а справа — сервисы, которые появятся на платформе.",
        "Выберите свои услуги, которые вы хотите добавить на платформу.",
        "Разверните категорию, в которой выбранные вами услуги будут отображаться на Платформе.",
        "Если выбранные вами услуги соответствуют услугам в расширенной вами категории, выберите эту услугу и нажмите кнопку «Передать».",
      ],
    },
    knowrequired: {
      title: "Что тебе нужно знать",
      content: [
        "Вы можете выбрать и переместить несколько сервисов одновременно.",
        "Ваши службы, перенесенные на платформу, больше не будут отображаться на вкладке «Существующие службы».",
        "Вы не можете сопоставить службу более чем с одним именем службы категории.",
        "Если выбранные вами услуги соответствуют услугам в расширенной вами категории, выберите эту услугу и нажмите кнопку «Передать».",
        "Вы можете использовать крестик рядом с именем службы, чтобы удалить несоответствующие вам службы, и удалить эту службу обратно на вкладку «Ваши существующие службы.",
        "При добавлении новой услуги выбор категории-услуги для платформы также был добавлен на страницу «Добавить новую услугу».",
      ],
      label1: "Если созданные вами услуги не соответствуют категориям или операциям на платформе",
      label2: "iletisim@salonrandevu.com",
      label3: "Вы можете сообщить о своем запросе по электронной почте на",
    },
    alertTitle: "Для отображения ваших услуг на платформе Salon Randevu вам необходимо сопоставить созданные вами услуги с услугами платформы.",
    serviceTitle: "Ваши существующие услуги",
    transferButtonTitle: "Передать",
    categoriesTitle: "Категории",
  },

  /**
   * İşletme Ayarları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings
   */
  settings: {
    idInputLabel: "Код Холла",
    nameInputLabel: "Название зала",
    mailInputLabel: "Адрес электронной почты зала",
    phoneInputLabel: "Номер телефона зала",
    aboutCompanyLabel: "О бизнесе",

    currencyType: "Валюта по умолчанию",
    countryInputLabel: "Выбор страны",
    cityInputLabel: "Провинциальные выборы",
    districtInputLabel: "Выбор района",
    addressInputLabel: "Открытый адрес",
    mapsInputLabel: "Бизнес-координаты Google Карт",
    mapsInputHint: "Например: 41.009336, 28.660129",
    currentLocationButtonTitle: "Использовать Мое мгновенное местоположение",

    instagramInputLabel: "Имя пользователя в Instagram",
    facebookInputLabel: "Имя пользователя страницы Facebook",
    websiteInputLabel: "URL-адрес веб-страницы",
    withoutAtHint: "Вводить без знака @",

    priceInputLabel: "Настройка информации о цене",
    priceInputHint:
      "Это настройка того, следует ли показывать информацию о цене выбранной транзакции вашим клиентам, которые будут запрашивать встречу через ваш корпоративный URL-адрес.",

    commentsInputLabel: "Настройка отзывов клиентов",
    commentsInputHint:
      "Устанавливает, следует ли показывать комментарии ваших клиентов к опросам в URL вашей компании.",
    systemLanguage:
      "Изменение языка системы может быть установлено на английский/турецкий язык.",
    inputOn: "Открытым",
    inputOff: "Закрыто",

    urlInputLabel: "URL-адрес деловой встречи",
    openOnBrowserButtonTitle: "Открыть в браузере",
    copyLinkButtonTitle: "Копировать ссылку",
    copyLinkToast: "Ссылка успешно скопирована.",
    updateButtonTitle: "Обновить информацию",

    geolocationErrorToast: "Ваш браузер не поддерживает геолокацию.",
    locationErrorToast:
      "Не удалось определить ваше местоположение. Повторите попытку позже.",
    updateToast: "Информация успешно обновлена.",

    currentPassword: "Текущий пароль",
    newPassword: "Новый пароль",
    newPasswordAgain: "Подтвердите новый пароль",
    updateInfo: "Обновить информацию",

    uploadPhotoWarnToast: "Логотип готовится...",
    uploadPhotoSuccessToast: "Логотип успешно загружен.",

    steps: [
      "Шаг 1",
      "Шаг 2",
      "Шаг 3",
      "Шаг 4",
    ],
    stepBack: "Назад",
    stepNext: "Далее",
    shortURL: "Сокращенный URL для записи на прием в бизнес",
    shortURLWarnToast: "Сокращение не определено. Пожалуйста, свяжитесь с вашим представителем.",
    countryCode: "Код страны",
    dialCode: "Код набора",

    noLogoTitle: "Логотип для этого бизнеса не найден.",
    updateLogo: "Обновить логотип",
    setLogo: "Нажмите здесь, чтобы загрузить логотип для этого бизнеса.",
    settingsAlertText: "Вы собираетесь внести изменения в время записи / начало / конец / частоту!! Все закрытые часы в профилях ваших сотрудников вернутся к значению по умолчанию, и вам придется их переработать. Вы подтверждаете?",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/hours
   */
  "settings/hours": {
    businessCategory: "Категория компании",
    startInputLabel: "Время начала работы",
    endInputLabel: "Время окончания работы",
    periodBetweenInputLabel: "Частота встреч (минуты)",
    startInputLabelHour: "Время начала",
    startInputLabelMinute: "Из минут",
    intervalInputLabel: "Интервал (минуты)",

    endInputLabelHour: "Время окончания",
    endInputLabelMinute: "В минутах",

    updateButtonTitle: "Обновить информацию",

    updateToast: "Информация успешно обновлена.",
    compareErrorToast:
      "Поле «Время окончания» не может быть меньше поля «Время начала».",
  },

  /**
   * İşletme Galerisi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/gallery
   */
  "settings/gallery": {
    numberPhoto: "Общее количество фотографий, которые вы можете загрузить",
    uploadButtonTitle: "Щелкните эту область, чтобы загрузить фотографию.",
    anexample: "Пример шоу",
    nextPhoto: "Следующее фото",
    uploadPhotoButtonTitle: "Загрузить",

    checkAllButtonTitle: "Выбрать все фотографии",
    deleteSelectedButtonTitle: "Удалить выбранные фотографии ({count})",

    delete: {
      // Modal
      title: "Вы подтверждаете удаление?",
      confirmButtonTitle: "Утвердить",
      discardButtonTitle: "сдаться",

      alertBoldText: "Вы одобряете это действие?",
      alertText:
        "Если вы выполните процесс удаления, удаленные фотографии будут удалены со всех связанных страниц.",
    },

    photoUploadToast: "Загрузка вашей фотографии началась.",
    insertToast: "Загрузка фото успешно завершена.",
    deleteToast: "Фотография была успешно удалена.",

    photoMorePictureError: "Можно загрузить не более 8 фотографий.",

    enterprise_gallery_info:
      "Вы можете добавить фотографии вашего бизнеса из этой области. Эти изображения будут отображаться на платформе назначения салонов и на вашей бизнес-странице.",
  },

  "settings/service-gallery": {
    numberPhoto: "Общее количество фотографий, которые вы можете загрузить",
    uploadButtonTitle: "Щелкните эту область, чтобы загрузить фотографию.",
    anexample: "Пример шоу",

    photoDelete: "Выберите фотографию, которую нужно удалить",

    checkAllButtonTitle: "Выбрать все фотографии",
    deleteSelectedButtonTitle: "Удалить выбранные фотографии ({count})",

    delete: {
      // Modal
      title: "Вы подтверждаете удаление?",
      confirmButtonTitle: "Утвердить",
      discardButtonTitle: "сдаться",

      alertBoldText: "Вы одобряете это действие?",
      alertText:
        "Если вы выполните процесс удаления, удаленные фотографии будут удалены со всех связанных страниц.",
    },

    photoUploadToast: "Загрузка вашей фотографии началась.",
    insertToast: "Загрузка фото успешно завершена.",
    deleteToast: "Фотография была успешно удалена.",

    photoMoreUploadError: "Можно загрузить не более 8 фотографий.",

    service_photo_info:
      "Вы можете добавить изображения, связанные с услугами, которые вы предоставляете в этой области.Эти изображения будут отображаться на платформе назначения салонов и на вашей бизнес-странице.",
  },

  /**
   * Sistem Logları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/logs
   */
  "settings/logs": {
    headings: {
      createdAt: "Дата совершения операции",
      message: "Описание транзакции",
      detail: "Сведения о транзакции",
      ip: "Айпи адрес",
    },

    categoryList: {
      title: "Категории записей",
      all: "Все записи",
      cus: "Записи клиентов",
      rec: "Записи чека",
      staf: "Записи сотрудников",
      appo: "Записи встреч",
      sms: "Записи настроек SMS"
    },
  },

  /**
   * 404 sayfasının bulunduğu key.
   * https://app.salonrandevu.com/404
   */
  notFound: {
    heroHeadingText: "Извините, мы не смогли его найти!",
    boldText:
      "К сожалению, мы не смогли найти страницу, которую вы искали или на которую пытались перейти.",
    text: "Возможно, он был удален, перемещен или не создан.",
  },

  care: {
    careTitle: "Мы на обслуживании...",
    careLabel1: "Чтобы предоставить вам лучший сервис,",
    careLabel2: "Обслуживание будет проводиться с %s по %v.",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/agreement/index
   */

  "settings/agreements": {
    agreementTemplates: "Шаблоны соглашений",
    createNewAgreement: "Создать новое соглашение",
    editAgreement: "Редактировать соглашение",
    agreementClauses: "Пункты соглашения",
    agreements: "Соглашения",

    delete: "Удалить",
    edit: "Редактировать",
    close: "Закрыть",

    yesOrNo: "Да / Нет",

    createTamplateArea: {
      personalInformation: "Персональная информация",
      paymentInformation: "Информация о платеже",
      clausesInformation: "Информация о пунктах",
      label: "Новый шаблон",
      placeHolder: "Новый шаблон соглашения",
      saveTamplate: "Сохранить шаблон",
      editTamplate: "Редактировать шаблон",
      cancel: "Отмена",
      placeHolders: "Информация о компании",
    },

    personal_info: {
      name_and_surname: "Имя и фамилия",
      phone: "Номер телефона",
      birth_date: "Дата рождения",
      job: "Профессия",
      id_number: "Идентификационный номер",
      tax_number: "Номер налога",
      address: "Адрес",
    },
    payment_info: {
      staff_name: "Имя сотрудника",
      service: "Услуга",
      total_amount: "Общая сумма",
    },

    createClausesArea: {
      createNewClauses: "Создать новый пункт",
      title: "Заголовок пункта",
      context: "Содержание пункта",
      yesOrNo: "Добавить вопрос Да / Нет",
      update: "Обновить",
      save: "Сохранить",
      cancel: "Отмена",
      fillAreaComplatelty: "Пожалуйста, заполните все поля.",
    },

    createPdf: {
      companyNamePlaceHolder: "Название компании",
      agreementTitle: "Заголовок соглашения",
    },

    agreementsList: {
      date: "Дата",
      nameSurname: "Имя и фамилия",
      agreementName: "Название соглашения",
      size: "Размер",
      actions: "Действия",
      startDate: "Дата начала",
      endDate: "Дата окончания",
      searchCustomer: "Поиск клиента",
    },

    agreementRequstFail:
      "Произошла непредвиденная ошибка. Пожалуйста, свяжитесь с вашим представителем.",

    agreementClausesSavedSucces: "Пункт соглашения успешно сохранен.",
    agreementClausesEditSucces: "Пункт соглашения успешно отредактирован.",
    agreementClausesDeleteSucces: "Пункт соглашения успешно удален.",

    agreementSaveSucces: "Шаблон соглашения успешно сохранен.",
    agreementEditSucces: "Шаблон соглашения успешно отредактирован.",
    agreementDeleteSucces: "Шаблон соглашения успешно удален.",

    deleteClausesModal: {
      title: "Вы уверены, что хотите удалить этот пункт соглашения?",
      delete: "Удалить",
      cancel: "Отмена",
      expTitle: "Это действие не может быть отменено!",
      expDetail: "Пункт соглашения будет удален навсегда.",
    },

    deleteAgreementModal: {
      title: "Вы уверены, что хотите удалить это соглашение?",
      delete: "Удалить",
      cancel: "Отмена",
      expTitle: "Это действие не может быть отменено!",
      expDetail: "Соглашение будет удалено навсегда.",
    },

    agreement: {
      yes: "Да",
      no: "Нет",
      signiture: "Подпись",
    },

    smsAproove: {
      title: "Хотите отправить зарегистрированное соглашение по SMS?",
      send: "Отправить",
      cancel: "Отмена",
      textFirst: "Вы уверены, что хотите отправить соглашение на имя",
      textSecond: "по SMS?",
    },

    wpAproove: {
      title: "Хотите отправить зарегистрированное соглашение через WhatsApp?",
      send: "Отправить",
      cancel: "Отмена",
      textFirst: "Вы уверены, что хотите отправить соглашение на имя",
      textSecond: "через WhatsApp?",
    },

    deleteAgreement: {
      title: "Вы уверены, что хотите удалить это соглашение?",
      aproove: "Подтвердить и удалить",
      cancel: "Отмена",
      infoTitle: "Соглашение будет удалено навсегда.",
      infoContext:
        "Это действие не может быть отменено. Соглашение будет удалено навсегда.",
    },
  },

  /**
   * Paket yükselt uyarısının bulunduğu key.
   */
  planUpgrade: {
    heroHeadingText: "Обновите свой пакет!",
    boldText: " Чтобы использовать этот модуль, вам необходимо обновить пакет.",
    text: "Если вы считаете, что возникла проблема, вы можете связаться с нами по нашим каналам связи.",
    showPackagesButtonTitle: "Посмотреть пакеты",
    callUsButtonTitle: "Позвоните нам - 0(212) 706 0510",
  },

  /**
   * İşletme URL'si sayfasının bulunduğu key.
   * https://app.salonrandevu.com/isletme/22
   */
  businessURL: {
    callButtonTitle: "Позвоните сейчас (0{phone})",
    instagramHeading: "Подписывайтесь на Instagram!",
    instagramText: "в инстаграме @{username}",

    facebookHeading: "Следите за новостями в Facebook!",
    facebookText: "На Фейсбуке @{username}",

    aboutHeading: "Деловая информация",
    phoneText: "Телефон",
    mailText: "Эл. адрес",
    addressText: "Адрес",

    appointment: {
      // Tab
      tab: "Создать встречу",
      title: "Создать запрос на встречу",

      nameInputLabel: "Твое имя",
      surnameInputLabel: "Ваша фамилия",
      phoneInputLabel: "Мобильный телефон",
      serviceInputLabel: "Выбор услуги",
      serviceInputHint: "Выберите из услуг",
      staffInputLabel: "Подбор сотрудников",
      staffInputHint:
        "Выберите сотрудника, который позаботится о вас на приеме",
      staffInputCompanyHint:
        "Выберите сотрудника, который будет заботиться о вас на приеме. (В списке сотрудников перечислены сотрудники, которые предоставляют выбранную вами услугу.)",
      dateInputLabel: "Назначенная дата",
      dateInputHint:
        "Вы можете либо написать дату встречи вручную, либо выбрать ее, щелкнув значок календаря.",

      availableHoursButtonTitle: "Доступные часы поиска",

      check: {
        // Modal
        title: "Отправить запрос на встречу",
        confirmButtonTitle: "Да",
        discardButtonTitle: "Нет",

        alertBoldText: "Уважаемый {name} {surname}",
        alertText: "Вы одобряете создание встречи на {date}, время {time}?",

        priceText:
          "Общая комиссия за выбранную вами транзакцию {service} была определена компанией в размере {amount}₺.",
        priceBoldText:
          "Эта цена дана в ознакомительных целях и не является определенной суммой сделки.",

        noteInputLabel: "Записка о встрече (необязательно)",
        noteInputHint: "%s/255 символов",
      },

      insertToast: "Ваша заявка на встречу успешно создана.",

      appointment_button: "записаться на прием",

      appointmentComingText: "Вы придете на назначенную встречу %s?",
      appointmentConfirmationText: "Ваша встреча подтверждена. Спасибо.",
      appointmentCancelingText1: "Ваша встреча будет отменена. Вы уверены?",
      appointmentCancelingText2: "Ваша встреча отменена. Спасибо.",
      appointmentDatePostponeText: "Хотите перенести встречу на другую дату?",
      businessPhoneText: "Телефон бизнеса: ",
      getDirectionText: "Получить направление",
      appointmentRatingSuccessToast: "Спасибо, что нашли время оценить нас!",
      appointmentRatingWarnToast: "Поля оценки бизнеса и оценки сотрудников обязательны для заполнения!",
      evaluationSurverTitle: "Анкета оценки",
      evaluationSurverSubtitle: "Анкета оценки удовлетворенности клиентов",
      evaluationSurverText1: "Этот опрос является местом, где вы можете передать уровень своего удовлетворения бизнесом и его персоналом.",
      evaluationSurverText2: "Ваше имя и фамилия будут скрыты и переданы в бизнес с учетом конфиденциальности.",
      evaluationSurverQuestion1: "Оцените бизнес по шкале от 0 до 5",
      evaluationSurverQuestion2: "Оцените сотрудников по шкале от 0 до 5",
      evaluationSurverInputLabel: "Напишите ваши впечатления и мнения",
      routeToBusinessURL: "Вас перенаправляют на страницу бизнеса",

      appointmentHourErrorTitle: "*Не найдено доступное время для записи на выбранную дату.",
      appointmentAccept: "Подтверждение записи",
      appointmentAcceptCodeText: "Введите 4-значный код подтверждения, отправленный на ваш телефон, чтобы подтвердить запись.",
      wrongCodeErrorText: "Неверный код",
      sendAgainText: "Отправить код подтверждения снова",
    },

    servicesGallery: {
      title: "Галерея услуг",
    },

    services: {
      // Tab
      tab: "Перечень услуг",
      title: "Перечень услуг",
      processTime: "Время обработки: {time} минут",
    },

    staffs: {
      //Tab
      tab: "Список сотрудников",
      title: "Список сотрудников",
    },

    comments: {
      // Tab
      tab: "Комментарии клиентов",
      title: "Комментарии",
    },
  },

  "support/live_support": {
    liveSupportRequestTitle: "Запрос поддержки в реальном времени",
    appointmentDate: "Дата и время встречи",
    appointmentRequestTitle: "Запросы на поддержку",
    createAppointmentButtonTitle: "записаться на прием",

    enterPhoneLabel: "Введите номер телефона",
    name: "Твое имя",
    surname: "Ваша фамилия",
    supportrequestsubject: "Тема запроса на поддержку",
    supportrequestdetail: "Сведения о запросе в службу поддержки",
    hours: "Час",

    nameInputLabel: "Имя",
    nameInputHint: "Пожалуйста, введите Ваше имя",

    surnameInputLabel: "Фамилия",
    surnameInputHint: "Пожалуйста, введите свою фамилию",

    phoneNumberInputLabel: "Телефонный номер",

    supportRequestAboutLabel: "Тема запроса на поддержку",
    supportRequestAboutHint: "Напишите тему запроса в поддержку",

    dateInputLabel: "Назначенная дата",
    noAvailableHours: "Seçebileceğiniz mevcut bir saat bulunamadı.",

    supportRequestDetailLabel: "Сведения о запросе в службу поддержки",
    supportRequestDetailHint:
      "Предоставьте подробное объяснение проблемы, по которой вы запрашиваете поддержку.",
    supportRequestDetailHelperText:
      "Пожалуйста, подробно объясните действия, которые вы предприняли по этому вопросу, и проблему, с которой вы столкнулись. Предоставленная вами информация позволит нам помочь вам самым быстрым и точным способом.",

    appointmentCancelDescription:
      "Важно: не забудьте отменить встречу, если ваша проблема была решена до времени поддержки.",

    headings: {
      appointmentDate: "Назначенная дата",
      appointmentAbout: "Тема",
      appointmentDetail: "Деталь",
      name: "Имя Фамилия",
      phoneNumber: "Телефон",
      appointmentStatus: "Статус встречи",
      cancelButtonTitle: "Отмена",

      replacements: {
        status: {
          waiting: "Ожидающий",
          done: "Обсуждено",
          cancel: "это отменено",
        },
      },
    },

    alertInfo: {
      text1:
        " Мы позвоним вам по телефону 02121060510 в удобное для вас время. Пожалуйста, несмотря на необходимость удаленного подключения",
      text2: "Программа удаленного подключения ALPEMIX",
      text3: "Загрузите и запустите его. В любое время вы хотите",
      text4: "Пассивные клиенты",
      text5: "Вы можете получить доступ к информации о клиенте из вкладки.",
    },

    createLiveSupportRequestToast: "Ваша заявка на встречу успешно создана.",
    cancelLiveSupportRequestToast: "Запрос на встречу успешно отменен.",
  },

  /**
   * Birden fazla yerde kullanılan sabit tanımlar bu alanda yazılmaktadır.
   */
  component: {
    actionHeadingText: "Транзакции",
    actionHeadingTextRating: "Комментарии Действия",
    detailEditButtonTitle: "Детализация и редактирование",
    validationErrorText: "Отредактируйте поля в нужном формате",
    loadingText: "Загрузка...",
    loadingTextWithoutDot: "Загрузка",

    "customer/customer_detail": {},

    smartBanner: {
      // Loginde mobil uygulama yayında banner'ı
      onAirText: "Наше приложение работает!",
    },

    timerButton: {
      waitText: "Ждать",
    },

    excelButton: {
      title: "Скачать как Эксель",
    },

    datePickerInput: {
      // Datepicker Input
      cancelLabel: "ОТМЕНА",
      clearLabel: "ЧИСТЫЙ",
      okLabel: "ПОЛНЫЙ",
    },

    dateRangeStats: {
      // Dashboard'daki istatistik kartları
      today: "ежедневно",
      week: "еженедельно",
      month: "ежемесячно",
      lastWeek: "Последние 7 дней",
      lastMonth: "Последние 30 дней",
      yesterday: "Вчера",
      last_15_days: "Последние 15 дней",
    },

    CADialog: {
      // Customer Add Dialog
      title: "Быстрое создание клиента",
      createButtonTitle: "Создать клиента",
      discardButtonTitle: "сдаться",

      nameInputLabel: "Имя Клиента",
      nameInputPlaceholder: "Введите имя клиента",

      surnameInputLabel: "Фамилия клиента",
      surnameInputPlaceholder: "Введите фамилию клиента",

      phoneInputLabel: "Мобильный телефон",

      updateCustomerDetails: "Обновить клиента",
      changeCutomertoActive: "Активировать клиента",
      changeCutomertoPassive: "Отключить клиента",

      genderInputLabel: "Пол",
      genderMale: "Мальчик",
      genderFemale: "Женщина",

      birthdayInputLabel: "Дата рождения",
      birthdayInputInvalidMessage:
        "Пожалуйста, введите в желаемом формате. День месяц год",

      maritalStatusInputLabel: "семейное положение",
      maritalStatusMarried: "женатый",
      maritalStatusSingle: "Один",

      delete: {
        title: "Вы подтверждаете удаление?",
        confirmButtonTitle: "Да",
        discardButtonTitle: "Нет",
        alertText: "Этот процесс необратим!",
        alertTitleText:
          "Если вы удалите этого клиента, возможно, вы больше не сможете получить доступ к информации о нем.",
      },

      checkingForeingNumber: "Отметить как иностранный номер",

      confirmChangeCustomerTitle: "Одобряете ли вы пассивный тюнинг?",
      confirmCustomerTitle:
        "Если вы деактивируете этого клиента, вы больше не сможете видеть его в таких областях, как встречи/счета.",
      confirmTitle1: "В любое время вы хотите",
      confirmTitle2: "Пассивные клиенты",
      confirmTitle3:
        "Вы можете получить доступ к информации о клиенте из вкладки.",

      secondPhoneInputLabel: "Второй номер телефона",
      secondPhoneInputPlaceholder: "Если есть, введите второй номер телефона.",

      mailInputLabel: "Адрес электронной почты",
      mailInputPlaceholder: "Если есть, введите свой адрес электронной почты.",

      descriptionInputLabel: "Аннотации",

      workStatusInputLabel: "Рабочий статус",
      workStatusWorking: "работающий",
      workStatusUnemployed: "Не работает",
      workStatusEmployer: "Работодатель",
      workStatusStudent: "Ученик",
      workStatusHouseWife: "Домохозяйка",

      relatedBusinessInputLabel: "Соответствующее учреждение",
      relatedBusinessInputPlaceholder:
        "Пожалуйста, укажите, из какого учреждения пришел клиент.",

      sendSmsInputLabel: "Статус отправки СМС",
      sendSmsTrue: "Отправь это",
      sendSmsFalse: "Не отправлять",

      advancedOptions: "Расширенные опции",
      advancedOptionsHide: "Скрывать",
      advancedOptionsShow: "Шоу",

      insertToast: "Регистрация клиента успешно создана!",
    },

    appointment_notification: {
      description: "У вас новое назначение. Желаем вам хорошей работы.",
      closeButtonTitle: "ХОРОШО",
      appointmentsButtonTitle: "Назначения",
    },

    allServicesDialog: {
      serviceCategoryTitle: "Категории услуг",
      selectedServicesTitle: "Услуги, которые вы выбираете",
      loading: "Загрузка...",
      noSelectedService: "Нет выбранной услуги.",
      notFoundService: "Услуга, которую вы ищете, не может быть найдена.",
      transferButtonTitle: "Перенести выбранные услуги",

      successToast: "Ваши услуги были успешно переданы.",
    },

    sms_review: {
      platformTitle: "Платформа для отправки",
      smsReviewTitle: "Предварительный просмотр сообщения",
      totalNumberTitle: "Общее количество человек для отправки:",
      totalWPCreditTitle: "Всего кредитов WhatsApp:",
      totalSMSCreditTitle: "Общая сумма SMS-кредитов:",
      smsContentTitle: "Содержание сообщения:",

      inUseSMSCredit: "Общее количество SMS-кредитов для использования:",
      inUseWPCredit: "Общее количество кредитов WhatsApp для использования:",

      sendButtonTitle: "Отправлять",
      discardButtonTitle: "Отмена",
    },

    staffOrderDialog: {
      staffOrderTitle: "Сортировать сотрудников",
      closeButtonTitle: "Закрывать",
    },

    NADDialog: {
      createAppointment: "Создать встречу",
    },

    OARDialog: {
      title: "Онлайн-запросы на прием",
    },

    filterAppointmentDialog: {
      title: "Фильтровать встречи",
    },

    dayWeekButton: {
      day: "День",
      week: "Неделю",
    },

    semiCirclePie: {
      noDisplayChartDescription:
        "График не отображается, так как значения равны нулю.",
    },
  },

  panel: {
    businessNumber: "Номер бизнеса:",
    licenseRemaining: "дней осталось",
    sms_credit: "смс",
    wp_notConnected: "Устройство не подключено",
    searchCustomer: "Поиск клиента",
    remainingAppointment: "назначение осталось",
    changeLanguage: "изменение языка",
  },

  panelTabs: {
    moreFavoriteErrorToast: "Вы можете добавить до 7 избранных.",
  },
  licenseDetails: {
    currentPackage: "(Текущий пакет)",
    features: "Характеристики",
    silver: [
      "До 3 сотрудников",
      'Специальный подарок "50 приемов" для новых участников',
      'Специальный подарок "100 SMS" для новых участников',
      "100 новых приемов всего за 119₺",
    ],
    gold: [
      "До 5 сотрудников",
      "Неограниченное количество приемов",
      "Управление несколькими филиалами",
      "Персональный менеджер по обслуживанию клиентов",
      "Галерея бизнеса",
      "Отправить SMS",
      'Специальный подарок "250 SMS" для новых участников',
    ],
    platinum: [
      "До 7 сотрудников",
      "Транзакции по счетам",
      "Управление пакетами",
      "Управление запасами",
      "Транзакции с бонусами",
      'Специальный подарок "500 SMS" для новых участников',
      "Расширенные отчеты",
      "Неограниченное количество приемов",
      "Онлайн-сайт для записи на прием",
      "Управление несколькими филиалами",
      "Персональный менеджер по обслуживанию клиентов",
    ],
    diamond: [
      "До 10 сотрудников",
      "Плагин электронной подписи",
      "Управление договорами",
      "Операции по выставлению счетов",
      "Управление пакетами",
      'Специальный подарок "2000 SMS" для новых участников',
      "Управление запасами",
      "Бонусные операции",
      "Расширенные отчеты",
      "Неограниченные записи на прием",
      "Онлайн-сайт записи на прием",
      "Управление несколькими филиалами",
      "Индивидуальный представитель по обслуживанию клиентов",
      "Интеграция с WhatsApp",
      "Сканер штрих-кода",
      "Ссылка на подтверждение прихода на прием",
      "Онлайн-ссылка для записи на прием",
    ],
    includesAllLicence: [
      "Управление бизнесом",
      "Управление клиентами",
      "Управление сотрудниками",
      "Отправка SMS",
      "Напоминание о приеме",
      "Управление приемом",
      "Бизнес-галерея",
      "Список дел",
    ],

    packageNames: {
      goldM: "Золотой Пакет (1 Месяц)",
      goldY: "Золотой Пакет (12 Месяцев)",
      platM: "Платиновый Пакет (1 Месяц)",
      platY: "Платиновый Пакет (12 Месяцев)",
      diaM: "Алмазный Пакет (1 Месяц)",
      diaY: "Алмазный Пакет (12 Месяцев)",
    },

    renewalOpportunity: "Эксклюзивная возможность продления лицензии для вас",
    licenseTooltipGold: "Подходит для компаний, желающих управлять записями на прием",
    licenseTooltipPlat: "Подходит для компаний, желающих управлять записями, бухгалтерией и премиями",
    licenseTooltipDia: "Подходит для компаний, желающих управлять контрактами, записями, бухгалтерией и премиями",
    allSpecs: "смотреть все функции",
    worth: "Доход",
    monthlyPaymentTitle: "/ в месяц",
    buyNowTitle: "Купить сейчас",
    moreDesc: "Получить дополнительную информацию",
    allFeatures: "Все функции",
    renewalTitle: "Не упустите возможность продления лицензии!",
    yearly: "Ежегодно",
    yearlyTitle: "оплатить (12 месяцев)",
    buyDetailsTitle: "Детали покупки",
    payNowTitle: "Оплатить сейчас",
    paymentPage: "Цены на странице оплаты",
    taxNotIn: "НДС не включен.",
    taxIn: "+Сумма НДС",

    lastDate: "Последние %s Дней",

    firstBuyText: "Специальное предложение для первой покупки",
    discountText: " Сниженные цены!",
    goldPlusText: "Хотите воспользоваться функциями Gold Plus?",
    platPlusText: "Хотите воспользоваться функциями Platinium Plus?",
    plusSpecsTitle: "Функции пакета PLUS",
    instead: "вместо",
    only: "ТОЛЬКО",
    takeOffTitle: "Удалить",

    wpIntegrationTitle: " Интеграция с WhatsApp",
    appointmentLinkTitle: "Ссылка для участия в записи",
    thousandCreditGiftTitle: "Подарок: 1000 SMS-WhatsApp!",
    thousandCreditTitle: "1000 SMS - WhatsApp",
    barcodeReaderTitle: "Сканер штрих-кодов",
  },
  tutorial: {
    description: "Выполняйте миссии и зарабатывайте подарки",
    is_success: "Собирайте награды",
    title: "Задания",
    button_wheel: "Вращать колесо",
    wheel_title: "Вращайте колесо и выиграйте свой подарок!",
    wheel_prize: "Вы выиграли: выполните миссии, чтобы получить награду!",
  },
  campaigns: {
    footer: {
      salonAPP: "#1 ПРИЛОЖЕНИЕ ДЛЯ САЛОНОВ",
      title1: "Если вы ищете качество и надежность в управлении салоном,",
      title2: "вы обратились по адресу.",
      getInfo: "Хочу получить информацию",
      askWp: "Спросить в WhatsApp",
      wouldLike:
        "Хотите, чтобы мы рассказали вам о преимуществах .app Premium?",
      name: "Ваше Имя",
      surname: "Ваша Фамилия",
      phone: "Ваш Номер Телефона",
      companyName: "Название Вашей Компании",
      callMe: "Позвони мне",
      sendMessageErrorToast: "Отправка не удалась.",
      sendMessageWarnToast: "Пожалуйста, введите полное имя, фамилию или номер телефона.",
    },
    header: {
      title1: "Красота для вашего салона",
      title2: "Здесь",
      title3: `Будь то дома, в отпуске, в салоне, в машине или где бы вы ни находились,
               с самым всеобъемлющим в Турции Платформой для управления салоном,
               Salon Randevu APP, всё работает так, как вам нужно. Вы можете
               управлять записями, контролировать персонал, проверять запасы и
               отправлять персонализированные SMS вашим клиентам. Всё здесь
               спроектировано для того, чтобы вам было проще управлять вашим салоном.`,
      title4: `Выберите один из предложенных выше пакетов, который подходит вам, и
               воспользуйтесь специальным контентом, созданным для вас.`,
    },
    aboutApp: [
      {
        title: "Страница записи в салон",
        context:
          "Предоставьте своим клиентам возможность записываться на приём онлайн через специально созданную страницу записи для вашего салона. Устраните ограничения по рабочему времени и предотвратите потерю потенциальных клиентов с помощью онлайн-системы записи.",
      },
      {
        title: "Управление клиентами",
        context:
          "Облегчите отслеживание клиентов, формируя подробные профили и историю записей для каждого из них. Повышайте лояльность клиентов, предлагая каждому индивидуальные услуги.",
      },
      {
        title: "Управление бухгалтерией",
        context:
          "Держите свою финансовую ситуацию под контролем, записывая доходы и расходы. Ежедневные и ежемесячные отчёты о продажах, дебиторской задолженности, премиях персонала, запасах и продажах продукции позволят вам держать всё под контролем.",
      },
      {
        title: "Управление записями",
        context:
          "Предотвращайте финансовые потери из-за пропущенных записей и экономьте время с помощью этой автоматизации. Это обязательная функция для тех, кто ценит каждую минуту.",
      },
      {
        title: "Мобильное приложение",
        context:
          "Управляйте своим салоном 24/7 через мобильное приложение Salon Randevu, будь то на компьютере или в пути.",
      },
      {
        title: "Расширенная отчётность",
        context:
          "С помощью расширенной отчётности вы можете просматривать и оценивать производительность вашего бизнеса за ежедневные, еженедельные и ежемесячные периоды и делать необходимые сравнения.",
      },
      {
        title: "Сообщения WhatsApp",
        context:
          "Теперь можно отправлять напоминания о записях, опросы и уведомления через WhatsApp вместо SMS.",
      },
      {
        title: "Электронные контракты",
        context:
          "Избавьтесь от бумажных контрактов и сохраняйте свои контракты со всеми данными для каждого клиента и получайте цифровые подписи.",
      },
      {
        title: "Система чеков",
        context:
          "Подробная панель управления чеками предоставляет нашим пользователям возможность отображать и отслеживать платежи за продажу пакетов, услуг или товаров на одной странице.",
      },
      {
        title: "Отчёты персонала",
        context:
          "Ваш главный ресурс — время. Чтобы максимизировать доход, вы хотите, чтобы ваш персонал всегда предоставлял лучший сервис. Важно всегда оставаться сосредоточенным и предоставлять 100% сервис, чтобы стать идеальным бизнесом.",
      },
      {
        title: "SMS-уведомления",
        context:
          "Приложение Salon Randevu сделало напоминания простыми и весёлыми. Быстро и легко отправляйте и автоматизируйте напоминания, чтобы ваши клиенты никогда не пропускали запись.",
      },
      {
        title: "Управление запасами",
        context:
          "Отслеживайте продажи продукции и статус запасов с помощью приложения Salon Randevu и избегайте ошибок. Держите свои данные в актуальном и точном состоянии с помощью контроля запасов и управляйте продажами продукции с помощью ежедневных и ежемесячных отчётов.",
      },
    ],
    sayAboutUs: {
      title1: "ТО, ЧТО НАШИ ПОЛЬЗОВАТЕЛИ ЛЮБЯТ БОЛЬШЕ ВСЕГО",
      title2: "Особенности",
      title3:
        "Красиво разработанное, простое в использовании веб- и мобильное приложение",
      title4: "Легко управляйте своим бизнесом с богатым функционалом",
      title5: "Круглосуточная поддержка в любое время, когда вам это нужно",
      title6: `"В выходные, вечерами или когда я был за пределами своего центра,
              запись на прием всегда означала ношение с собой блокнота
              и ручки. Теперь блокнот и ручка полностью исчезли из
              моей жизни; я могу мгновенно записаться на прием с
              моего телефона круглосуточно, что для меня невероятное
              удобство. С точки зрения моих клиентов, с момента
              перехода на систему я заметил невероятное изменение
              имиджа, и они сказали мне, что эта программа очень
              профессиональна..."`,
      title7: `"С тех пор как мы начали наше членство в салоне, вся
              команда была очень внимательна и помогала во всех
              вопросах. Особенно хочу поблагодарить госпожу Элиф за
              ее усилия и самоотверженность. Программа значительно
              уменьшила нашу рабочую нагрузку, и я настоятельно
              рекомендую ее."`,
      title8: `"Отличная программа, приложение очень успешное:
              Оно безупречно работает для записи на приемы,
              ведения учета клиентов, бухгалтерии и с
              обширным функционалом. Это незаменимо, и я
              определенно рекомендую его."`,
    },
    featureOffApp: {
      head: "Функции приложения",
      title1: "Самые используемые функции приложения для записи в салон",
      title2: "Страница записи в салон",
      title3: "Управление клиентами",
      title4: "Управление бухгалтерией",
      title5: "Управление записями",
      title6: "Мобильное приложение",
      title7: "Расширенная отчетность",
      title8: "Сообщения в WhatsApp",
      title9: "Электронный договор с подписью",
      title10: "Система учета заказов",
      title11: "Отчеты по персоналу",
      title12: "SMS-уведомления",
      title13: "Управление запасами",
    },
    statistics: {
      title1: "Лучший",
      title2: "Софт",
      title3: "Мгновенная Живая Поддержка",
      title4:
        "Наша команда всегда готова помочь вам в любой момент! Вы можете связаться с нами 24/7 через наши контактные данные.",
      title5: "Легкость Использования",
      title6:
        "Используйте веб-панель или мобильное приложение, чтобы контролировать всю информацию о вашем салоне в любое время.",
      title7: "100% Безопасность",
      title8:
        "Ваши данные в безопасности с нами! Мы надежно храним и защищаем всю информацию о вашем салоне и вас.",
      title9: "Электронная Подпись Контракта",
    },
  },
};

export default langRU;
