const langAR = {
  langText: "Arabic",

  /**
   * İlk açılan pop-up içeriği
   */

  definePopup: {
    title: "نحن نحدد الميزات الأكثر ملاءمة لك.",
    description:
      "هل ترغب في استخدام ميزة الفوترة والمحاسبة لدينا بالإضافة إلى تقويم المواعيد في عملك؟",
    buttons: {
      approve: "أجل",
      cancel: "لا",
    },
    succesMessage:
      "لقد تم حفظ تفضيلاتك بنجاح. الرجاد الدخول على الحساب من جديد.",

    appTitleOnBrowser: "🕐 قم بإدارة وقتك بفاعلية! - "
  },

  /**
   * Giriş sayfasının bulunduğu key.
   * https://app.salonrandevu.com/login
   */
  login: {
    title: "تسجيل الدخول",
    mailPhoneInputLabel: "البريد الإلكتروني / رقم الهاتف",
    passInputLabel: "كلمه السر",
    loginButtonTitle: "تسجيل الدخول",
    registerButtonTitle: "ليس لديك حساب؟ سجل الان!",
    forgotPassButtonTitle: "لقد نسيت كلمة المرور",
    rememberMe: "تذكرني",
    loginWithGoogle: "تسجيل الدخول باستخدام Google",
    loginwithApple: "تسجيل الدخول باستخدام Apple",

    license: {
      // Modal
      title: "انتهت فترة استخدام الترخيص الخاص بك!",
      description:
        "عزيزي {company} ، لقد انتهت فترة استخدام اللوحة الخاصة بك. يمكنك الاستمرار في استخدام اللوحة عن طريق شراء الحزمة التي تعتقد أنها ستكون مناسبة لعملك.",
      buttonTitle: "اتصل بنا لشراء الباقات - 0 (212) 706 0510",

      standart: {
        type: "تجارة صغيرة",
        title: "اساسي",
        items: [
          "مواعيد غير محدودة",
          "صفحة موعد لك",
          "لوحة إدارة المواعيد",
          "ملف الشركة",
          "ملف تعريف الموظف",
        ],
      },

      premium: {
        type: "المشروعات المتوسطة والكبيرة",
        title: "الممتازة",
        items: [
          "جميع ميزات الحزمة القياسية",
          "إدارة التذاكر",
          "الدخل - تتبع المصاريف",
          "نظام نقاط العملاء",
          "تحليل رضا العملاء",
        ],
      },
    },

    downloadApp:
      "قم بتنزيل التطبيق الآن عن طريق مسح رمز الاستجابة السريعة ضوئيًا.",

    loginErrorToast: "فشلت العملية! يرجى التحقق من معلومات تسجيل الدخول والمحاولة مرة أخرى.",
  },
  /**
   * login vaidate bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  validation: {
    enterField: "يرجى ملء الحقول المطلوبة.",
    wrongEmailOrPassword:
      "البريد الإلكتروني أو رقم الهاتف الذي أدخلته غير مرتبط بحساب.",
    wrongPassword: "كلمة المرور التي أدخلتها غير صحيحة.",
  },
  /**
   * Kayıt sayfasının bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  register: {
    title: "سجل",
    trialText: "7 أيام ، جميع الميزات مجانية تمامًا!",
    noPaymentText: "لا دفع ، لا التزام ، لا رسوم إلغاء.",
    privacyPolicyText: "لقد قرأت ووافقت على سياسة الخصوصية.",

    continueButtonTitle: "استمر",
    completeButtonTitle: "اكمل التسجيل",
    alreadyUserButtonTitle: "عضوا فعلا؟ تسجيل الدخول!",

    companyNameInputLabel: "الاسم التجاري",
    phoneInputLabel: "رقم الهاتف",
    campaingCodeInputLabel: "إذا كان لديك رمز حملة ، أدخله",
    staffNameInputLabel: "الاسم المعتمد",
    staffSurnameInputLabel: "اللقب المعتمد",
    staffMailInputLabel: "عنوان البريد الالكتروني",
    passwordInputLabel: "كلمه السر",
    verificationCode: "تم إرسال رمز التحقق بنجاح إلى الرقم!",
    registerError:
      "تعذر إنشاء التسجيل! يرجى مراجعة معلوماتك والمحاولة مرة أخرى.",
    registerNavigate: "تم إنشاء تسجيلك بنجاح! يتم توجيهك إلى اللوحة.",
    registerErrorSecond:
      "تعذر إتمام العملية! يرجى التحقق من معلومات تسجيل الدخول والمحاولة مرة أخرى.",
    registerAlreadyExist:
      "يوجد بالفعل تسجيل لصالة رياضية باستخدام هذا البريد الإلكتروني!",
    fieldCompany: "اسم الشركة",
    fieldNumber: "رقم الهاتف",
    fieldStaffName: "اسم الشخص المسؤول",
    fieldStaffSurname: "لقب الشخص المسؤول",
    fieldStaffEmail: "عنوان البريد الإلكتروني",
    fieldStaffPassword: "كلمة المرور",
    fieldVerificationCode: "رمز التحقق",
    securityWarning: "قبول اتفاقية الخصوصية إلزامي!",
    approveCode: "رمز الموافقة",
    smsSubmit: "التحقق عبر SMS",
    finishRegister: "إكمال التسجيل",
  },

  /**
   * Şifremi unuttum sayfasının bulunduğu key.
   * https://app.salonrandevu.com/forgot-pass
   */
  forgotPass: {
    title: "نسيت كلمة المرور وإعادة تعيين",

    stepText: "اسمي",
    firstStepText:
      "في هذه الخطوة ، يجب إدخال رقم هاتفك المرتبط بملف تعريف الموظف الخاص بك في الحقل أدناه. يمكنك إنشاء كلمة مرورك الجديدة عن طريق إدخال رمز التحقق في رسالة SMS التي سنرسلها إلى هاتفك في الخطوة التالية.",
    secondStepText:
      "لقد أرسلنا رمز التحقق في صورة رسالة نصية قصيرة إلى رقم الهاتف الذي أدخلته. يمكنك تحديث كلمة المرور الخاصة بك عن طريق إدخال كلمة المرور الجديدة ورمز التحقق المرسل إليك في الحقول أدناه.",

    continueButtonTitle: "استمر",
    backToHomeButtonTitle: "العودة إلى الصفحة الرئيسية",
    updatePassButtonTitle: "تطوير كلمة السر",

    phoneInputLabel: "رقم الهاتف",
    verificationCodeInputLabel: "شيفرة التأكيد",
    newPassInputLabel: "كلمة سرك الجديدة",

    verificationSuccessfullySentToast: "تم إرسال رمز التحقق بنجاح.",
    verificationErrorToast: "فشل في إرسال رمز التحقق.",
    validPhoneErrorToast: "الرجاء إدخال رقم هاتفك الكامل.",
    passwordSuccessfullyChangedToast: "كلمة السر الخاصة بك تم تغييرها بنجاح.",
    warningStatusToast: "حالة غير صحيحة ، لا يمكن تغيير كلمة المرور الخاصة بك.",
  },

  /**
   * Panel içerisindeki sol menü ve alt elemanların bulunduğu key.
   */
  menuItems: {
    dashboard: { title: "ملخص" },
    profile: { title: "إدارة الملف الشخصي" },
    wallet: { title: "معاملات الرصيد" },
    notify: { title: "إشعار الدفع" },
    todos: { title: "عمل قائمة" },

    appointment: {
      title: "إدارة المواعيد",
      children: {
        index: "تقويم المواعيد",
        filter: "قائمة المواعيد",
        online: "طلبات المواعيد عبر الإنترنت",
        repeat: "المواعيد المتكررة",
        add: "+ إنشاء موعد جديد",
      },
    },
    receipt: {
      title: "إدارة التذاكر",
      children: {
        index: "قائمة التذاكر",
        add: "+ إنشاء تذكرة جديدة",
        recordAdd: "إنشاء فواتير للعملاء المسجلين",
        newAdd: "إنشاء فواتير للعملاء الجدد",
      },
    },
    packages: {
      title: "إدارة الحزم",
      children: {
        sales: "قائمة مبيعات الحزمة",
        salesAdd: "+ إنشاء بيع حزمة جديدة",
        list: "قائمة التعبئة",
        add: "+ إنشاء الحزمة",
        unbookedPackages: "الحزم غير المحجوزة",
      },
    },
    products: {
      title: "إدارة المخزون",
      children: {
        index: "قائمة المنتجات",
        add: "+ إنشاء منتج جديد",
        addWithoutPlus: "إنشاء منتج جديد",
      },
    },
    customers: {
      title: "معاملات العملاء",
      children: {
        active: "قائمة العملاء",
        passive: "العملاء السلبيون",
        risky: "العملاء المفقودون",
        rating: "تعليقات العملاء",
        add: "+ إنشاء عميل جديد",
        addBulk: "+ إنشاء عملاء مجمعين باستخدام Excel",
      },
    },
    services: {
      title: "إدارة الخدمات",
      children: {
        index: "قائمة الخدمات",
        add: "+ إنشاء خدمة جديدة",
        requests: "طلبات اسم الخدمة",
        bounty: "إنشاء مكافأة مخصصة",
      },
    },
    employees: {
      title: "إدارة شؤون الموظفين",
      children: {
        index: "قائمة الموظفين",
        add: "+ إنشاء موظف جديد",
      },
    },
    sms: {
      title: "إدارة الرسائل القصيرة",
      children: {
        index: "إدارة الرسائل القصيرة",
        sendSelective: "إرسال رسائل نصية مجمعة",
        sendFilter: "إرسال الرسائل القصيرة المفلترة",
        sendGroup: "إرسال الرسائل القصيرة إلى المجموعات",
        settings: "إعدادات الرسائل القصيرة",
        blacklist: "القائمة السوداء للرسائل النصية القصيرة",
        template: "إعدادات القالب",
        wpSettings: "إعدادات صث",
      },
    },
    accounting: {
      title: "مدير الحسابات",
      children: {
        income: "معاملات الدخل",
        debt: "معاملات الديون",
        bounty: "المعاملات المميزة",
        expense: "معاملات المصاريف",
        expenseTypes: "بنود المصاريف",
        credit: "الإئتمان",
        debit: "الخصم",
      },
    },
    reports: {
      title: "تقارير متقدمة",
      children: {
        company: "تقارير الأعمال",
        service: "تقارير الخدمة",
        stock: "تقارير المنتج",
        staff: "تقارير الموظفين",
        customer: "تقارير العملاء",
        appointment: "تقارير المواعيد",
      },
    },
    platform: {
      title: "إدارة المنصة",
      children: {
        photo: "عرض الصورة",
        settings: "إعدادات النظام الأساسي",
        category_settings: "إعدادات الفئة",
      },
    },
    settings: {
      title: "اعدادات النظام",
      children: {
        index: "إعدادات الأعمال",
        hours: "ساعات العمل",
        gallery: "معرض الأعمال",
        service_gallery: "معرض الخدمة",
        logs: " سجلات المعاملات",
        branchAdd: "إنشاء فرع جديد",
        giveAReferans: "كن مرجعا",
      },
    },
    gallery: {
      title: "إدارة المعرض",
      children: {
        platform_photo: "عرض الصورة",
        enterprise_gallery: "معرض الأعمال",
        service_gallery: "معرض الخدمة",
      },
    },
    support: {
      title: "دعم مباشر",
    },
  },

  /**
   * Panel içerisindeki üst menü ve açılan popuplarının bulunduğu key.
   */
  scaffold: {
    translateViewHeading: "لغة النظام",
    logsViewHeading: "سجلات النظام",
    notificationViewHeading: "إشعارات",
    quickActionsViewHeading: "معاملات سريعة",

    logsViewAllButtonTitle: "اظهار الكل",

    notificationFound: "لديك إخطارات جديدة!",
    notFoundNotification: "ليس لديك أي إخطارات حتى الآن!",

    QAItems: {
      createCustomer: "إنشاء عميل جديد",
      createAppointment: "إنشاء موعد جديد",
      createReceipt: "إنشاء تذكرة جديدة",
      createPackage: "إنشاء حزمة جديدة",
      createPackageSale: "بيع حزمة جديدة",
      createService: "إنشاء خدمة جديدة",
      createEmployee: "إنشاء موظف جديد",
      showIncomes: "عرض الإيرادات",
      showExpenses: "عرض المصاريف",
      showDebts: "عرض الديون",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/branches
   */
  branches: {
    heroHeadingText: "صالون تعيين إدارة متعددة الفروع",
    greetingText: "أهلا وسهلا",
    greetingBottomText: "يتم سرد الفروع الخاصة بك تحت إدارتك في الحقل أدناه.",
    greetingBottomText2:
      "يمكنك تحديث معلومات تسجيل الدخول لأي فرع أو تسجيل الدخول إلى الفرع ذي الصلة باستخدام الأزرار الموجودة على الجانب الأيمن من الجدول.",

    logoutButtonTitle: "خروج",
    loginBranchButtonTitle: "دخول الفرع",
    editStaffButtonTitle: "تعديل المعلومات",
    completeEditingButtonTitle: "تحرير كامل",
    discardEditingButtonTitle: "يستسلم",
    createBranchButtonTitle: "انشاء فرع",
    licenseExpiredToast: "ترخيص مستخدم منتهي الصلاحية",

    tabBranchListTitle: "قائمة الفروع",
    tabBranchReportsTitle: "تقارير الفرع",
    tabBranchAddTitle: "+ إنشاء فرع جديد",

    headings: {
      listTable: {
        id: "كود العمل",
        companyName: "اسم العمل / الفرع",
        staffFullName: "الاسم المرخص له",
        staffPhoneNumber: "هاتف معتمد",
        staffMail: "البريد الإلكتروني المعتمد",
        licenseEndDate: "نهاية الترخيص",
        refresh: "ينعش",
      },
      reportsTable: {
        companyName: "اسم العمل / الفرع",
        staffCount: "عدد الموظفين",
        appointmentCount: "عدد المواعيد",
        receiptCount: "عدد التذاكر",
        serviceIncome: "دخل الخدمة",
        saleIncome: "إيرادات مبيعات المنتج",
        staffBounty: "قسط مدفوعة",
        totalIncome: "إجمالي الإيرادات",
        totalExpense: "المصاريف الكلية",
        totalCount: "صافي الدخل",
      },
    },

    createBranch: {
      companyNameInputLabel: "الاسم التجاري",
      staffNameInputLabel: "الاسم المعتمد",
      staffSurnameInputLabel: "اللقب المعتمد",
      commonMailInputLabel: "عنوان البريد الالكتروني",
      commonPhoneInputLabel: "رقم الهاتف",
      passInputLabel: "كلمه السر",
    },

    licenseExpiredLoginToast:
      "انتهت فترة ترخيص الحساب الذي تريد تسجيل الدخول إليه.",
    licenseExpiredEditToast: "انتهت فترة ترخيص الحساب الذي تريد تحريره.",
    staffUpdateToast: "تم تحديث العامل بنجاح.",
    newBranchSuccessToast: "تم إنشاء الفرع الجديد بنجاح.",
    staffUpdateSuccessToast: "تم تحديث معلومات الموظف بنجاح!",
    updateStaffTitle: " تعديل الموظف",

    inputLabels: {
      name: "اسم الموظف",
      surname: "اسم العائلة للموظف",
      mail: "بريد الموظف الإلكتروني",
      phone: "هاتف الموظف",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/give-reference
   */
  referance: {
    createReferanceButtonTitle: "كن مرجعا",
    createReferance: {
      companyNameInputLabel: "الاسم التجاري",
      staffNameInputLabel: "الاسم واللقب المعتمد",
      commonMailInputLabel: "عنوان البريد الالكتروني",
      commonPhoneInputLabel: "رقم الهاتف",
    },
  },
  platformSettings: {
    approve: "تحديث الإعدادات",
    appointmentApprovelCode: {
      title: "رمز التحقق من الموعد",
      detail:
        "هذا هو الإعداد لرمز التحقق للمواعيد المستلمة من المنصة. عند تمكين هذا الإعداد، سيتم إرسال رمز التحقق إلى العميل عند استلام موعد من المنصة.",
    },
    appointmentApprovelCodeType: {
      title: "نوع تسليم رمز التحقق من الموعد",
      detail:
        "هذا الإعداد هو لتسليم رموز التحقق للمواعيد المستلمة من المنصة. عند تمكين هذا الإعداد، فإنه يشير إلى نوع التسليم الذي سيتم استخدامه لإرسال رمز التحقق إلى العميل عند استلام موعد من المنصة.",
    },
    appointmentConfirmationDeadline: {
      title: "موعد التحقق من الموعد",
      detail:
        "هذا الإعداد يشير إلى أقصى عدد من الساعات التي يمكن أن يتم تأكيد الموعد من خلال الرابط.",
      startInputLabel: "موعد التحقق من الموعد(ساعات)",
    },
    confirmationDeadlineSelect: {
      noLimit: "لا يوجد حد زمني",
      hour: "ساعة",
    },
  },

  /**
   * Genel Bakış sayfasının bulunduğu key.
   * https://app.salonrandevu.com/dashboard
   */
  dashboard: {
    accountInstallCard: {
      heading: "يمكنك متابعة إعداد حسابك من خلال الخطوات أدناه!",
      description:
        "يمكنك الذهاب إلى صفحة الصفقة من خلال النقر على الخطوة ذات الصلة.",

      setShift: "حدد ساعات العمل الخاصة بك",
      createService: "بناء خدماتك",
      setServicePicked: "اختر الخدمات التي يقدمها الموظفون",
      createCustomer: "كوِّن عملاءك",
    },

    tutorialModal: {
      startHeading: "ابدأ في إعداد الحساب",
      startDescription:
        "أكمل إعداد حسابك من خلال استكمال متطلبات النظام في ثلاث خطوات أساسية فقط.",

      startTutorialButton: "ابدأ التثبيت",
      dontShowAgainButton: "لا تظهر مرة أخرى",
    },

    descriptionTutorial: {
      prevButtonTitle: "سابق",
      nextButtonTitle: "التالي",
      finishButtonTitle: "انهها",
    },

    businessURLCard: {
      heading: "إدارة مواعيدك بسهولة عن طريق مشاركة عنوان عملك مع عملائك!",
      inputLabel: "عنوان URL لموعد العمل",
      openBrowserButton: "افتح في المتصفح",
      copyLinkButton: "انسخ الرابط",
      copySuccessToast: "تم نسخ الرابط بنجاح!",
    },

    usageCard: {
      heading: "يمكنك بسهولة تتبع استخدامك المتبقي أدناه.",

      licenseDetail: "تفاصيل الترخيص",
      packageText: "باكيت",
      licenseRemaining: "ايام متبقية",

      smsDetail: "رصيد الرسائل القصيرة المتبقي",

      avaible_appointment_credit: "رصيد التعيين المتبقي",
    },

    stats: {
      appointment: {
        heading: "إحصائيات التعيين",
        createdAppointments: "المواعيد التي تم إنشاؤها",
        successAppointments: "المواعيد الختامية",
        closedAppointments: "المواعيد غير المنتهية",
      },

      customer: {
        heading: "إحصائيات العملاء",
        newCustomers: "العملاء الجدد",
        oldCustomers: "العملاء القدامى القادمون",
        appointmentCustomers: "تعيين العملاء",
      },

      accounting: {
        heading: "احصائيات الحالة",
        income: "إجمالي الدخل ",
        expense: "المصروفات الكلية ",
        debt: "إجمالي الديون ",

        licenseUpgradeText: "قم بترقية حسابك!",
      },

      meta: {
        total: "إجمالي المواعيد",
        arrived: "أتى",
        notArrived: "لم يأت",
        waiting: "انتظار",
      },
    },

    cardTitle: {
      receipt_income_card_title: "احصائيات الحالة",
      appointment_calender_card_title: "تقويم المواعيد",
      appointments_card_title: "تفاصيل الموعد",
    },

    cardDescription: {
      favorites_description:
        "يمكنك إنشاء الاختصارات الخاصة بك عن طريق تمييز علامات التبويب التي تستخدمها في أغلب الأحيان بنجمة. يمكنك أيضًا تغيير أماكن البطاقات بالسحب والإفلات.",
      receipt_income_card_description:
        "يمكنك متابعة إحصائياتك النقدية اليومية أو لمدة 7 أيام أو 30 يومًا على الرسم البياني للدخل.",
      appointment_calender_card_description:
        "انقر فوق اليوم الذي تريد عرض تفاصيل الموعد فيه.",
      appointment_statistic_card_description:
        "يمكنك الإبلاغ عن عدد المواعيد التي تم إنشاؤها من خلال النظام الأساسي ورابط الأعمال واللوحة من خلال البيانات الإحصائية على فترات يومية أو 7 أيام أو 30 يومًا.",
      appointments_card_description:
        "يمكنك تتبع عدد المواعيد التي يقوم بها عملك يوميًا ، 7 أيام أو 30 يومًا وما إذا كان عملاؤك يأتون أم لا.",
      online_appointment_request_card_description:
        "يمكنك الموافقة على الموعد أو رفضه باتباع طلبات المواعيد عبر الإنترنت من عملائك عبر رابط عملك.",
    },
    PlatformLink: {
      btnText: "صفحة حجز الموعد",
      title: "صفحة حجز الموعد",
      description:
        "من هذا المجال، يمكنك الوصول إلى الرابط الذي يحتاجه عملاؤك لإنشاء طلب حجز موعد لعملك. من خلال هذا الرابط، يمكن لعملاؤك إنشاء طلب حجز موعد عن طريق إدخال معلومات حول الموظف، الخدمة، اليوم والوقت.",
      copyLink: "انسخ الرابط",
      goToLink: "الذهاب إلى الصفحة",
      linkCopied: "تم نسخ الرابط.",
      Qr: "انظر رمز الاستجابة السريعة",
    },

    favoritePage: {
      sectionHeading: "المفضلة",
    },

    report: {
      company: {
        typeCASH: "نقدي",
        typeCARD: "بطاقة",
        typeEFT: "التحويل البنكي / التحويل الإلكتروني",
        totalIncome: "إجمالي الإيرادات",
        noDataText:
          "لا يمكن عرض الرسم البياني نظرًا لعدم توفر الدخل النقدي وبطاقة الائتمان / الخصم والحوالات المالية / التحويل الإلكتروني.",
      },
    },

    appointmentAnalys: {
      platform: "SalonRandevu.com",
      company: "موقع الأعمال التجارية",
      panel: "حسب الأعمال",
      packageWarningText:
        "يمكن فقط لحسابات الحزمة المميزة عرض إحصائيات المواعيد.",
      upgradePackage: "حزمة الترقية",
      noDataText: "لا توجد طلبات إيصال مفتوحة معلقة.",
    },

    waitingAppointments: {
      sectionHeading: "طلبات المواعيد عبر الإنترنت",
      headings: {
        appointmentStartDate: "وقت الموعد",
        customerFullName: "العميل محل الاهتمام",
        staffFullName: "الموظف المهتم",
        serviceName: "الإجراءات الواجب اتخاذها",
      },
      noDataText: "لا توجد لديك طلبات موعد معلقة.",

      buttons: {
        openReceiptTitle: "تذكرة مفتوحة",
      },
    },

    appointmentList: {
      title: "المواعيد اليومية",
    },

    onlineAppointments: {
      headings: {
        appointmentStartDate: "التاريخ - الوقت",
        customerFullName: "العميل ذو الصلة",
        customerPhone: "هاتف",
        staffFullName: "عامل",
        serviceName: "خدمة",
        note: "ملاحظة",
        customerType: "نوع العميل",
        statistics: "إحصائيات",
      },

      approveButtonTitle: "يوافق",
      rejectButtonTitle: "رفض",

      rejectConfirm: {
        title: "هل تؤكد إلغاء الموعد؟",
        confirmButtonTitle: "أجل",
        discardButtonTitle: "لا",

        alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
        alertText:
          "إذا رفضت هذا الطلب ، فلن تتمكن بعد ذلك من الوصول إلى طلب الموعد هذا.",
      },

      approveToast: "تمت الموافقة على طلب الموعد بنجاح!",
      rejectToast: "تم رفض طلب الموعد بنجاح!",
    },

    openAppointments: {
      headings: {
        date: "التاريخ - الوقت",
        customerFullName: "العميل ذو الصلة",
        products: "منتجات",
        serviceName: "خدمة",
        totalPayment: "المبلغ الإجمالي",
      },
      title: "فتح التذاكر",

      approveButtonTitle: "يوافق",
      rejectButtonTitle: "رفض",

      rejectConfirm: {
        title: "هل تؤكد إلغاء الموعد؟",
        confirmButtonTitle: "أجل",
        discardButtonTitle: "لا",

        alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
        alertText:
          "إذا رفضت هذا الطلب ، فلن تتمكن بعد ذلك من الوصول إلى طلب الموعد هذا.",
      },

      approveToast: "تمت الموافقة على طلب الموعد بنجاح!",
      rejectToast: "تم رفض طلب الموعد بنجاح!",
    },
    creditors: {
      headings: {
        customerFullName: "العميل ذو الصلة",
        type: "طب",
        paymentDate: "تاريخ الدفع المقرر",
        totalPayment: "المبلغ الإجمالي",
      },

      noDataText: "لا يوجد دائنون معلقون.",
      title: "الدائنين",

      approveButtonTitle: "يوافق",
      rejectButtonTitle: "رفض",

      rejectConfirm: {
        title: "هل تؤكد إلغاء الموعد؟",
        confirmButtonTitle: "أجل",
        discardButtonTitle: "لا",

        alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
        alertText:
          "إذا رفضت هذا الطلب ، فلن تتمكن بعد ذلك من الوصول إلى طلب الموعد هذا.",
      },

      approveToast: "تمت الموافقة على طلب الموعد بنجاح!",
      rejectToast: "تم رفض طلب الموعد بنجاح!",
    },
    birthDay: {
      headings: {
        customerFullName: "العميل ذو الصلة",
        customerPhone: "رقم الهاتف",
        birthDate: "تاريخ الميلاد",
      },
      noDataText: "لا توجد أعياد ميلاد قادمة.",
      title: "أعياد الميلاد القادمة",
    },
    openReceiptOnSuccessToast: "تم إنشاء تذكرة الموعد بنجاح!",
    packageTitle: "الباقة الذهبية",
    packageTitleSpan: "الأسعار المخفضة !",
    packageBuyButton: "شراء الآن",
    packageMoreInfo: "تعلم أكثر",
    packageCardDetails: [
      {
        title: "الباقة الذهبية",
        info: "للشركات التي تريد إدارة المواعيد",
        listItems: ["موعد غير محدود لـ 5 موظفين", "إدارة الفروع المتعددة", "ممثل خدمة عملاء خاص", "معرض الأعمال", "إرسال الرسائل القصيرة", "هدية خاصة '250 رسالة قصيرة' للأعضاء الجدد"],
        price: "₺599/شهر",
        oldPrice: "₺839",
        discount: "₺240 إيراد",
      },
      {
        title: "الباقة البلاتينية",
        info: "للشركات التي تريد إدارة المواعيد والإيصالات والحزم والمخزون والمكافآت",
        listItems: ["موعد غير محدود لـ 7 موظفين", "إدارة الإيصالات", "إدارة الحزم", "إدارة المخزون", "إدارة المكافآت", "هدية خاصة '500 رسالة قصيرة' للأعضاء الجدد"],
        price: "₺849/شهر",
        oldPrice: "₺1199",
        discount: "₺350 إيراد",
      },
      {
        title: "الباقة الألماسية",
        info: "للشركات التي تريد إدارة العقود والمواعيد والمحاسبة المسبقة والمكافآت",
        listItems: ["موعد غير محدود لـ 10 موظفين", "إضافة التوقيع الإلكتروني", "إدارة العقود", "إدارة الإيصالات", "إدارة الحزم", "هدية خاصة '1000 رسالة قصيرة' للأعضاء الجدد"],
        price: "₺1249/شهر",
        oldPrice: "₺1749",
        discount: "₺500 إيراد",
      }
    ],

    packageTitleRenewal: "تجديد الباقة",
    packageBuyButtonRenewal: "شراء الآن",
    packageMoreInfoRenewal: "تعلم أكثر",
    packageCardDetailsRenewal: [
      {
        title: "الباقة الذهبية",
        info: "للشركات التي تريد إدارة المواعيد",
        listItems: ["5 موظفين مواعيد غير محدودة", "إدارة الفروع المتعددة", "ممثل خدمة عملاء خاص", "معرض الأعمال", "إرسال الرسائل القصيرة", "هدية خاصة '250 رسالة قصيرة' للأعضاء الجدد"],
        price: "₺839 / شهر",
      },
      {
        title: "الباقة البلاتينية",
        info: "للشركات التي تريد إدارة المواعيد والفواتير والحزم والمخزون والمكافآت",
        listItems: ["7 موظفين مواعيد غير محدودة", "إدارة الفواتير", "إدارة الحزم", "إدارة المخزون", "إدارة المكافآت", "هدية خاصة '500 رسالة قصيرة' للأعضاء الجدد"],
        price: "₺1199 / شهر",
      },
      {
        title: "الباقة الألماسية",
        info: "للشركات التي تريد إدارة العقود والمواعيد والمحاسبة المسبقة والمكافآت",
        listItems: ["10 موظفين مواعيد غير محدودة", "إضافة التوقيع الإلكتروني", "إدارة العقود", "إدارة الفواتير", "إدارة الحزم", "هدية خاصة '1000 رسالة قصيرة' للأعضاء الجدد"],
        price: "₺1749 / شهر",
      }
    ],
  },

  /**
   * Randevu tablosun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments
   */
  appointments: {
    dayInputLabel: "تاريخ التعيينات للعرض",
    beforeDayButtonTitle: "اليوم السابق",
    afterDayButtonTitle: "اليوم التالي",
    today: "العودة إلى اليوم",
    allStaff: "كل الموظفين",
    dayWeekSelectLabel: "يوم - أسبوع - شهري",
    customerStateSelectLabel: "تصفية بالحالة",

    repeatButtons: {
      showAppointment: 'عرض المواعيد',
      goAppointment: 'انتقل إلى بيل',
      cancelAppointment: 'إلغاء الموعد',
      appointmentDate: 'تاريخ الموعد',
      appointmentStatus: 'حالة الموعد',
      startEnd: 'البداية والنهاية'
    },

    // Salon Randevu
    definitionGreenText: "التذكرة تمثل المواعيد المفتوحة.",
    definitionRedText:
      "تمثل المواعيد المواعيد التي انقضى تاريخها ولم يتم فتح تذكرتها.",
    definitionYellowText:
      "لم يمر تاريخ - وقت الموعد وتمثل التذكرة المواعيد التي تنتظر فتحها.",
    definitionLightText: "تمثل المواعيد الواردة والفواتير المفتوحة.",
    definitionPurpleText: "تم الوصول وجاري العمل على المعاملة.",
    definitionLinkText: "تمثل المواعيد التي تم حجزها عبر رابط عبر الإنترنت.",
    // En Randevu
    definitionGreenText_en: 'يمثل المواعيد المميزة بعلامة "وصلت".',
    definitionRedText_en: 'يمثل المواعيد المميزة بعلامة "لم تصل".',
    definitionYellowText_en:
      'يمثل المواعيد التي لم تتم معالجتها بعد وهي في حالة "معلقة".',

    create: {
      title: "إنشاء موعد في الوقت المحدد",
      completeButtonTitle: "إكمال",
      discardButtonTitle: "يستسلم",
      notInterval: "الفترة غير محددة",
      minutes: "دقائق",
      printButtonTitle: "طباعة",
      contractButtonTitle: "عقد",

      selectedTimeInputLabel: "يوم ووقت الموعد",
      selectedStaffInputLabel: "اختيار الموظف",
      selectedServiceInputLabel: "الخدمات التي يقدمها الموظف",
      selectedPackageInputLabel: "حزم العرض من قبل الموظف",
      selectedCustomerInputLabel: "يتم تعيين العميل",
      selectedCustomerInputPlaceholder:
        "أدخل اسم العميل أو رقم هاتفه في هذا الحقل.",
      createNewCustomerButtonTitle: "إنشاء عميل جديد",
      createNewWalkinCustomerButtonTitle: "إنشاء عميل غير مسجل",

      closeAllDayTitle: "أغلق طوال اليوم",

      selecting_service: "اختيار الخدمة",
      selecting_package: "اختيار الحزمة",
      select_package_alert:
        "الحزمة المحددة تحتوي على خدمات تم حذفها. يرجى تغيير الحزمة المحددة!",

      // sendSmsInputLabel: "Bilgilendirme SMS Gönderimi",
      sendSmsInputLabel: "إرسال إشعار SMS",
      sendSmsTrue: "ارسلها",
      sendSmsFalse: "لا ترسل",
      description: "تفسير",
      defaultValue: "اختر الزبون",

      groupAppointment: "إنشاء موعد بالجملة",
      clockOff: "إزالة الساعة",
      addEmployeeButtonTitle: "اضافة موظف + خدمة",

      noteInputLabel: "ملاحظة موعد (اختياري)",

      onCreateErrorToast: "يوجد موعد قائم في الوقت الذي تحاول فيه إنشاء موعد!",
      selectStaffWarnToast: "يرجى اختيار موظف أولاً لتحديد موعد!",

      onWarningToast: "أدخل حقول اختيار العميل واختيار الخدمة بالكامل!",

      noDataText:
        "لإنشاء موعد مُجمَّع ، يجب عليك تحديد موظفين وخدمات اثنين على الأقل.",
    },

    detail: {
      title: "تفاصيل الموعد",
      deleteButtonTitle: "إلغاء التعيين",
      closeButtonTitle: "أغلق",
      allStaffSelectedTitle: "الكل",

      deleteButton: "حذف",
      editButton: "تعديل",
      clockOffDetail: "تفاصيل اغلاق الساعة",
      clockOffDateInputLabel: "تاريخ / وقت الإغلاق",
      clockOffStaffInputLabel: "الموظفون ذوو الصلة",
      clockOffNoteInputLabel: "تفسير",

      appointmentTime: "وقت الموعد (دقيقة)",
      appointmentTimeExtension: "تمديد وقت الموعد",
      appointmentalAlertMessage:
        "تم تمديد وقت موعدك. يمكنك عرض وقت الموعد الحالي من تقويم المواعيد.",
      appointmentalWarningMessage: "الرجاء إدخال وقت الموعد.",

      dateInputLabel: "تاريخ / وقت الموعد",
      serviceInputLabel: "الخدمة التطبيقية",
      staffInputLabel: "أفراد للعناية بهم",
      customerInputLabel: "اسم العميل - اللقب",
      customerPhoneInputLabel: "رقم هاتف العميل",
      noteInputLabel: "مذكرة موعد",
      noteInputPlaceholder: "أدخل ملاحظة الموعد",
      customerNote: "ملاحظة الزبون",
      appointmentStatus: {
        title: "ملاحظة الزبون",
        attended: "حضر",
        notAttended: "لم يحضر",
        accepted: "موافق عليه",
        inProcess: "قيد المعالجة",
        new: "جديد",

        changeStatusSuccessToast: "تم تغيير حالة الموعد بنجاح.",
      },

      appointmentStatusOnline: "تم الحجز عبر الإنترنت",
      appointmentStatusTable: "نوع الموعد",
      appointmentStatusOnlineList: "موعد عبر الإنترنت",
      appointmentStatusPlatformList: "موعد عبر المنصة",



      statusAttended: "حضر",
      statusNotAttended: "لم يحضر",
      statusAccepted: "موافق عليه",
      statusInProcess: "قيد المعالجة",
      statusNew: "جديد",
      repeatButtonTitle: 'موعد متكرر',
      repeatFrequency: 'تردد التكرار',
      repeatCount: 'عدد التكرارات',
      logButtonTitle: "الذهاب إلى سجل العمليات الموعدة",
      sendSms: "إرسال SMS",
      send: "إرسال",
      cancel: "إلغاء",
    },

    deleteConfirm: {
      title: "تأكيد إلغاء الموعد",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "إذا تم تشغيل إعدادات إرسال الرسائل القصيرة وكان لديك رصيد SMS ، فسيتم إرسال رسالة SMS إلى العميل بخصوص إلغاء الموعد.",
    },

    pastCreateErrorToast: "لقد حاولت إنشاء موعد في تاريخ ووقت سابق.",
    onMouseOverCardToast:
      "يمكنك الضغط على البطاقة التي تريدها لمعرفة تفاصيل الموعد.",
    insertToast: "تم إنشاء الموعد بنجاح.",
    deleteToast: "تم حذف الموعد بنجاح.",

    weeklyAppointmentsStaffErrorToast: "الرجاء تحديد موظف للمواعيد الأسبوعية.",
    weeklyAppointmentsDisplayErrorToast:
      "للحصول على موعد ، اعرض المواعيد اليومية.",

    checkButton: "أفهم",
    firstLoginAppointmentsTitle: "لكي يعلم",
    firstLoginAppointmentsDescription:
      "تم إنشاء نموذج لمعلومات الموظفين وبطاقة المواعيد من أجلك. يمكنك تحديث معلومات الموظفين والمواعيد الخاصة بك من خلال النقر على علامات التبويب المتاحة.",

    updateAppointment: {
      title: "هل تريد تغيير عامل الموعد و / أو الوقت؟",
      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "إذا قمت بتحديث الموعد الحالي ، ستتغير معلومات الموظف و / أو الساعة.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",

      service: "اسم الخدمة",
      staff: "عامل",
      time: "ساعة",
      customer: "عميل",

      updateAppointmentSuccessToast: "تم تحديث الموعد بنجاح.",
    },

    orderStaffsButtonTitle: "فرز الموظفين",

    rightButtonTooltip: "اسحب الجدول لليمين",
    leftButtonTooltip: "اسحب الجدول إلى اليسار",

    createAppointmentButtonTitle: "قم بإنشاء موعد",
    appointmentsComp: {
      green: "الأوقات المعروضة باللون الأخضر هي الأوقات المتاحة لحجز المواعيد.",
      red: "الأوقات المعروضة باللون الأحمر هي الأوقات التي تم حجزها مسبقًا.",
      purple:
        "قد تحدث تداخلات مع المواعيد الحالية أو الأوقات المغلقة عند حجز الأوقات المعروضة باللون الأرجواني.",
      gray: "الأوقات المعروضة باللون الرمادي هي ساعات مغلقة على التفاصيل الخاصة بالموظف أو جدول المواعيد.",
    },
    comunicateWithWhatsApp: "التواصل عبر الواتس اب",
    tooltipExplanations: {
      selectStaff: "هنا يمكنك تحديد الموظفين الذين تريد رؤيتهم في التقويم",
      staffCount: "هنا يمكنك تعديل عدد الأشخاص الذين تريد ظهورهم على الصفحة",
      appointmentRequest: "طلبات الموعد عبر الإنترنت",
      closeToGetAppointment: "حجز المواعيد مغلق",
      appointmentList: "قائمة المواعيد الخاصة بك",
      appointmentTypes:
        "يمكنك تغيير تقويم المواعيد هنا لعرضه يوميًا أو أسبوعيًا أو شهريًا.",
    },
    staffSelect: {
      all: "الجميع",
    },
    staffViewCount: {
      person3: "عرض 3 أشخاص",
      person5: "عرض 5 أشخاص",
      person7: "عرض 7 أشخاص",
      person9: "عرض 9 أشخاص",
      all: "الجميع",
    },

    repetition: {
      dailyPeriod: "كل يوم",
      twoDayPeriod: "كل يومين",
      threeDayPeriod: "كل ثلاثة أيام",
      fourDayPeriod: "كل أربعة أيام",
      fiveDayPeriod: "كل خمسة أيام",
      weeklyPeriod: "مرة في الأسبوع",
      twoWeekPeriod: "كل أسبوعين",
      threeWeekPeriod: "كل ثلاثة أسابيع",
      fourWeekPeriod: "كل أربعة أسابيع",
      monthlyPeriod: "كل شهر",
      fortyFiveDayPeriod: "كل خمسة وأربعين يوماً",
      twoMonthPeriod: "كل شهرين",
      threeMonthPeriod: "كل ثلاثة أشهر",
      sixMonthPeriod: "كل ستة أشهر",
    },
    gotRepetition: "متكرر",
    cancelRepetition: "إلغاء",
    repetitionFrequencyTitle: "تكرار التردد",
    repetitionCountTitle: "عدد التكرارات",

    recurenctModalAccept: "نعم",
    recurenctModalRefuse: "لا",
    recurenctModalCancel: "إلغاء",
    recurenctModalWarn: "هذا حدث متكرر. هل ترغب في حذف الأحداث المتكررة الأخرى؟",

    servicesInReceiptWarn: "لفتح الفاتورة، يجب عليك تحديد فترة الجلسة للخدمات الموجودة في الحزمة.",
    serviceName: "اسم الخدمة",

    selectStaffWarn: "يرجى اختيار الموظف.",
    onlineAppointmentTitle: "موعد عبر الإنترنت",

    closeAppointmentClockTimeSuccess: "تم الإضافة بنجاح.",
    closeAppointmentClockTimeError: "حدث خطأ",

    youtubeTitle: "إنشاء موعد جديد",
    youtubeTitle2: "عرض سجل المواعيد",
    youtubeTitle3: "تقويم المواعيد اليومية والأسبوعية",
    updateAppointmentWarn: "لا يمكن أن يكون وقت الانتهاء قبل وقت البدء!",
    transferAppointmentWarn: "لا يمكن إجراء تحويل الساعات المغلقة.",
    allDayClosedText: "مغلق طوال اليوم",
    calendarTodayButtonTitle: "هذا الشهر",
    descriptionNoteTitle: "ملاحظة: ",
    saveButtonTitle: "احفظ",
    quitButtonWarn: "يرجى عدم نسيان حفظ التغييرات التي أجريتها!",
    routeToReceipt: "اذهب إلى الفاتورة",
    openReceipt: "افتح الفاتورة",
    permissionWarn: "يجب عليك شراء حزمة البريميوم لاستخدام هذه الميزة.",
    historyTitle: "سجل المواعيد",
    appliedPackageTitle: "الحزمة المطبقة",
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/filter
   */
  "appointments/filter": {
    alertBoldText: "يمكنك تصفية مواعيدك باستخدام الحقول أدناه.",
    alertText:
      "إذا قمت بإجراء تغييرات في الحقول ، فسيتم إجراء التصفية تلقائيًا.",

    startInputLabel: "تاريخ البدء",
    endInputLabel: "تاريخ الانتهاء",
    staffInputLabel: "اختيار الموظف",
    allStaffsText: "كل الموظفين",
    serviceInputLabel: "اسم الخدمة",
    customerInputLabel: "اسم العميل واللقب",

    tabMenuLeft: {
      customerBill: "معلومات العميل",
      customerAppointment: "تذاكر العميل",
      customerDetails: "مواعيد العميل",
      customerPhotos: "صور العملاء",
      updateCustomerDetailsTrue: "تم تحديث معلومات العميل بنجاح!",
      updateCustomerDetailsFalse: "فشل تحديث معلومات العميل!",
    },

    statusInputLabel: "اختيار حالة الموعد",
    statusInputItems: [
      "جميع المواعيد",
      "وصلت المواعيد",
      "المواعيد الفائتة",
      "المواعيد المعلقة",
      "المواعيد الملغاة",
    ],

    filterTitle: "فلترة المواعيد",

    canceled: "ألغيت",
    situations: "مواقف",

    headings: {
      appointmentStartDate: "تاريخ الموعد",
      customerFullName: "عميل",
      serviceName: "خدمة",
      appointmentNote: "مذكرة موعد",
      staffFullName: "الموظف المهتم",
      customerPhone: "هاتف العميل",
      createdAt: "تاريخ إنشاء الموعد",
    },
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/online
   */
  "appointments/online": {
    alertText:
      "في الحقل أدناه ، يتم سرد طلبات المواعيد عبر الإنترنت التي يمكنك التقدم لها ، إذا تمت الموافقة عليها. يمكن لمندوب الصالون فقط الموافقة على هذه المواعيد / رفضها.",
    headings: {
      appointmentStartDate: "التاريخ - الوقت",
      customerFullName: "العميل ذو الصلة",
      customerPhone: "هاتف العميل",
      staffFullName: "الموظف المهتم",
      serviceName: "خدمة",
      note: "مذكرة موعد العميل",
      customerType: "نوع العميل",
    },

    approveButtonTitle: "يوافق",
    rejectButtonTitle: "رفض",

    rejectConfirm: {
      title: "هل تؤكد إلغاء الموعد؟",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",

      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "إذا رفضت هذا الطلب ، فلن تتمكن من الوصول إلى طلب موعد مرة أخرى.",
    },

    conflictConfirm: {
      title: "هل تريد تأكيد طلب الموعد؟",
      alertBoldText: "يتعارض طلب الموعد مع موعد موجود. هل تريد تأكيده على أي حال؟",
    },

    approveToast: "تمت الموافقة على طلب الموعد بنجاح!",
    rejectToast: "تم رفض طلب الموعد بنجاح!",
    blackListWarning:
      "بعد إدراج الرقم في القائمة السوداء، لا يمكن إجراء طلبات مواعيد باستخدام هذا الرقم.",
    addToNumberBlackList:
      "هل ترغب في إضافة هذا الرقم إلى قائمة الأرقام السوداء؟",
  },

  /**
   * Randevu Oluştur sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/add
   */
  "appointments/add": {
    staffInputLabel: "اختيار الموظف",
    serviceInputLabel: "الخدمات التي يقدمها الموظف",
    customerInputLabel: "يتم تعيين العميل",
    customerInputPlaceholder: "أدخل اسم العميل في هذا المجال",
    //sendSmsInputLabel: "Bilgilendirme SMS Gönderimi",
    sendSmsInputLabel: "إرسال إشعار SMS",
    sendSmsTrue: "ارسلها",
    sendSmsFalse: "لا ترسل",
    noteInputLabel: "ملاحظة موعد (اختياري)",

    availableHoursButtonTitle: "إحضار المواعيد المناسبة",
    confirmButtonTitle: "تأكيد اختيار الموعد",
    confirmButtonHoursTitle: "تأكيد اختيار الساعة",
    createNewCustomerButtonTitle: "إنشاء عميل جديد",
    createNewWalkinCustomerButtonTitle: "إنشاء عميل جديد",

    insertToast: "تم إنشاء الموعد بنجاح.",
    onlyActiveSwitch: 'احصل على ساعات النشاط فقط'
  },

  /**
 * Adisyonların listeletildiği sayfaların bulunduğu key.
 * https://app.salonrandevu.com/receipts
 * https://app.salonrandevu.com/packages/sales
 */
  receipts: {
    filter: {
      title: "التاريخ والتصفية حسب العميل",
      startInputLabel: "تاريخ البدء",
      endInputLabel: "تاريخ الانتهاء",
      typeOfSales: "نوع المبيعات",

      allSales: "كل المبيعات",
      openTitle: "الجميع",
      open: "فتح المبيعات",
      close: "المبيعات المغلقة",
    },

    previous: "الصفحة السابقة",
    next: "الصفحة التالية",

    headings: {
      id: "رقم التذكرة",
      type: "نوع المبيعات",
      billType: "نوع التذكرة",
      createdAt: "موعد الافتتاح",
      customerFullName: "اسم الزبون",
      info: "محتوى التذكرة",
      allAmount: "المجموع",
      paid: "دفع",
      debtTotal: "بقية",
      processDate: "تاريخ الصفقة",
    },

    replacements: {
      undefinedCustomer: "زبون غير معروف",
      allSales: "كل المبيعات",
      packageSale: "باكيت",
      standartSale: "اساسي",
    },

    showButtonTitle: "رأي",

    detailButtons: {
      confirmButton: "يعتمد",
      closeButton: "يغلق",
    },

    deleteReceiptSuccessToast: "تم حذف الإيصال والمدفوعات بنجاح!",

    customerStatus: {
      attend: "حضر",
      notAttend: "لم يحضر",
      waiting: "في الانتظار"
    }
  },

  packages: {
    filter: {
      title: "تصفية الحزمة",
      packageInputLabel: "اسم الحزمة",
    },
    alert_receipt_installment:
      "هناك أقساط مستحقة في الإيصال بتواريخ استحقاق سابقة.",
  },

  /**
   * Adisyonların oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/receipts/add
   */
  "receipts/add": {
    searchCustomerInputLabel: "بحث العملاء",
    searchCustomerInputPlaceholder: "أدخل اسم العميل واللقب في هذا المجال",
    receiptNoteInputLabel: "مذكرة تذكرة (اختياري)",

    openReceiptBySelectedButtonTitle: "إنشاء تذكرة على العميل المختار",
    openReceiptByNewCustomerButtonTitle: "إنشاء تذكرة على عميل جديد",
    openReceiptByRandomCustomerButtonTitle: "إنشاء تذكرة على عميل غير مسجل",
    openReceiptWithCreatedNewCustomerButtonTitle: `إنشاء العميل والاستلام`,

    insertToast: "تم إنشاء التذكرة بنجاح!",
    infoToast: "يجب عليك أولاً تحديد العميل من مربع البحث!",
  },

  /**
   * Tekli adisyonun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/receipts/detail/:id
   */
  "receipt/detail/id": {
    single: {
      warnTitle: 'تحذير بشأن تحديث الفاتورة',
      warnDescription: 'الخدمة المختارة لا يتم تقديمها من قبل الموظفين المختارين. هل تريد الاستمرار؟',
      warnApprove: 'يقبل'
    },
    customerPoint: "نقاط العملاء المتراكمة",
    saveChangesButtonTitle: "حفظ التغييرات",

    applyDiscountButtonTitle: "تطبيق الخصم",
    addPackageSaleButtonTitle: "إضافة بيع حزمة",
    addServiceTransactionButtonTitle: "إضافة بيع الخدمة",
    addStockSaleButtonTitle: "إضافة بيع منتج جديد",
    addPaymentButtonTitle: "إضافة دفعة جديدة",

    installmentButtonTitle: "إضافة دفعة التقسيط",
    installmentCountInputLabel: "عدد الاقساط",
    installmentStaffInputLabel: 'حدد طاقم العمل',
    installmentProcess: "عملية التقسيط",

    deleteReceiptButtonTitle: "حذف التذكرة",
    alertText:
      "تم حفظ الرسالة ، ولكن يجب عليك أولاً حفظ التذكرة لإعادة توجيه الرسالة.",

    sms: {
      sendButtonTitle: "حفظ الرسائل القصيرة",
      discardButtonTitle: "يلغي",
      addSmsButtonTitle: "إرسال رسالة المجموعة",
      smsButtonTitle: "إرسال معلومات الديون / رسالة التحصيل",
      languageLabel: "اختيار اللغة للرسالة المثالية :",
    },

    creationdate: "تاريخ الإنشاء",

    point: "نقطة",
    packageInner: {
      title: "الحزمة تشمل",
      headings: {
        // Table Headings
        name: "اسم الخدمة",
        count: "إجمالي الجلسة",
        completed: "جلسة مكتملة",
        pending: "جلسة انتظار",
      },
    },

    serviceTransactions: {
      // Section
      print: "مطبعة",
      soldTitle: "بيع خدمة جديدة",
      title: "مبيعات الخدمة",
      dateInputLabel: "تاريخ الصفقة",
      nameInputLabel: "اسم المعاملة",
      transactionStatus: "حالة عملية",
      staffInputLabel: "الموظف المهتم",
      amountInputLabel: "قيمة التحويل ",
      totalAmount: "إجمالي مبلغ مبيعات الخدمة ",
      noDataText: "لم يتم إضافة بيع خدمة إلى التذكرة حتى الآن!",
      searchPlaceholder: "ابحث عن الخدمات...",
    },

    productTransactions: {
      productSaleTitle: "مبيعات المنتجات الجديدة",
      successAdd: "تمت إضافة المنتج بنجاح",
    },

    stockSales: {
      // Section
      title: "مبيعات المنتجات",
      nameInputLabel: "اسم المنتج",
      deletedName: "عنصر محذوف",
      staffInputLabel: "تاجر",
      amountInputLabel: "سعر الوحدة ",
      countInputLabel: "قطعة",
      totalAmount: "إجمالي مبلغ مبيعات المنتج ",
      noDataText: "لم تتم إضافة أي مبيعات منتج إلى التذكرة حتى الآن.",
      selectWithBarcode: "حدد المنتج مع الباركود",
      selectStock: "حدد المنتج",
      barcodeCheckAlert:
        "المحتوى المدفوع غير نشط. يرجى الاتصال بالممثل للتفعيل.",
    },

    barcodeAlert: {
      pleaseEnterACode: "الرجاء إدخال رمز المنتج",
    },

    payments: {
      // Section
      title: "المدفوعات",
      dateInputLabel: "يوم الدفع او الاستحقاق",
      typeInputLabel: "طريقة الدفع او السداد",
      amountInputLabel: "المبلغ ",
      pointInputLabel: "نقاط لاستخدامها",

      typeCASH: "نقدي",
      typeCARD: "بطاقة الائتمان / الخصم",
      typeEFT: "التحويل الإلكتروني / التحويل الإلكتروني",
      typePOINT: "نقاط العميل",

      totalAmount: "مجموع التذاكر ",
      totalPaidAmount: "المبلغ المدفوع",
      totalDebtAmount: "مبلغ الدين ",

      noDataText: "لم يتم إضافة أي طريقة دفع للتذكرة حتى الآن!",
    },

    notes: {
      // Section
      title: "ملاحظات",
      receipt: "مذكرة تذكرة",
      customer: "وصف العميل",
      appointment: "مذكرة موعد",
    },

    applyDiscount: {
      // Modal
      title: "تطبيق الخصم",
      typeInputLabel: "نوع الخصم",
      typeCASH: "خصم على أساس TL",
      typePERCENT: "نسبة الخصم",
      amountInputLabel: "مقدار الخصم",
      discardButtonTitle: "يستسلم",
    },

    packageSale: {
      // Modal
      title: "بيع حزمة جديدة",
      completeButtonTitle: "بيع كامل",

      packageNameInputLabel: "اختيار الحزمة",
      serviceInputLabel: "اختيار الخدمة",
      staffInputLabel: "اختيار الموظف",
      startInputLabel: "تاريخ البدء",
      periodInputLabel: "عدد الجلسات",
      periodBetweenInputLabel: "الفاصل الزمني للجلسة",
      periodAmountInputLabel: "سعر الجلسة",

      noDataText: "لم يتم إضافة جلسة خدمة إلى بيع الحزمة حتى الآن!",
      totalAmount: "إجمالي العبوة ",

      servicePickToast: "أكمل خيارات خدمتك بالكامل!",
      customerPickToast: "أكمل اختيار العميل!",
    },

    serviceTransaction: {
      // Modal
      title: "بيع خدمة جديدة",
      addButtonTitle: "يضيف",
      discardButtonTitle: "يستسلم",

      staffInputLabel: "اختيار الموظفين",
      serviceInputLabel: "اختيار الخدمة",
      customerStateInputLanel: "حالة العميل",
      successAdd: "تمت إضافة الخدمة بنجاح",
    },

    stockSale: {
      // Modal
      title: "مبيعات المنتجات الجديدة",
      addButtonTitle: "يضيف",
      discardButtonTitle: "يستسلم",

      staffInputLabel: "موظفي المبيعات",
      stockInputLabel: "اختيار المنتج",
    },

    delete: {
      // Modal
      title: "هل تؤكد الحذف؟",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
      alertText:
        "هذه العملية لا رجوع فيها! إذا قمت بحذف هذه التذكرة ، فسيتم حذف المعاملات فيها معًا. يمكن أن يؤثر على سجلاتك المحاسبية!",
    },

    discountBeforePaymentToast: "لتطبيق الخصم ، يجب عليك إزالة المدفوعات.",
    saveChangesToast: "التغييرات التي تم حفظها بنجاح.",

    transactionSuccessToast: "تم حفظ الملاحظة بنجاح",
    agreementSuccessToast: "تم إرسال الاتفاقية بنجاح.",
    uploadAgreementErrorToast: "يجب ألا يتجاوز حجم الاتفاقية 2 ميجابايت.",
    uploadAgreementWarnToast: "جارٍ إعداد الاتفاقية...",
    uploadAgreementSuccessToast: "تم تحميل الاتفاقية بنجاح.",
    stockItemErrorToast: "لم يتم العثور على السجل",
    pointPaidWarnToast: "تم استخدام نقاط أكثر مما تم تجميعه من نقاط العميل. يرجى تعديل مدفوعاتك!",

    headingTitles: {
      receiptId: "رقم الإيصال: ",
      services: "الخدمات",
      staff: "الموظفين",
      service: "خدمة",
      amount: "الرسوم",
      products: "المنتجات",
      product: "منتج",
      quantity: "الكمية",
      receiptTotalAmount: "إجمالي الإيصال: ",
      discount: "الخصم: ",
      netTotal: "الإجمالي الصافي: ",
      paidAmount: "المبلغ المدفوع: ",
      remaintAmount: "المبلغ المتبقي: "
    },

    agreementSaveButtonWarnToast1: "يرجى حفظ التغييرات التي قمت بها.",
    agreementSaveButtonWarnToast2: "لاستخدام هذه الميزة لحزمة ترخيص Diamond، تحتاج إلى شراء حزمة ترخيص Diamond.",
    routeToLogs: "انتقل إلى سجلات معاملات الإيصالات",
    previewTitle: "معاينة",
    previewErrorToast: "رقم الهوية التركية غير صالح",

    selectStaff: "اختيار الموظف (اختياري)",
    paymentAmount: "مبلغ الدفع",
    installments: {
      two: "2 قسط",
      three: "3 أقساط",
      four: "4 أقساط",
      five: "5 أقساط",
      six: "6 أقساط",
      seven: "7 أقساط",
      eight: "8 أقساط",
      nine: "9 أقساط",
      ten: "10 أقساط",
      eleven: "11 قسط",
      twelve: "12 قسط"
    },

    charLimitTitle: "حد الأحرف الخاص بك",

    greenHourTitle: "تظهر الفترات الزمنية المتاحة لحجز المواعيد باللون الأخضر.",
    redHourTitle: "تظهر الفترات الزمنية التي تم حجزها سابقًا أو التي تم إغلاقها من تفاصيل الموظف باللون الأحمر.",
    orangeHourTitle: "عندما يتم إغلاق الوقت من قبل الشخص المخول، يظهر باللون البرتقالي.",

    uploadAgreementTitle: "تحميل العقد",
    uploadAgreementText: "انقر هنا لتحميل العقد المرتبط بهذه الإيصال.",
    uploadAgreementWarnToast2: "يرجى ملء الحقول الناقصة: اسم العقد.",
    savedAgreementTitle: "العقود المسجلة",
    selectAgreementTemplateTitle: "اختر نموذج العقد",

    nationalIDTitle: "رقم الهوية الوطنية",
    taxIDTitle: "رقم الضريبة",
    addressTitle: "العنوان",

    sendToSignatureTitle: "إرسال للتوقيع",
    sendToSignatureAlertDialogTitle: "هل تؤكد إرسال العقد المعدل للتوقيع عبر الهاتف المحمول؟",
    sendToSignatureAlertDialogText: "يمكنك عرض العقد المرسل وتعديله وتوقيعه من قسم قائمة العقود في تطبيقك المحمول.",

    getServices: "احصل على الخدمات المتاحة",
    clear: "مسح",
    deletedService: "خدمة محذوفة",

    saveBeforeGetAppointmentWarnToast: "يرجى حفظ التغييرات قبل حجز الموعد.",
    addNote: "إضافة ملاحظة",

    getProducts: "احصل على المنتجات",
    installmentText: "تقسيط",

    packageSessionUpdateTitle: "تحديث جلسة الحزمة",
    packageSessionUpdateText1: `سيتم تحديث جميع الجلسات في الحزمة إلى "%s". هل تؤكد؟`,
    packageSessionUpdateText2: "ملاحظة: لا تنسَ حفظ التغييرات بعد هذا التحديث.",

    packageDateUpdateTitle: "تحديث تاريخ الحزمة",
    packageDateUpdateText: "سيتم تحديث تواريخ جميع الجلسات في الحزمة وفقًا لنطاق التاريخ. هل تؤكد؟",

    packageDeleteTitle: "حذف الحزمة",
    packageDeleteText1: "سيتم حذف الحزمة وجميع الخدمات الموجودة بها. هل تؤكد؟",
    packageDeleteText2: "إذا أكدت الحذف، فسيتم حذف الحزمة وجميع الخدمات الموجودة بها. هل تؤكد؟",

    stockSaleErrorToast: "المنتج المحدد غير متوفر في المخزون.",
    barcodeInputPlaceholder: "أدخل الرمز الشريطي.",
  },

  "packages/sales": {
    filter: {
      title: "التصفية على أساس التاريخ",
    },

    loaderTitle: "يتم تحميل الحزم. يرجى الانتظار...",
  },

  /**
   * Paket satışının yapıldığı sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/sales/add
   */
  "packages/sales/add": {
    searchPackegeInputLabel: "حزمة البحث",
    customerInputLabel: "سيتم بيع العميل",
    customerInputPlaceholder: "أدخل اسم العميل في هذا المجال",

    importPackageButtonTitle: "حزمة الاستيراد",
    addServiceButtonTitle: "أضف الخدمة إلى الحزمة",
    completeButtonTitle: "بيع كامل",

    enterPackageName: "أدخل اسم الحزمة",

    serviceInputLabel: "اختيار الخدمة",
    staffInputLabel: "اختيار الموظف",
    startInputLabel: "تاريخ البدء",
    periodInputLabel: "عدد الجلسات",
    periodBetweenInputLabel: "الفاصل الزمني للجلسة",
    periodAmountInputLabel: "سعر الجلسة",
    totalInputLabel: "إجمالي العبوة ",

    noDataText: "لم يتم إضافة جلسة خدمة إلى بيع الحزمة حتى الآن!",

    addPackageInSaleWarnToast: "تأكد من أنك قد اخترت الحزمة التي ترغب في استيرادها!",
    validatePackageSelectServiceWarnToast: "أكمل اختيارات الخدمة بشكل كامل!",
    validatePackageSelectCustomerWarnToast: "أكمل اختيار العميل بشكل كامل!",

    dayTitle: "%s يوم",

    modal: {
      title: "استيراد عبوات جاهزة",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
      packageInputLabel: "اختيار الحزمة",
    },
  },

  /**
   * Paketlerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/list
   */
  "packages/list": {
    headings: {
      name: "اسم الحزمة",
      amount: "مبلغ العبوة",
    },
  },

  /**
   * Paketlerin oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/add
   */
  "packages/add": {
    name: "اسم الحزمة",
    completeButtonTitle: "إنشاء الحزمة",
    addServiceButtonTitle: "إضافة خدمة جديدة إلى الحزمة",

    serviceInputLabel: "اختيار الخدمة",
    periodInputLabel: "عدد الجلسات",
    periodAmountInputLabel: "سعر وحدة الجلسة ",
    totalInputLabel: "السعر الإجمالي للباقة ",

    noDataText: "لم يتم إضافة أي جلسات خدمة إلى الباقة حتى الآن!",

    validateServiceToast: "أكمل خيارات خدمتك بالكامل!",
    insertToast: "تم إنشاء الحزمة بنجاح.",
  },

  /**
   * Paket detayı sayfasının bulunduğu key.
   * https://app.salonrandevu.com/packages/detail/:id
   */
  "packages/detail/id": {
    updatePackageButtonTitle: "حزمة التحديث",
    deletePackageButtonTitle: "حذف الحزمة",
    addServiceButtonTitle: "إضافة خدمة جديدة إلى الحزمة",

    nameInputLabel: "اسم الحزمة",
    serviceInputLabel: "اختيار الخدمة",
    countInputLabel: "عدد الجلسات",
    periodAmountInputLabel: "سعر وحدة الجلسة",

    totalInputLabel: "السعر الإجمالي للباقة ",
    noDataText: "لم يتم إضافة أي جلسات خدمة إلى الباقة حتى الآن!",

    delete: {
      title: "قم بالتأكيد لحذف الحزمة",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "يستسلم",
      alertText:
        "هل تؤكد حذف الحزمة؟ لن تتأثر المبيعات والفواتير والسجلات المحاسبية السابقة.",
    },

    validateServiceToast: "أكمل خيارات الخدمة الخاصة بك!",
    deleteToast: "تم حذف الحزمة بنجاح.",
    updateToast: "تم تحديث الحزمة بنجاح",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  products: {
    deleteButtonTitle: "حذف المنتج",

    headings: {
      name: "اسم المنتج",
      amount: "رسوم المنتج",
      limit: "كمية المخزون",
      bounty: "نسبة قسط التأمين",
    },
    delete: {
      // Modal
      title: "تأكيد حذف المنتج",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",

      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText: "إذا قمت بحذفه ، فلن تتمكن من البيع على هذا المنتج مرة أخرى!",
    },

    deleteToast: "تم حذف المنتج بنجاح.",
    stockName: "اسم المخزون",

    insertStockSaleSuccessToast: "تمت عملية البيع بنجاح!",
    customerNameInput: "أدخل اسم العميل الكامل في هذا الحقل",
    goWithSelectedCustomer: "المتابعة مع العميل المحدد",
    goWithSelectedCustomerWarnToast: "يجب أن تختار العميل أولاً.",
    goWithoutSelectedCustomer: "المتابعة مع عميل غير محدد",

    productList: "قائمة المنتجات",
    productName: "اسم المنتج",
    productAmount: "سعر المنتج",
    stockPiece: "الكمية المتوفرة",
    addToCart: "أضف إلى السلة",

    cartList: "العناصر في السلة",
    singleAmount: "سعر الوحدة",
    totalAmount: "السعر الإجمالي",
    removeFromCart: "إزالة من السلة",

    completeStockSale: "إكمال عملية البيع",

    codeLabel: "كود",
    saleDate: "تاريخ البيع",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  "products/add": {
    searchProductInputLabel: "البحث عن المنتج",
    searchProductInputPlaceholder: "أدخل اسم المنتج",

    createButtonTitle: "إنشاء منتج",

    nameInputLabel: "اسم المنتج",
    nameInputPlaceholder:
      "أدخل اسم المنتج المراد إدراجه في تطبيق الإدارة الخاص بك.",
    amountInputLabel: "رسوم المنتج ",
    amountInputHint:
      "المبلغ الذي تحدده هو المبلغ الافتراضي. يمكنك تغيير سعر المعاملة أثناء الإضافة إلى التذكرة.",
    bountyInputLabel: "نسبة القسط (%)",
    bountyInputHint:
      "إذا لم يتم تحديد نسبة أقساط ثابتة لموظفيك ، فسوف يكسبون أقساطًا بالمبلغ الذي سيتم تحديده.",
    pointInputLabel: "نقاط لكسبها",
    addBarcode: "إنشاء منتج باستخدام الباركود",
    addBarcodeExplain: "أدخل الرمز لإنشاء باركود للمنتج.",
    pointInputHint: "100 نقطة للعميل تعادل 1 ليرة تركية.",
    limitInputLabel: "Stok Adeti",
    limitInputHint: "أدخل كمية المخزون للمنتج. يمكنك تغيير هذه القيمة لاحقًا.",

    insertToast: "تم إنشاء المنتج بنجاح.",
    barcodeCheckTitle:
      "المحتوى المدفوع غير نشط. يرجى الاتصال بالممثل للتفعيل..",
  },

  /**
   * Ürün detay sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products/detail/:id
   */
  "products/detail/id": {
    updateButtonTitle: "تحديث المنتج",

    nameInputLabel: "اسم المنتج",
    nameInputPlaceholder:
      "أدخل اسم المنتج المراد إدراجه في تطبيق الإدارة الخاص بك.",
    amountInputLabel: "رسوم المنتج ",
    amountInputHint:
      "المبلغ الذي تحدده هو المبلغ الافتراضي. يمكنك تغيير سعر المعاملة أثناء الإضافة إلى التذكرة.",
    bountyInputLabel: "نسبة القسط (%)",
    bountyInputHint:
      "إذا لم يتم تحديد نسبة أقساط ثابتة لموظفيك ، فسوف يكسبون أقساطًا بالمبلغ الذي سيتم تحديده.",
    pointInputLabel: "نقاط لكسبها",
    pointInputHint: "100 نقطة للعميل تعادل 1 ليرة تركية.",
    limitInputLabel: "Stok Adeti",
    limitInputHint: "أدخل كمية المخزون للمنتج. يمكنك تغيير هذه القيمة لاحقًا.",

    updateToast: "تم تحديث المنتج بنجاح.",
  },

  /**
   * Aktif müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers
   */
  customers: {
    alertTExt:
      "إنه الإعداد لتشغيل / إيقاف إرسال الرسائل القصيرة لعملائك الذين تمت إضافتهم إلى قائمة العملاء الخاصة بك. عند إجراء هذه العملية ، سيتم تحديث إعدادات إرسال الرسائل القصيرة لجميع عملائك.",
    filter: {
      title: "عامل تصفية بحث العملاء",
      inputLabel: "الاسم واللقب أو رقم الهاتف",
    },
    amount: {
      openSmsSendingStatus: "افتح حالة تسليم الرسائل القصيرة",
      closeSmsSendingStatus: "قم بإيقاف تشغيل حالة إرسال الرسائل القصيرة",
      title: "عرض المبالغ",
      updateSmsStatus: "تحديث حالة الرسائل القصيرة",
      allCustomerSmsUpdate: "ستتغير حالة تسليم الرسائل القصيرة لجميع العملاء!",
      excelTooltip: "يمكنك إضافة بيانات إضافية إلى قائمة العملاء مثل مبلغ الدين ومبلغ الأرباح وحالة تسليم الرسائل القصيرة إلى ملف Excel.",
    },
    headings: {
      code: "كود العميل",
      fullName: "اسم اللقب",
      phone: "رقم الهاتف",
      colorSelect: "اختيار اللون",
      sex: "جنس",
      incomeAll: "المبلغ المكتسب",
      debtAll: "مبلغ الدين",
      message_state: "حالة الرسالة",
      message_open: "مفتوح",
      message_close: "مغلق",
    },
    replacements: {
      totalCustomerNumber: "إجمالي عدد العملاء",
      sex: {
        undef: "غير محدد",
        male: "الولد",
        female: "النساء",
      },
    },
    passiveAll: {
      passiveAllButtonTitle: "اجعل كل شيء سلبي",
      title: "هل توافق على تعطيل جميع عملائك؟",
      alertText:
        "إذا قمت بإلغاء تنشيط جميع العملاء ، فلن تتمكن من رؤية العملاء في مجالات مثل المواعيد / الفواتير مرة أخرى.يمكنك الوصول إلى معلومات العملاء من علامة التبويب العملاء السلبيون وقتما تشاء.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",

      successToast: "تم إلغاء تنشيط جميع العملاء بنجاح.",
    },
    passiveSelected: {
      passiveSelectedButtonTitle: "تعطيل المحدد",
      title: "هل توافق على إلغاء تنشيط عملاء محددين؟",
      alertText:
        "إذا قمت بإلغاء تنشيط العملاء المحددين ، فلن تتمكن من رؤية العملاء مرة أخرى في مجالات مثل المواعيد / الفواتير.يمكنك دائمًا الوصول إلى معلومات العملاء من علامة التبويب العملاء السلبيون.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",

      successToast: "تم إلغاء تنشيط العملاء المحددين بنجاح.",
    },

    months: {
      jan: "يناير",
      feb: "فبراير",
      mar: "مارس",
      apr: "أبريل",
      may: "مايو",
      jun: "يونيو",
      jul: "يوليو",
      aug: "أغسطس",
      sep: "سبتمبر",
      oct: "أكتوبر",
      nov: "نوفمبر",
      dec: "ديسمبر"
    },
    customerFullNameLabel: "عميل غير محدد",
    ispackageTrueLabel: "بيع باقة",
    ispackageFalseLabel: "بيع قياسي",
    createSuccessToast: "تم إنشاء سجل العميل بنجاح!",
    wrongDateWarnToast: "لقد قمت بإدخال تاريخ خاطئ. يرجى إعادة اختيار 'تاريخ الميلاد'.",
    errorOccuredToast: "حدث خطأ غير متوقع. يرجى الاتصال بممثل خدمة العملاء الخاص بك.",
    deletedStaffLabel: "(موظف محذوف)",
    photoLimitErrorToast: "لقد تجاوزت حد الصور المسموح به للحزمة الحالية.",
    photoUploadErrorToast: "لا يمكنك تحميل صورة.",
    updateToPassiveSuccessToast: "تم تعيين العميل بنجاح كعميل غير نشط!",
    updateToActiveSuccessToast: "تم تعيين العميل بنجاح كعميل نشط!",
    customerDeleteSuccessToast: "تم حذف العميل بنجاح.",
    suitableClockWarnToast: "يرجى اختيار وقت مناسب لتاريخ الموعد.",
    staffUpdateWarn1: "لا يمكنك إجراء تغييرات على معلومات العميل وفقًا لامتيازاتك!",
    staffUpdateWarn2: "يمكن فقط للسلطات التجارية تعديل معلومات العملاء وحذف العملاء.",
    whatsappTooltipTitle: "التواصل عبر واتساب",
    whatsappWarnToast: "المحتوى المدفوع غير مفعل. يرجى التواصل مع ممثلك.",
    sendMessageTooltipTitle: "إرسال رسالة",

    contractHeading: {
      date: "التاريخ",
      agreement: "اسم العقد",
      bytes: "الحجم",
      transactions: "العمليات"
    },

    sendContractViaSMS: "هل تريد إرسال العقد المسجل عبر الرسائل القصيرة؟",
    sendContractMessage: "هل أنت متأكد أنك تريد إرسال '%v' إلى العميل باسم %s عبر الرسائل القصيرة؟",
    sendContractWP: "هل أنت متأكد أنك تريد إرسال '%v' إلى العميل باسم %s عبر واتساب؟",
    sendContractViaWP: "هل تريد إرسال العقد المسجل عبر واتساب؟",

    deleteContractTitle: "هل تؤكد حذف العقد؟",
    deleteContractAccept: "تأكيد وحذف",
    deleteContractWarn1: "سيتم حذف العقد نهائيًا.",
    deleteContractWarn2: "لا يمكن التراجع عن هذا الإجراء. سيتم حذف العقد نهائيًا.",

    serviceTableLabel: "الحزم",
    routeToAppointment: "اذهب إلى الموعد",
    acceptAppointmentDecline: "هل تؤكد إلغاء الموعد؟",
    appointmentDeclineWarn1: "لا يمكن التراجع عن هذا الإجراء!",
    appointmentDeclineWarn2: "سيتم حذف الموعد من التقويم. هل تؤكد؟",

    beforePhoto: "الصورة السابقة",
    afterPhoto: "الصورة التالية",

    routeToLogs: "اذهب إلى سجلات معاملات العملاء",
  },

  "logs/index": {
    filter: {
      title: "مرشح البحث المتقدم",
      form: {
        description: "تفاصيل المعاملة / اسم العميل / اسم الموظف",
        startDate: "تاريخ البدء",
        endDate: "تاريخ الانتهاء",
        allDate: "كل الأوقات",
        findCustomer: {
          label: "اسم الزبون",
          placeholder: "ابحث عن العملاء",
          helper: "تصفية حسب عميل معين فقط",
        },
      },
    },
  },

  /**
   * Riskli müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/risky
   */
  "customers/risky": {
    offsetInputLabel: "تحديد نطاق اليوم",
    offsetItem15: "أولئك الذين ظلوا غير نشطين لأكثر من 15 يومًا",
    offsetItem30: "أولئك الذين ظلوا غير نشطين لأكثر من 30 يومًا",
    offsetItem45: "أولئك الذين ظلوا غير نشطين لأكثر من 45 يومًا",
    offsetItem60: "أولئك الذين ظلوا غير نشطين لأكثر من 60 يومًا",

    headings: {
      code: "كود العميل",
      fullName: "اسم اللقب",
      phone: "رقم الهاتف",
      sex: "جنس",
      incomeAll: "المبلغ المكتسب",
      debtAll: "مبلغ الدين",
      lastTransaction: "الوقت المنقضي منذ آخر معاملة",
    },

    sendMessageSuccessToast: "تم إرسال الرسالة بنجاح.",
    sendMessageButtonTitle: "إرسال رسالة الحملة",
    selectedAllButtonTitle: "اختر الكل",
    cancelAllButtonTitle: "حذف الكل",
    listButtonTitle: "الحصول على العملاء المفقودين",
    noDataTextInitial: "يرجى اختيار تاريخ ثم الضغط على زر \"الحصول على العملاء المفقودين\".",
    noDataText: "لم يتم العثور على عملاء مفقودين في نطاق التاريخ المختار.",
    infoText: "معلومات!",
  },

  /**
   * Değerlendirme anketlerinin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/rating
   */
  "customers/rating": {
    headings: {
      createdAt: "تاريخ التقييم",
      staffFullName: "الموظف المهتم",
      serviceName: "الخدمة المنفذة",
      companyStar: "نقاط الأعمال",
      staffStar: "نقاط الموظف",
      description: "تفسير",
    },

    deleteButtonTitle: "حذف التقييم",
    replyToComment: "إجابه",
    viewToComment: "رأي",
    delete: {
      title: "هل تؤكد الحذف؟",
      alertText:
        "هذه العملية لا رجوع فيها! إذا حذفت استبيان التقييم هذا ، فلن تتمكن من الوصول إليه بعد الآن.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
    },

    message: {
      title: "الرد على التعليق",
      comment: "أدلى التعليق",
      alertText: "اكتب تعليقك.",
      confirmButtonTitle: "يرسل",
      discardButtonTitle: "يلغي",
    },

    deleteToast: "تم حذف التصنيف المحدد بنجاح!",
    deleteBeforePlanUpgradeToast:
      "لحذف مراجعة العميل ، تحتاج إلى ترقية الحزمة الخاصة بك إلى حزمة متميزة.",
  },

  /**
   * Müşterilerin eklendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/add
   */
  "customers/add": {
    nameInputLabel: "اسم الزبون",
    nameInputPlaceholder: "أدخل اسم العميل",

    surnameInputLabel: "الاسم الأخير للعميل",
    surnameInputPlaceholder: "أدخل لقب العميل",

    phoneInputLabel: "جوال",

    genderInputLabel: "جنس",
    genderMale: "الولد",
    genderFemale: "النساء",

    disableOnlineBooking: "موعد عبر الإنترنت",
    disableOnlineBookingLabelTrue: "لا يمكن",
    disableOnlineBookingLabelFalse: "يمكن",
    secondPhoneNumberWarning: "الرقم الثاني لا يمكن أن يكون فارغ",

    firstPhoneNumberWarning: "الرقم الأول لا يمكن أن يكون فارغ",
    bothPhoneNumberWarning: "لا يمكن أن يكون أحد أو كلا رقمي الهاتف فارغين",
    sendSmsTooltipTitle: "يشير إلى حالة إرسال الرسائل مثل إرسال إشعارات الموعد وإعادة تأكيد الموعد.",

    blacklist: {

      primary: "1. رقم الهاتف في القائمة السوداء. لا يمكن إرسال الرسائل إلى هذا الرقم.",
      secondary: "2. رقم الهاتف في القائمة السوداء. لا يمكن إرسال الرسائل إلى هذا الرقم.",
      both: "1. و 2. رقم الهاتف في القائمة السوداء. لا يمكن إرسال الرسائل إلى هذه الأرقام.",
      removeBlacklist: "اضغط لإزالة الحظر.",
    },


    birthdayInputLabel: "تاريخ الميلاد",
    birthdayInputInvalidMessage: "الرجاء إدخال التنسيق المطلوب. يوم شهر سنة",

    maritalStatusInputLabel: "الحالة المدنية",
    maritalStatusMarried: "متزوج",
    maritalStatusSingle: "أعزب",

    secondPhoneInputLabel: "رقم الهاتف الثاني",
    secondPhoneInputPlaceholder: "إذا كان متاحًا ، أدخل رقم الهاتف الثاني.",

    mailInputLabel: "عنوان البريد الالكتروني",
    mailInputPlaceholder:
      "إذا كان متاحًا ، أدخل عنوان البريد الإلكتروني الخاص بك.",

    descriptionInputLabel: "شروح",

    workStatusInputLabel: "حالة العمل",
    workStatusWorking: "عمل",
    workStatusUnemployed: "لا يعمل",
    workStatusEmployer: "صاحب العمل",
    workStatusStudent: "التلميذ",
    workStatusHouseWife: "سيدة منزل",

    relatedBusinessInputLabel: "المؤسسة ذات الصلة",
    relatedBusinessInputPlaceholder:
      "حدد المؤسسة التي جاء منها العميل ، إن وجدت.",

    sendSmsInputLabel: "حالة إرسال الرسائل القصيرة",
    sendSmsTrue: "ارسلها",
    sendSmsFalse: "لا ترسل",

    selectPhoneNumberForMessage: "اختر الرقم لإرسال الرسائل إليه",
    firstPhoneNumber: "الرقم الأول",
    secondPhoneNumber: "الرقم الثاني",
    allPhoneNumber: "كلاهما",

    createNewCustomerButtonTitle: "إنشاء العميل",
  },

  "customers/passive": {
    makeActiveButtons: {
      selectedButtonTitle: "جعل المحدد نشطًا",
      allButtonTitle: "جعل كل شيء نشط",
    },

    selectedCustomersSuccessToast: "تم تفعيل العملاء المحددين بنجاح",
    allCustomersSuccessToast: "تم تنشيط جميع العملاء بنجاح",
    allCustomersDeletedSuccessToast: "تم حذف جميع العملاء بنجاح.",
    selectedCustomersDeletedSuccessToast: "تم حذف العملاء المحددين بنجاح.",
    selectedCustomersDeleteWarnToast: "تم حذف العملاء الذين ليس لديهم مواعيد نشطة. إذا كنت ترغب في حذف العملاء الذين لديهم مواعيد نشطة، يجب عليك إلغاء مواعيدهم النشطة.",
  },

  /**
   * Toplu müşteri ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/customers/add/bulk
   */
  "customers/add/bulk": {
    orginzeProcess: "جاري تحرير جهات الاتصال. انتظر من فضلك.",
    uploadAreaText: "انقر فوق هذا الحقل لتحميل ملف Excel",
    stepsHeading: "خطوات التحميل الجماعي للعملاء باستخدام Excel",
    steps: [
      "من أجل إضافة عملائك إلى النظام عبر Excel ، تحتاج إلى تحميل بياناتك بتنسيق excel الذي أعددناه.",
      "قم بتنزيل قالب Excel بالنقر فوق الزر في الخطوة التالية.",
      "قم بتنزيل تنسيق Excel",
      "قم بمطابقة بيانات العميل الخاصة بك بتنسيق Excel الذي قمت بتنزيله في الخطوة السابقة وقم بتحميله على النظام.",
    ],
    boldText:
      "هام: لنقل البيانات بدون خسارة وخالية من الأخطاء ، يجب عدم تغيير أماكن وأسماء الأعمدة في قالب Excel!",

    preview: {
      heading: "معاينة تحميل Excel",
      completeButtonTitle: "استكمال تسجيل العملاء بالجملة",

      nameInputLabel: "اسم",
      nationalID: "الهوية الوطنية",
      surnameInputLabel: "الكنية",
      phoneInputLabel: "جوال",
      sendSmsInputLabel: "إرسال الرسائل القصيرة",
      sendSmsTrue: "يفتح",
      sendSmsFalse: "مغلقة",
      selectedPhoneLabel: "الرقم المحدد",
      descriptionLabel: "ملاحظات إضافية",

      genderInputLabel: "جنس",
      genderMale: "الولد",
      genderFemale: "النساء",

      mailInputLabel: "عنوان البريد الالكتروني",
      birthdayInputLabel: "تاريخ الميلاد",

      maritalStatusInputLabel: "الحالة المدنية",
      maritalStatusMarried: "متزوج",
      maritalStatusSingle: "أعزب",
    },

    duplicateRecords: "%s تم حذف السجلات المكررة.",

    duplicatedPhoneWarnToast: "أرقام الهواتف مسجلة بالفعل في النظام.",

    insertToast:
      "تم وضع التحميل المجمع الخاص بك في قائمة الانتظار بنجاح! يتم إعادة توجيهك إلى صفحة القائمة.",
  },

  /**
   * Hizmet listesinin bulunduğu key.
   * https://app.salonrandevu.com/services
   */
  services: {
    filter: {
      title: "عامل تصفية بحث الخدمة",
      inputLabel: "اسم الخدمة",
    },

    headings: {
      name: "اسم الخدمة",
      processTime: "وقت المعالجة",
      bounty: "نسبة القسط",
      amount: "رسوم الخدمة",
      showAtUrl: "تحديد موعد عبر الإنترنت",
    },

    replacements: {
      showAtUrlTrue: "يفتح",
      showAtUrlFalse: "مغلقة",
    },

    deleteButtonTitle: "حذف الخدمة",
    delete: {
      title: "هل تؤكد حذف الخدمة؟",
      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "إذا حذفت هذه الخدمة ، فلن تتمكن من إجراء المعاملات في إدارة التذاكر ، ولن تتمكن من تحديد موعد من خلال هذه الخدمة أثناء تحديد / تحديد موعد. سيتم الاحتفاظ بمواعيدك الحالية لهذه الخدمة.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
    },

    dataNotFound: "لم يتم العثور على بيانات!",

    addAllService: {
      warningTitle: "تحذير!",
      addButtonTitle: "إضافة خدمة مجمعة",
      informationDialog: {
        addServiceDescription:
          "يقوم النظام تلقائيًا بتعيين مدة وسعر الخدمات التي قمت بنقلها. يرجى تحديث فترة الخدمة ومعلومات السعر التي قدمتها في عملك.",
        confirmButtonTitle: "أفهم",
      },
    },

    deleteAll: {
      title: "هل تؤكد حذف الخدمات؟",
      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "إذا قمت بحذف جميع الخدمات ، فلن تتمكن من إجراء المعاملات في إدارة التذاكر ، ولن تتمكن من تحديد موعد من خلال هذه الخدمات أثناء تحديد / تحديد موعد. سيتم الاحتفاظ بمواعيدك الحالية لهذه الخدمات.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
      deleteAllServicesToast: "تم حذف الخدمات بنجاح",
      deleteAllButtonTitle: "احذف كل شيء",
    },

    deleteSelectedServices: {
      title: "هل تؤكد حذف الخدمات المختارة؟",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "إذا قمت بحذف الخدمات المحددة ، فلن تتمكن من إجراء المعاملات في إدارة التذاكر ، ولن تتمكن من تحديد موعد من خلال هذه الخدمات أثناء تحديد / تحديد موعد. سيتم الاحتفاظ بمواعيدك الحالية لهذه الخدمات.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
      deleteSelectedButtonTitle: "حذف الخدمات المحددة",
    },

    deleteToast: "تم حذف الخدمة بنجاح.",

    amountBasisTitle: "حسب المبلغ",
    percentBasisTitle: "(%) بناءً على النسبة",
  },

  /**
   * Hizmet detay/ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/services/add
   * https://app.salonrandevu.com/services/detail/id
   */
  "services/detail": {
    placeholder: {
      search: "الرجاء إدخال الخدمة التي تبحث عنها",
    },
    validate: {
      serviceCategory: "فئة الخدمة",
      servicesName: "اسم الخدمة",
      servicePrice: "سعر الخدمة",
      servicesProcessTime: "وقت معالجة الخدمة",
    },
    selcetAllEmployees: "حدد كل الموظفين",

    serviceName: "أنت تبحث عن",
    lookingFor: " اسم الخدمة",
    ourList: "ليس على قائمتنا؟",
    createRequest: "خلق الطلب.",
    newCreateRequest: "إنشاء طلب خدمة",
    createNewRequest: "إنشاء طلب جديد.",
    nameInputLabel: "اسم الخدمة",
    nameInputHint:
      "أدخل اسم الخدمة التي سيتم إدراجها في تطبيق الإدارة الخاص بك وفي صفحة الموعد عبر الإنترنت.",

    amountInputLabel: "رسوم الخدمة ",
    amountInputHint:
      "المبلغ الذي تحدده هو المبلغ الافتراضي. يمكنك تغيير سعر المعاملة أثناء الإضافة إلى التذكرة.",

    bountyInputLabel: "نسبة القسط (%)",
    bountyMoneyBased: "مبني على المال الإضافي",
    bountySelectMoney: "مبني على المال",
    bountySelectPer: "نسبة مئوية للمكافأة",
    bountyInputHint:
      "إذا لم يكن لدى موظفيك نسبة أقساط ثابتة محددة ، فسوف يكسبون أقساطًا من هذه الخدمة بالنسبة المئوية المحددة.",

    pointInputLabel: "نقاط لكسبها",
    pointInputHint: "100 نقطة عميل تعادل 1 ليرة تركية.",

    timeInputLabel: "متوسط ​​وقت المعالجة (دقيقة)",
    timeInputHint:
      "وقت المعالجة عبارة عن معلومات سيتم عرضها عندما يقوم العميل بتحديد موعد. لن يتمكن الموظف الذي يقوم بتشغيل هذه الخدمة من الحصول على موعد جديد خلال فترة المعالجة.",

    repeatInputLabel: "Tekrar Süresi (gün)",
    repeatInputHint:
      "إذا كانت هذه الخدمة تتطلب التكرار ، فستسمح لك بإبلاغ عملائك عن طريق الرسائل القصيرة / البريد الإلكتروني عندما يحين وقت تكرارها.",

    urlInputLabel: "تحديد موعد عبر الإنترنت",
    urlInputHint:
      "إنه إعداد ما إذا كان سيتم عرض / عرض الإجراء في المواعيد عبر الإنترنت من عنوان URL الخاص بالعمل.",

    urlInputTrue: "يفتح",
    urlInputFalse: "مغلقة",

    staffSectionTitle: "موظفين لتنفيذ الخدمة",
    timeWarnAlertText:
      "سيكون التغيير الذي ستجريه ساريًا من المواعيد المنشأة حديثًا ، ولن يؤثر على المواعيد التي تم إنشاؤها.",

    createButtonTitle: "إنشاء الخدمة",
    updateButtonTitle: "تحديث الخدمة",
    deleteButtonTitle: "حذف الخدمة",

    delete: {
      title: "هل تؤكد حذف الخدمة؟",
      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "إذا حذفت هذه الخدمة ، فلن تتمكن من إجراء المعاملات في إدارة التذاكر ، ولن تتمكن من تحديد موعد من خلال هذه الخدمة أثناء تحديد / تحديد موعد. سيتم الاحتفاظ بمواعيدك الحالية لهذه الخدمة.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
    },

    lastTransactions: {
      headings: {
        createdAt: "تاريخ الصفقة",
        staffFullName: "الموظف المهتم",
        amount: "قيمة التحويل",
      },
      showOnReceiptButton: "إظهار في تذكرة",
    },

    serviceCategoryInput: "فئة الخدمة",
    serviceNameInput: "اسم الخدمة",

    serviceCategoryHint:
      "سيتم استخدام فئة الخدمة واسم الخدمة اللذين تختارهما على منصة مواعيد الصالون.",

    serviceNameHint:
      "لتحديد اسم الخدمة ، يجب عليك أولاً تحديد فئة من قسم فئة الخدمة.",

    deleteToast: "تم حذف الخدمة بنجاح.",
    insertToast: "تم إنشاء الخدمة بنجاح.",
    updateToast: "تم تحديث الخدمة بنجاح.",

    newServiceInputTitle: "اسم الخدمة الجديد",
    newServiceRequestToast: "تم إنشاء طلب الخدمة الجديد الخاص بك بنجاح.",
    newServiceRequestErrorToast: "لا يمكن أن يكون اسم الخدمة الجديد فارغًا.",
    newServiceRequestDialogTitle: "طلب خدمة جديدة",
    newServiceCategoryHint: "فئة الخدمة التي ستعتمد عليها الخدمة الجديدة.",
    createNewServiceRequest: "إنشاء طلب",
    cancelNewServiceRequest: "يلغي",

    setCampaignTitle: "تعيين كحملة",
    campaignNameTitle: "اسم الحملة",
    campaignHint:
      "ستظهر المنتجات التي تم تمييزها على أنها حملات في حقل الحملة بصفحة عملك.",

    serviceDescriptionLabel: "وصف الخدمة",
    serviceDescriptionHint:
      "يتيح وصف الخدمة لعملائك التعرف على الخدمة التي تقدمها بشكل أفضل.",

    continueWithoutCategory: "لا أريد تحديد فئة الخدمة",
  },


  "payment-notify": {
    copyIban: "نسخ رقم الحساب بصيغة IBAN",
    ibanSuccess: "تم نسخ IBAN بنجاح.",
    depositBank: "بنك التحويلات",
    depositAmonut: "كمية التحويلات",
    depositDate: "تاريخ التحويل",
    makePaymentNotice: "جعل إشعار الدفع",
  },

  "services/requests": {
    headings: {
      time: "تاريخ الصفقة",
      name: "اسم الخدمة",
      state: "حالة",
      category: "فئة",
      actionHeadingText: "المعاملات",
      stateCompleted: "تمت الموافقة على الطلب",
      stateIncompleted: "فشل الطلب في التأكيد",
      stateWaiting: "في انتظار الموافقة على الطلب",
    },

    deletedRequestToast: "تم حذف طلب الطلب",

    cancel: {
      cancelButton: "يلغي",
      cancelTitle: "هل تريد حذف طلب خدمة جديد؟",
      cancelConfirmButtonTitle: "أجل",
      cancelDiscardButtonTitle: "لا",
      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "إذا قمت بإلغاء هذا الطلب ، فلن نتمكن من إجراء المعاملة ولن يتم إجراء فحص خدمة جديد.",
    },
  },

  "services/bounty": {
    addBountyTitle: "أضف مكافأة",

    bountyDescription:
      "يمكنك هنا إضافة علاوة خاصة لخدماتك والموظفين الذين ليس لديهم نسبة أقساط ثابتة ، مع خيار تحديد أقساط خاصة.",

    staffInputLabel: "الموظف المراد اضافته مكافأة",
    categoryInputLabel: "فئة الخدمة",
    serviceInputLabel: "اسم الخدمة",
    bountyAmountInputLabel: "نسبة القسط",

    headings: {
      bounty: "نسبة القسط (٪)",
      serviceName: "اسم الخدمة",
      staffName: "اسم الموظف",
      deleteBountyButtonTitle: "حذف قسط",
    },

    deleteBountyDialog: {
      title: "هل أنت متأكد أنك تريد حذف قسط؟",
      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "إذا قمت بحذف هذا القسط ، فلن يكون لموظفك حساب مميز مرتبط بهذه الخدمة.",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
    },

    addBountySuccessToast: "تمت إضافة مكافأة الموظف الخاصة بالخدمة بنجاح.",
    deleteBountySuccessToast: "تم حذف علاوة الموظف للخدمة بنجاح.",
  },

  /**
   * Çalışanların listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees
   */
  employees: {
    editButtonTitle: "تحرير الموظف",
    staffPageTitle: "قائمة الموظفين",
    createNewEmployee: "إنشاء موظف جديد",
    orderStaffs: "تغيير الترتيب",
    insertStaffSuccessToast: "تم إنشاء الموظف بنجاح!",
    updateStaffSuccessToast: "تم تحديث الموظف بنجاح!",
    deleteStaffSuccessToast: "تم حذف الموظف بنجاح!",

    addAllService: "أضف الكل",
    addAllServiceWarn: "الخدمات التي حددتها في الحزمة ليست من بين الخدمات التي يقدمها الموظف. لا يمكنك المتابعة دون إضافة الخدمة إلى خدمات الموظف المقدمة.",
    routeToStaffLogs: "اذهب إلى سجلات معاملات الموظفين",
    packagesTitle: "الباقات المقدمة",
  },

  /**
   * Geri Bildirim verilen sayfa
   * https://app.salonrandevu.com/feedback
   */
  feedback: {
    insertFeedbackSuccessToast: "تم استلام ملاحظاتك بنجاح. شكرًا لك!",
    insertFeedbackWarnToast: "يرجى كتابة ملاحظاتك!",
    feedbackTitle: "اترك ملاحظاتك",
    feedbackLabel1: "نحن نقدر آراء وأفكار واقتراحات مستخدمينا.",
    feedbackLabel2: process.env.APP_NAME === "salon" ? "Salonrandevu" : process.env.APP_NAME === "en" ? "EnRandevu" : "Salon Management",
    feedbackLabel3: "تطبيق الإدارة",
    feedbackLabel4: "تجربة المستخدم",
    feedbackLabel5: "نواصل العمل على كيفية تحسين تجربة المستخدم. ولتحقيق هذا الهدف، قمنا بتطوير قناة للتواصل مع مستخدمينا الكرام باستمرار.",
    feedbackLabel6: "قناة التغذية الراجعة",
    feedbackLabel7: "من خلال النموذج الموجود في الصفحة، يمكنك مشاركة أي أفكار أو آراء أو اقتراحات معنا.",
    feedbackInputLabel: "يمكنك الكتابة هنا",
  },

  /**
   * Çalışan profil sayfalarının listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees/detail/id
   * https://app.salonrandevu.com/employees/add
   * https://app.salonrandevu.com/profile
   */
  profile: {
    detail: {
      //Section
      title: "معلومات الموظف",
      profileTitle: "الملف التعريفي للمعلومات",

      nameInputLabel: "اسم",
      surnameInputLabel: "الكنية",

      genderInputLabel: "جنس",
      genderMale: "الولد",
      genderFemale: "النساء",

      birthdayInputLabel: "تاريخ الميلاد",

      jobTitleInputLabel: "عنوان",
      bountyInputLabel: "نسبة الأقساط الثابتة",

      permissionInputLabel: "حالة سلطة اللوحة",
      permissionOwner: "الرسمية",
      permissionStaff: "عامل",
      permissionLimited: "سلطة محدودة",

      sendSmsInputLabel: "إرسال الرسائل القصيرة",
      sendSmsTrue: "ارسلها",
      sendSmsFalse: "لا ترسل",

      urlInputLabel: "الانطباع على عنوان URL للنشاط التجاري",
      showAtUrlTrue: "يتم اظهار",
      showAtUrlFalse: "لا تظهر",

      branch_list: "قائمة الفروع",
      branch_change: "تغيير الفرع",
      branch_enter: "الانتقال إلى الفرع",
      branch_change_alert: "تم تغيير الفرع.",
    },

    contact: {
      //Section
      title: "معلومات الاتصال",

      mailInputLabel: "عنوان البريد الالكتروني",
      phoneInputLabel: "جوال",
      passwordInputLabel: "كلمة مرور اللوحة",
      newPasswordInputLabel: "كلمة مرور جديدة للوحة",
    },

    services: {
      //Section
      title: "الخدمات التي تقدمها",

      alertBoldText:
        "لم يتم تحديد الخدمات التي يقدمها الموظف بالضغط على الخدمات أدناه!",
      alertText:
        "خلاف ذلك ، فإن الخدمات التي يقدمها الموظف في عنوان التعيين للمؤسسة ستكون فارغة.",
    },

    booking: {
      //Section
      title: "ساعات التعيين",
      alertBoldText: "تعديل وتيرة موعدك!",
      week: [
        "الاثنين",
        "يوم الثلاثاء",
        "الاربعاء",
        "يوم الخميس",
        "جمعة",
        "السبت",
        "الأحد",
      ],
      dayOpenText: "افتح اليوم للتعيين",
      dayCloseText: "أغلق اليوم للموعد",
    },

    photo: {
      //Section
      uploadButtonTitle: "حمل الصورة",
      deleteButtonTitle: "إزالة الصورة",
    },

    createButtonTitle: "إنشاء موظف",
    updateButtonTitle: "حفظ التغييرات",
    deleteButtonTitle: "حذف الموظف",

    logoutButtonTitle: "خروج",

    delete: {
      //Modal
      title: "هل تؤكد الحذف؟",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
      alertBoldText: "هذه العملية لا رجوع فيها!",
      alertText:
        "إذا قمت بحذف هذا الموظف ، فقد لا تتمكن من الوصول إلى ملف تعريف الموظف والمعلومات الأخرى.",
    },

    photoUploadToast: "تم تحميل الصورة بنجاح.",
    photoDeleteToast: "تمت إزالة الصورة بنجاح.",
    insertToast: "تم إنشاء العامل بنجاح.",
    updateToast: "تم تحديث العامل بنجاح.",
    deleteToast: "تم حذف الموظف بنجاح.",

    limited_permission_label: {
      no_permission: "يتعذر الوصول إليها",
      display: "يمكن عرضه او رؤيته",
      full_permission: "مصرح به بالكامل",
      all: "الكل",
      just_itself: "الخاص به"
    },
  },

  /**
   * SMS Yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms
   */
  sms: {
    foundWpDevice: "تم العثور على جهاز WhatsApp المسجل في حسابك.",
    noFoundWpDevice: "لم يتم العثور على أي أجهزة whatsapp مسجلة في حسابك.",

    creditSMSLabel: "متاح / رصيد الرسائل القصيرة المتبقية",
    creditWPLabel: "الرصيد المتوفر / المتبقي في WP",
    senderLabel: "اسم المرسل",

    sendType: {
      label: "طريقة إرسال الرسائل",
      sendSMS: "أرسل رسالة نصية قصيرة",
      sendWP: "ارسل الواتس اب",
      both: "أرسل كلاهما",
    },
    loginWithQr: "مسح رمز الاستجابة السريعة",
    loginWithNo: "أدخل رقم الهاتف",

    addDevice: "إضافة جهاز",
    removeDevice: "إزالة الجهاز",

    deviceStatus: "الحالة",
    wpPhone: "رقم واتساب",
    wpCredits: "رصيد واتساب",

    phoneNotConnected: "الرقم الخاص بك غير متصل",
    deviceConnected: "الجهاز متصل",
    deviceNotConnected: "الجهاز غير متصل",

    wpQrTutorial1: "افتح تطبيق واتساب ، ثم انقر على ︙.",
    wpQrTutorial2: "انقر على زر 'ربط الجهاز'.",
    wpQrTutorial3: "امسح الرمز الشريطي الخاص بك باستخدام الكاميرا الخاصة بك.",

    qrPopupTitle: "مسح رمز الاستجابة السريعة",
    qrPopupContext:
      "امسح رمز الاستجابة السريعة باستخدام كاميرا جهازك للاتصال بالتطبيق.",

    withPhoneNumberTitle: "أدخل الرقم الذي ترغب في استلام رمز واتساب له.",
    codeForThisPhone: "رمز التحقق من WhatsApp لهذا الرقم",

    alertBoldText: "الحصول على معلومات مرسل الرسائل القصيرة ...",
    alertText: "قد تستغرق هذه العملية بعض الوقت ، يرجى الانتظار.",

    undefinedAlerts: {
      wp_heading: "لم يتم العثور على جهاز لإرسال رسائل WhatsApp في حسابك",
      wp_content:
        " لا يوجد موضوع WhatsApp يمكنك إرساله باسم عملك. إذا كنت تريد أن تتمكن من إرسال رسائل واتساب خاصة لعملائك بخلاف رسائل المعلومات الثابتة الخاصة بالمنصة",
      wp_span: "  تحديد الجهاز في حسابك.",
      sms_heading: "لم يتم تعريف رأس الرسائل القصيرة لعمليات التسليم المخصصة!",
      sms_content:
        "   لا يوجد رأس رسالة نصية قصيرة يمكنك إرسالها مع اسم عملك. إذا كنت تريد أن تتمكن من إرسال رسائل نصية قصيرة خاصة إلى عملائك بخلاف رسائل المعلومات الثابتة الخاصة بالمنصة، فأنت بحاجة إلى شراء خدمة الرسائل القصيرة.",
    },

    sendButtonText: "رأي",
    dialogTitle: "عرض الرسائل القصيرة",
    buttonApprove: "يوافق",
    buttonCancel: "يلغي",

    groupSMSReports: "تقارير الرسائل القصيرة الجماعية",
    singleSMSReports: "تقارير الرسائل القصيرة التلقائية",
    groupWPReports: "تقارير مجموعة WP",
    singleWPReports: "تقارير WP التلقائية",

    lastSendingsTitle: "يرسل SMS",
    creditPaybackButtonTitle: "الحصول على استرداد SMS الائتمان",
    whatsappPaybackButtonTitle: "الحصول على استرداد WhatsApp الائتمان",
    searchInputLabel: "أدخل كلمة رئيسية",
    headings: {
      date: "تاريخ",
      sender: "اسم المرسل",
      status: "حالة الشحن",
      content: "محتوى الرسالة",
    },
    replacements: {
      status: ["أرسلت", "لم يتم توجيهها", "رقم غير صالح", "عاد", "ريثما"],
    },

    send: {
      // Section
      messageInputLabel: "محتوى الرسالة",
      charType: "نوع الحرف",
      charTR: "اللغة التركية",
      charNormal: "طبيعي",
      buttonTitle: "أرسل رسالة نصية قصيرة",
      discardButtonTitle: "أغلق",
      sendDate: "تاريخ إرسال",
      futureDate: "متقدمة SMS",
      platformRequired: "لم يتم تحديد المنصة المراد إرسال الرسالة عليها! يرجى اختيار المنصة.",
      platformSelect: "المنصة المراد إرسال الرسالة عليها",
      sampleMessage: "اختيار الرسالة النموذجية",

      autoText: {
        nameSurname: "اسم اللقب",
        amountPaid: "المبلغ المدفوع",
        remainingDebt: "الديون المتبقية",
        businessUrl: "عنوان URL للنشاط التجاري",
        shortCode: "رمز قصير",
        forSmsSelection: "لإلغاء الرسائل القصيرة",
        writeIt: "اكتبها إلى 4607.",
      },

      autoTextShortCode: {
        nameSurname: "|name surname|",
        remainingDebt: "|remaining debt|",
        paidDebt: "|paid debt|",
        businessUrl: "|business url|",
      },

      check: {
        // Modal
        title: "هل توافق على هذا العمل؟",
        confirmButtonTitle: "أجل",
        discardButtonTitle: "لا",
        alertText:
          "بعد موافقتك ، إذا كان رصيدك كافيًا ، ستبدأ عملية الشحن. هذه العملية لا رجوع فيها!",
      },

      template: "اختيار القالب",

      nameSurnameShortcut: "اسم اللقب",
      successToast: "بدأ إرسال الرسائل القصيرة بنجاح!",
    },

    planAlertLabel: "يمكنك التواصل معنا على الرقم 0(212) 706 0510 لشراء باقة الرسائل القصيرة.",
    availableCreditLabel: "الرصيد المتاح/المتبقي",
  },

  /**
   * Seçimli SMS Gönder sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/selective
   */
  "sms/selective": {
    continueButtonTitle: "استمر",
    backToSelectButtonTitle: "رجوع إلى التحديدات",

    warningText: "لا يمكن أن تكون قائمة الإرسال هي نفسها قائمة الاستبعاد!",

    customer: {
      // Section
      title: "قائمة العملاء",
      headings: {
        fullName: "اسم اللقب",
        phone: "رقم الهاتف",
      },

      searchInputLabel: "بحث العملاء",
      selectAll: "حدد كل العملاء",
      unselectAll: "الغاء تحديد الكل",
    },

    approved: {
      // Section
      title: "عمل قائمة",
      notFound: {
        alertBoldText: "لم يتم الاختيار بين العملاء ليتم إرسالها!",
        alertText:
          "يمكنك البدء في اختيار عملائك بالنقر فوق زر التجزئة من قائمة العملاء.",
      },

      alertBoldText: "تم اختيار جميع عملائك بنجاح!",
    },

    excepted: {
      // Section
      title: "قائمة الاستبعاد",
      notFound: {
        alertBoldText: "العملاء الذين سيتم استبعادهم من الشحن لم يتم اختيارهم!",
        alertText:
          "إذا كان لديك عملاء استثنائيون لن يتم إرسالهم من بين عملائك ، فيمكنك استبعاد العميل من الإرسال عن طريق النقر فوق زر الحظر في الجدول.",
      },
    },
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/filter": {
    applyFilterButtonTitle: "تطبيق عامل التصفية",
    continueButtonTitle: "استمر",
    backToFilterButtonTitle: "رجوع إلى التصفية",

    tabs: {
      // Section
      debt: "حالة الديون",
      debtTrue: "مدين",
      debtFalse: "بشكل واضح",

      risk: "حالة الخطر",
      risk15: "لا توجد معاملات في آخر 15 يومًا",
      risk30: "لا توجد معاملات في آخر 30 يومًا",
      risk45: "لا توجد معاملات في آخر 45 يومًا",
      risk60: "لا توجد معاملات في آخر 60 يومًا",

      work: "حالة العمل",
      workWorking: "عمل",
      workUnemployed: "لا يعمل",
      workEmployer: "صاحب العمل",
      workStudent: "التلميذ",
      workHouseWife: "سيدة منزل",

      gender: "جنس",
      genderMale: "الولد",
      genderFemale: "النساء",

      marital: "الحالة الاجتماعية",
      maritalMarried: "متزوج",
      maritalSingle: "أعزب",

      service: "تم استلام الخدمة",
      staff: "الأفراد المعالجون",
      age: "الفئة العمرية",
    },

    customer: {
      // Section
      title: "قائمة العملاء",
      headings: {
        fullName: "اسم اللقب",
        phone: "جوال",
        gender: "جنس",
      },
      replacements: {
        genderNull: "غير محدد",
        genderFemale: "النساء",
        genderMale: "الولد",
      },
    },

    except: {
      title: "تصفية قائمة الاستبعاد",
      alertBoldText: "العملاء الذين سيتم استبعادهم من الشحن لم يتم اختيارهم!",
      alertText:
        "إذا كان لديك عملاء استثنائيون لن يتم إرسالهم من قائمة العملاء بعد التصفية ، فيمكنك استبعاد العميل من الإرسال عن طريق النقر فوق زر الحظر في الجدول.",
    },

    sendFilteredSmsErrorToast: "يرجى اختيار إحدى طرق الإرسال.",
    sendFilteredSmsSuccessToast: "تم بدء إرسال الرسائل القصيرة بنجاح!",
    shortCodeButtonMessage: "%s اكتب %v لإلغاء الرسالة النصية القصيرة وأرسلها إلى 4607.",

    smsMessageTitle: "رسالة SMS",
    wpMessageTitle: "رسالة واتساب",
  },

  "sms/group": {
    continueButtonTitle: "استمر",
    backToSelectButtonTitle: "رجوع إلى التحديدات",

    newGroupButtonTitle: "إنشاء مجموعة",

    group: {
      title: "قائمة المجموعة",

      headings: {
        groupName: "أسم المجموعة",
        customerCount: "عدد العملاء",
      },

      searchInputLabel: "مجموعة البحث",
      searchInputPlaceholder: "أدخل اسم المجموعة ",
      selectAll: "حدد كل المجموعات",
      unselectAll: "الغاء تحديد الكل",
    },

    approved: {
      title: "عمل قائمة",
      notFound: {
        alertBoldText: "لم يتم التحديد بين المجموعات التي سيتم إرسالها!",
        alertText:
          "يمكنك البدء في تحديد مجموعاتك بالنقر فوق زر التجزئة من قائمة المجموعة.",
      },

      alertBoldText: "تم اختيار كل مجموعاتك بنجاح!",
    },

    excepted: {
      title: "قائمة الاستبعاد",
      notFound: {
        alertBoldText:
          "المجموعات التي سيتم استبعادها من النشر لم يتم اختيارها!",
        alertText:
          "إذا كانت لديك مجموعات استثناءات ضمن مجموعاتك لن يتم إرسالها ، فيمكنك استبعاد المجموعة من الإرسال بالنقر فوق زر الحظر في الجدول.",
      },
    },

    newGroup: {
      title: "مجموعة جديدة",

      groupName: "أسم المجموعة",
      groupEditButtonTitle: "تعديل",

      customersTitle: "زبائنك",
      selectedCustomersTitle: "عملاؤك المختارون",

      searchCustomer: "بحث العملاء",
      searchCustomerDescription: "اكتب اسم العميل أو الرقم",
      selectionDescription:
        "يمكنك نقل العميل المطلوب عن طريق تحديد أو استخدام طريقة السحب والإفلات.",
      notFoundCustomer: "لم يتم العثور على العميل (العملاء) الذي تبحث عنه.",

      selectAllButtonTitle: "حدد كل العملاء",
      unselectAllButtonTitle: "إزالة كافة العملاء",
      deleteAllButtonTitle: "احذف كل شيء",

      alertBoldText: "تم اختيار جميع عملائك بنجاح!",

      createButtonTitle: "خلق",
      cancelButtonTitle: "يلغي",

      successToast: "تم إنشاء المجموعة الجديدة بنجاح.",
      emptyGroupName: "لا يمكن أن يكون اسم المجموعة فارغًا.",

      edit: {
        editGroupTitle: "تحديث المجموعة",
        editButtonTitle: "تحديث",
        deleteGroupButtonTitle: "حذف المجموعة",

        updateSuccessToast: "تم تحديث المجموعة بنجاح.",
        deleteSuccessToast: "تم حذف المجموعة بنجاح.",
      },
    },

    successToast: "تم ارسال رسالتك بنجاح.",
    connectionWarnToast: "الجهاز غير نشط، يرجى تحديثه!",
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/settings": {
    updateButtonTitle: "إعدادات التحديث",
    customerSwitchTitle: "عميل",
    staffSwitchTitle: "عامل",
    onSwitchTitle: "على",
    offSwitchTitle: "قبالة",


    padiContent: "المحتوى المدفوع غير نشط. يرجى الاتصال بممثل العميل الخاص بك.",

    reminder: {
      title: "منبه مواعيد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال رسائل SMS حول تذكيرات المواعيد.",
    },

    longTermReminder: {
      title: "تذكير موعد طويل الأمد",
      description:
        "إذا كان هناك أكثر من يوم واحد على تاريخ الموعد ، فهذا هو الإعداد لإرسال / عدم إرسال رسالة SMS قبل يوم واحد من تاريخ الموعد.",
    },

    requestInfo: {
      title: "معلومات طلب التعيين",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند تلقي طلب / طلب موعد جديد عبر الإنترنت.",
    },

    whenApproved: {
      title: "عند الموافقة على طلب الموعد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند الموافقة على طلب / طلب الموعد الوارد عبر الإنترنت.",
    },

    whenRejected: {
      title: "عندما يتم رفض طلب موعد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند رفض طلب / طلب موعد وارد عبر الإنترنت.",
    },

    whenCreated: {
      title: "عندما يتم إنشاء موعد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة أثناء إنشاء الموعد.",
    },

    whenCancelled: {
      title: "في إلغاء الموعد النشط",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند إلغاء الموعد الذي تم إنشاؤه.",
    },

    whenBirthday: {
      title: "إرسال عيد ميلاد",
      description:
        "إنه الإعداد لإرسال أو عدم إرسال رسائل تهنئة إلى عملائك الذين لديهم عيد ميلاد. يعمل هذا الإعداد إذا كان لديك اسم مرسل مخصص لعملك / لنفسك.",
    },

    whenCustomerCreate: {
      title: "عندما يضاف الزبون",
      description:
        "بعد تسجيل العميل ، يكون الإعداد لإرسال أو عدم إرسال إشعار SMS إلى عميلك يفيد بأن نشاطك التجاري قد تم تسجيله في قائمة العملاء.",
    },

    dragAndDrop: {
      title: "سحب وإسقاط الموعد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة إلى العميل عندما يتم سحب وإفلات الموعد.",
    },

    smsShortcode: {
      title: "رمز قصير SMS",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند إضافة رقم العميل إلى القائمة السوداء.",
    },

    smsApprovalLink: {
      title: "إرسال رابط حضور المواعيد عبر الرسائل القصيرة",
      description:
        "يتم إرسال ارتباط لمعرفة ما إذا كنت تريد حضور الموعد الذي أنشأه العميل أم لا. هذا الرابط هو إعداد للعميل لاختيار ما إذا كان سيحضر الموعد بشكل إيجابي أو سلبي.",
    },

    reminderNoncomerCustomer: {
      title: "تذكير للعميل الذي لم يحضر الموعد",
      description: [
        "تذكير تلقائي لعملائك الذين لم يزوروا عملك لمدة 60 يومًا",
        "تم إرسال الرسائل القصيرة.",
        "عينة من محتوى الرسالة: عزيزي Figen Çelik ، لقد اشتقنا إليك كثيرًا!",
        "لاحظنا أنك لم تجرِ أي معاملات لمدة 60 يومًا. نتمنى ان نراكم مجددا نتمنى لكم يوما سعيدا ..",
        "لتحديد موعد : https://app.salonrandevu.com/isletme/2037",
      ],
    },

    appointmentUpdate: {
      title: "تحديث الموعد",
      description:
        "إنه الإعداد لإرسال / إرسال وقت الموعد المحدث والتاريخ عبر الرسائل القصيرة.",
    },

    smsAttendedApproval: {
      title: "إرسال رابط حضور المواعيد عبر الرسائل القصيرة",
      description:
        "يتم إرسال ارتباط لمعرفة ما إذا كنت تريد حضور الموعد الذي أنشأه العميل أم لا. هذا الرابط هو إعداد للعميل لاختيار ما إذا كان سيحضر الموعد بشكل إيجابي أو سلبي.",
    },

    afterAppointment: {
      title: "تقييم ما بعد التعيين",
      description:
        "إنه الإعداد لإرسال / عدم إرسال رسائل التقييم القصيرة بعد الموعد.",
    },

    reminderHour: {
      title: "موعد التسليم تذكير موعد",
      description:
        "إنه تحديد عدد الساعات قبل إرسال الرسائل القصيرة قبل الموعد.",

      inputLabel: "كم ساعة قبل أن يتم إرسالها؟",
      options: ["مغلقة", "1 ساعة", "2 ساعة", "3 ساعة", "4 ساعة"],
    },

    updateToast: "تم تحديث إعدادات الرسائل القصيرة بنجاح.",
    saveSMSSettingsErrorToast: "تذكيرات المواعيد الطويلة ترسل رسالة نصية قصيرة قبل 24 ساعة. يرجى تحديث وقت التذكير الخاص بك أو تعطيل الموعد الطويل.",
    routeToSmsLogs: "انتقل إلى سجلات معاملات SMS",
  },

  "sms/wp/settings": {
    updateButtonTitle: "إعدادات التحديث",
    customerSwitchTitle: "عميل",
    staffSwitchTitle: "عامل",
    onOffSwitchTitle: "على قبالة",

    padiContent: "المحتوى المدفوع غير نشط. يرجى الاتصال بممثل العميل الخاص بك.",

    reminder: {
      title: "منبه مواعيد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال رسائل SMS حول تذكيرات المواعيد.",
    },

    longTermReminder: {
      title: "تذكير موعد طويل الأمد",
      description:
        "إذا كان هناك أكثر من يوم واحد على تاريخ الموعد ، فهذا هو الإعداد لإرسال / عدم إرسال رسالة SMS قبل يوم واحد من تاريخ الموعد.",
    },

    requestInfo: {
      title: "معلومات طلب التعيين",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند تلقي طلب / طلب موعد جديد عبر الإنترنت.",
    },

    whenApproved: {
      title: "عند الموافقة على طلب الموعد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند الموافقة على طلب / طلب الموعد الوارد عبر الإنترنت.",
    },

    whenRejected: {
      title: "عندما يتم رفض طلب موعد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند رفض طلب / طلب موعد وارد عبر الإنترنت.",
    },

    whenCreated: {
      title: "عندما يتم إنشاء موعد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة أثناء إنشاء الموعد.",
    },

    whenCancelled: {
      title: "في إلغاء الموعد النشط",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند إلغاء الموعد الذي تم إنشاؤه.",
    },

    whenBirthday: {
      title: "إرسال عيد ميلاد",
      description:
        "إنه الإعداد لإرسال أو عدم إرسال رسائل تهنئة إلى عملائك الذين لديهم عيد ميلاد. يعمل هذا الإعداد إذا كان لديك اسم مرسل مخصص لعملك / لنفسك.",
    },

    whenCustomerCreate: {
      title: "عندما يضاف الزبون",
      description:
        "بعد تسجيل العميل ، يكون الإعداد لإرسال أو عدم إرسال إشعار SMS إلى عميلك يفيد بأن نشاطك التجاري قد تم تسجيله في قائمة العملاء.",
    },

    dragAndDrop: {
      title: "سحب وإسقاط الموعد",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة إلى العميل عندما يتم سحب وإفلات الموعد.",
    },

    smsShortcode: {
      title: "رمز قصير SMS",
      description:
        "إنه الإعداد لإرسال / عدم إرسال الرسائل القصيرة عند إضافة رقم العميل إلى القائمة السوداء.",
    },

    smsApprovalLink: {
      title: "إرسال رابط لحضور المواعيد عبر WhatsApp",
      description:
        "يتم إرسال ارتباط لمعرفة ما إذا كنت تريد حضور الموعد الذي أنشأه العميل أم لا. هذا الرابط هو إعداد للعميل لاختيار ما إذا كان سيحضر الموعد بشكل إيجابي أو سلبي.",
    },

    reminderNoncomerCustomer: {
      title: "تذكير للعميل الذي لم يحضر الموعد",
      description: [
        "تذكير تلقائي لعملائك الذين لم يزوروا عملك لمدة 60 يومًا",
        "تم إرسال الرسائل القصيرة.",
        "عينة من محتوى الرسالة: عزيزي Figen Çelik ، لقد اشتقنا إليك كثيرًا!",
        "لاحظنا أنك لم تجرِ أي معاملات لمدة 60 يومًا. نتمنى ان نراكم مجددا نتمنى لكم يوما سعيدا ..",
        "لتحديد موعد : https://app.salonrandevu.com/isletme/2037",
      ],
    },

    appointmentUpdate: {
      title: "تحديث الموعد",
      description:
        "إنه الإعداد لإرسال / إرسال وقت الموعد المحدث والتاريخ عبر WhatsApp.",
    },

    smsAttendedApproval: {
      title: "إرسال رابط لحضور المواعيد عبر WhatsApp",
      description:
        "يتم إرسال ارتباط لمعرفة ما إذا كنت تريد حضور الموعد الذي أنشأه العميل أم لا. هذا الرابط هو إعداد للعميل لاختيار ما إذا كان سيحضر الموعد بشكل إيجابي أو سلبي.",
    },

    afterAppointment: {
      title: "تقييم ما بعد التعيين",
      description:
        "إنه الإعداد لإرسال / عدم إرسال رسائل التقييم القصيرة بعد الموعد.",
    },

    reminderHour: {
      title: "موعد التسليم تذكير موعد",
      description:
        "إنه تحديد عدد الساعات قبل إرسال الرسائل القصيرة قبل الموعد.",

      inputLabel: "كم ساعة قبل أن يتم إرسالها؟",
      options: ["مغلقة", "1 ساعة", "2 ساعة", "3 ساعة", "4 ساعة"],
    },

    updateToast: "تم تحديث إعدادات الرسائل القصيرة بنجاح.",
    loginWPDeviceSuccessToast: "تم توصيل الجهاز بنجاح.",
    logoutWPSuccessToast: "تم تسجيل الخروج من جهاز WhatsApp.",
    infoCodeText: "سيتم إرسال رمز تسجيل الدخول إلى WhatsApp إلى الرقم المدخل.",
  },

  "sms/template": {
    createTemplateButtonTitle: "إنشاء قالب جديد",

    templatesHeadings: {
      name: "اسم",
      content: "محتويات",
    },

    deleteButtonTitle: "حذف القالب",

    templateDialog: {
      addTitle: "قالب جديد",
      updateTitle: "تفاصيل القالب / تحرير",

      templateTitleInputLabel: "اسم القالب",
      templateTitleInputPlaceholder: "أدخل اسم النموذج",

      templateContentInputLabel: "محتوى النموذج",

      createButtonTitle: "خلق",
      updateButtonTitle: "تحديث",
      discardButtonTitle: "يلغي",
    },

    deleteConfirm: {
      title: "هل تؤكد حذف النموذج؟",
      confirmButtonTitle: "أجل",
      discardButtonTitle: "لا",
      alertBoldText: "لا يمكن التراجع عن هذا الإجراء!",
      alertText:
        "عند إزالة رقم الهاتف هذا من القائمة السوداء ، سيتم إرسال رسائل SMS إلى الشخص عند إرسال رسائل مجمعة.",
    },

    addSuccessToast: "تم إنشاء النموذج بنجاح.",
    updateSuccessToast: "تم تحديث القالب بنجاح.",
    deleteSuccessToast: "تم حذف القالب بنجاح.",

    tableButtonWarnToast: "يرجى ملء الحقول المطلوبة.",
  },

  "sms/blacklist": {
    totalNumber: "العدد الإجمالي للأرقام: ",
    blacklistHeadings: {
      number: "رقم",
      addDate: "تاريخ الرفع",
      disableOnlineBooking: "حالة الموعد الإلكتروني",
    },
    deleteUserBlacklist: "إزالة الرقم من القائمة",
    numberInputLabel: "أدخل الأرقام التي تريد حظرها",
    searchNumberInputLabel: "أدخل الرقم الذي تريد البحث عنه",
    addToBlacklist: "أضف إلى القائمة السوداء",
    addToBlacklistError:
      "الرجاء إدخال رقم صالح استخدام '،' لإضافة أرقام متعددة",
    addSuccessToast: "تم إضافة الرقم بنجاح.",
    updateSuccessToast: "تم تحديث الرقم بنجاح.",
    deleteConfirm: {
      title: "هل تؤكد حذف الرقم من القائمة؟",
      // alertText:"إذا قمت بحذف هذا الرقم ، فلن يكون بإمكانك الوصول إليه بعد الآن.",
    },
  },

  /**
   * Muhasebe Yönetimindeki ortak kullanımda olan key.
   * https://app.salonrandevu.com/accounting/income
   * https://app.salonrandevu.com/accounting/debt
   */
  accounting: {
    showOnReceipt: "إظهار في تذكرة",

    startInputLabel: "تاريخ البدء",
    endInputLabel: "تاريخ الانتهاء",
    typeInputLabel: "طريقة الدفع او السداد",
    customerName: "اسم الزبون",

    typeCustomer: "كل العملاء",
    typeALL: "كل الطرق",
    typeCASH: "نقدي",
    typeCARD: "بطاقة الائتمان / الخصم",
    typeEFT: "التحويل البنكي / التحويل الإلكتروني",

    totalIncome: "إجمالي الإيرادات",
    totalDebt: "الدين الكلي",

    alertBoldText: "يمكنك تصفية {desc} باستخدام الحقول أدناه.",
    alertText:
      "إذا قمت بإجراء تغييرات في الحقول ، فسيتم إجراء التصفية تلقائيًا.",

    today: "اليوم",
    thisWeek: "هذا الاسبوع",
    thisMonth: "هذا الشهر",

    incomeMethods: "المعاملات الايرادية",

    filterArea: {
      header: "حدد النطاق الزمني",
      yesterday: "أمس",
      lastSevenDays: "اخر 7 ايام",
      lastFifteenDays: "آخر 15 يومًا",
      lastThirtyDays: "آخر 30 يومًا",
      approve: "يعتمد",
      cancel: "يلغي",
    },

    noRecords: "البيانات تساوي صفرًا في النطاق الزمني الذي تحدده.",
  },

  /**
   * Gelir işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/income
   */
  "accounting/income": {
    headings: {
      createdAt: "تاريخ الدفع المحدد",
      customerFullName: "اسم الزبون",
      amount: "قيمة التحويل",
      type: "طريقة الدفع او السداد",
    },
    totalIncome: "إجمالي الدخل",
  },

  /**
   * Borç işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debt
   */
  "accounting/debt": {
    headings: {
      createdAt: "تاريخ الصفقة",
      customerFullName: "اسم الزبون",
      amount: "المجموع",
      paid: "دفع",
      remaining: "بقية",
    },
  },

  /**
   * Prim işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/bounty
   */
  "accounting/bounty": {
    payButtonTitle: "دفع قسط",
    allPayButtonTitle: "دفع الكل",
    paymentType: "يرجى اختيار نوع الدفع",
    routeReceipt: "اذهب إلى الفاتورة",
    routeError: "حدث خطأ ما",

    customerInfo: {
      name: "اسم العميل",
      paid: "المبلغ المدفوع",
      primAmount: "مبلغ العمولة",
      transactions: "العمليات",
    },

    headings: {
      staffFullName: "اسم الموظف واللقب",
      bounty: "مبلغ قسط",
    },

    check: {
      title: "دفع قسط",
      confirmButtonTitle: "قم بالدفع",
      discardButtonTitle: "يستسلم",
      alertBoldText:
        "توجد مكافأة متراكمة قدرها {bounty} للموظف {selected_employee}. يمكنك تسديد قسط التأمين عن طريق ملء تاريخ المعاملة وخيارات طريقة الدفع.",
    },
  },

  /**
   * Gider işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense
   */
  "accounting/expense": {
    add: {
      title: "إضافة مصاريف جديدة",
      dialogTitle: "مصروف جديد",
      dialogQATitle: "هل تؤكد حذف المصروف؟",
      typeInputLabel: "بند النفقات",
      amountInputLabel: "مقدار",
      amountInputPlaceholder: "أدخل مبلغ المصاريف ذات الصلة في هذا المجال.",
      descriptionInputLabel: "تفسير",
      descriptionInputHint:
        "في هذا الحقل ، يمكنك إدخال الملاحظات التي تريد إضافتها حول المعاملة ذات الصلة.",

      buttonTitle: "أضف المصاريف",
      cancelButtonTitle: "إلغاء",
      paymentType: "طريقة الدفع او السداد",

      processDate: "تاريخ الصفقة",

      spending: "الإنفاق",
      spendingPlaceholder: "أدخل إنفاقك.",
    },

    payments: {
      cash: "نقداً",
      creditCard: "بطاقة ائتمان / بطاقة مصرفية",
      transfer: "حوالة مصرفية / تحويل إلكتروني",
      cusPoint: "تقييم العميل",
    },

    table: {
      title: "معاملات المصاريف",
      headings: {
        createdAt: "تاريخ الإنشاء",
        transactionDate: "تاريخ الصفقة",
        type: "بند النفقات",
        description: "وصف المصاريف",
        amount: "مقدار",
      },
      deleteButtonTitle: "حذف المصاريف",
    },

    totalExpense: "إجمالي النفقات",
    dialogQAWarn1: "لا يمكن التراجع عن هذا الإجراء!",
    dialogQAWarn2: "سيتم حذف المصروف من القائمة.",
    insertErrorToast: "يرجى اختيار طريقة الدفع",
    deleteToast: "تم حذف سجل المصاريف بنجاح.",
    insertToast: "تم إنشاء المصروفات بنجاح.",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense/types
   */
  "accounting/expense/types": {
    alertBoldText: "حول بنود المصاريف",
    alertText:
      "في هذا الحقل ، يمكنك تحديد بنود المصروفات الخاصة بعملك وإجراء معاملات النفقات الخاصة بك على العناصر التي قمت بإنشائها.",

    saveChangesButtonTitle: "حفظ التغييرات",
    typeInputLabel: "{i}. بند المصاريف الخاصة",

    insertToast: "تم تنفيذ العملية بنجاح.",

    moreButtonTitle: "اكثر",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/credit
   */
  "accounting/credit": {
    filter: {
      title: "التاريخ والتصفية حسب العميل",
      satrtDate: "تاريخ البدء",
      endDate: "تاريخ الانتهاء",
      name: "اسم الزبون",
    },

    add: {
      customerSelection: "اختيار العميل",
      scheduledPaymentDate: "تاريخ الدفع المقرر",
      enterAmount: "أدخل المبلغ",
      explanation: "تفسير",
      saveReceivable: "حفظ المستحق",
      updateReceivable: "تحديث المستحق",
      collectPayment: "جمع الدفعات المالية",
      paymentDialogTitle: "معلومات الدفع",
      savePaymnet: "حفظ الدفع",
      closePayment: "إغلاق الدفع",

      date: "تاريخ",
      amount: "مقدار",
      paymentType: "طريقة الدفع او السداد",
      actions: "المعاملات",
      delete: "حذف",
      update: "تحديث",

      start_amount: "مبلغ البدء",
      end_amount: "المبلغ النهائي",
    },

    payments: {
      typeInputLabel: "طريقة الدفع او السداد",
      typeInputPlaceholder: "اختر وسيلة الدفع",
      paid: "تم الدفع",
      notPaid: "لم يتم الدفع",

      amountInputLabel: "أدخل المبلغ",

      typeCASH: "نقدي",
      typeCARD: "بطاقة الائتمان / الخصم",
      typeEFT: "التحويل البنكي / التحويل الإلكتروني",
      typePOINT: "نقاط العميل",
    },

    inserted: "تم إضافة الدين",
    missingInfo: "لقد أدخلت معلومات ناقصة",
    error: "حدث خطأ",
    addCredit: "إضافة رصيد",
    paid: "دفع",
    notPaid: "لم تدفع",

    headings: {
      createdAt: "تاريخ الإنشاء",
      scheduledPaymentDate: "تاريخ الدفع المقرر",
      nameSurname: "اسم اللقب",
      all_amount: "المجموع",
      paid: "دفع",
      debt_total: "بقية",
      view: "عرض التفاصيل",
      delete: "حذف",
      transactions: "المعاملات",
      is_paid: "حالة السداد",
    },
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debit
   */
  "accounting/debit": {
    filter: {
      title: "التاريخ والتصفية حسب العميل",
      satrtDate: "تاريخ البدء",
      endDate: "تاريخ الانتهاء",
      name: "اسم الزبون",
    },

    add: {
      customerSelection: "اسم الزبون",
      scheduledPaymentDate: "تاريخ الدفع المقرر",
      enterAmount: "أدخل المبلغ",
      explanation: "تفسير",
      saveReceivable: "حفظ الديون",
      updateReceivable: "تحديث الديون",
      collectPayment: "جمع الدفعات المالية",
      paymentDialogTitle: "معلومات الدفع",
      savePaymnet: "حفظ الدفع",
      closePayment: "إغلاق الدفع",

      date: "تاريخ",
      amount: "مقدار",
      paymentType: "طريقة الدفع او السداد",
      actions: "المعاملات",
      delete: "حذف",
      update: "تحديث",

      start_amount: "مبلغ البدء",
      end_amount: "المبلغ النهائي",
    },

    payments: {
      typeInputLabel: "طريقة الدفع او السداد",
      typeInputPlaceholder: "اختر وسيلة الدفع",
      paid: "تم الدفع",
      notPaid: "لم يتم الدفع",

      typeCASH: "نقدي",
      typeCARD: "بطاقة الائتمان / الخصم",
      typeEFT: "التحويل البنكي / التحويل الإلكتروني",
      typePOINT: "نقاط العميل",
    },

    addCredit: "إضافة الخصم",
    paid: "دفع",
    notPaid: "لم تدفع",

    headings: {
      createdAt: "تاريخ الإنشاء",
      scheduledPaymentDate: "تاريخ الدفع المقرر",
      nameSurname: "اسم اللقب",
      all_amount: "المجموع",
      paid: "دفع",
      debt_total: "بقية",
      view: "عرض التفاصيل",
      delete: "حذف",
      transactions: "المعاملات",
      is_paid: "حالة السداد",
    },
  },

  /**
   * Raporlar modülünün genel kullanımlarının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   * https://app.salonrandevu.com/reports/services
   * https://app.salonrandevu.com/reports/stock
   * https://app.salonrandevu.com/reports/staff
   * https://app.salonrandevu.com/reports/customer
   */
  reports: {
    startInputLabel: "تاريخ البدء",
    endInputLabel: "تاريخ الانتهاء",

    headings: {
      stockName: "اسم المنتج",
      serviceName: "اسم المنتج",
      transactionCount: "عدد المعاملات",
      soldCount: "عدد المبيعات",
      income: "الدخل المكتسب",
    },

    selectedStaffInputLabel: "اختيار الموظف",
    selectedPageCountInputLabel: "عدد الصفحات",

    loaderTitle: "يتم تحميل تقاريرك. يرجى الانتظار...",
  },

  /**
   * İşletme raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   */
  "reports/company": {
    soldItem: "البند المباع",
    soldService: "الخدمة المنفذة",

    serviceIncome: "دخل الخدمة",
    itemIncome: "إيرادات المنتج",

    generalIncome: "إجمالي الإيرادات",
    maxExpense: "المصاريف الكلية",
    netIncome: "صافي الدخل",

    typeCASH: "نقدي",
    typeCARD: "بطاقة الائتمان / الخصم",
    typeEFT: "التحويل البنكي / التحويل الإلكتروني",

    _maxAppointment: "إجمالي عدد المواعيد",
    _maxReceipt: "إجمالي عدد التذاكر",
    _serviceIncome: "الدخل من الخدمات",
    _itemIncome: "الدخل من المنتجات",
    _paidBounty: "قسط مدفوعة للموظفين",

    alertText:
      "يتم احتساب القيم المحددة كإيرادات الخدمة ودخل المنتج على الائتمانات المدفوعة بالكامل والخالية من الديون.",

    incomeReports: "تقارير الدخل",
    expensesReports: "تقارير المصروفات",
    summary: "ملخص",

    goToDetail: "انتقل إلى التفاصيل",
  },

  /**
   * Çalışan raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/staff
   */
  "reports/staff": {
    name: "اسم الموظف",
    serviceCount: "عدد الخدمات المقدمة",
    soldItemCount: "عدد المنتجات المباعة",

    onlyService: "معدل دوران الخدمة",
    bountyService: "قسط الخدمة",
    productSum: "دوران المنتج",
    bountyProduct: "قسط المنتج",
    packageSum: "دوران العبوة",
    bountyPackage: "حزمة بريميوم",

    endorsement: "إجمالي حجم التداول",
    bounty: "إجمالي المكافأة",
    survey: "تقييم",
  },

  /**
   * Müşteri raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/customer
   */
  "reports/customer": {
    name: "اسم الزبون",
    appointmentCount: "عدد المواعيد",
    appointmentStatus: "وصل / في انتظار / لم يصل",

    transactionCount: "عدد المعاملات المنجزة",
    productCount: "عدد المنتجات المباعة",
    totalEarned: "الأرباح الكلية",
  },

  /**
   * Yapılacaklar listesi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/todos
   */
  todos: {
    toastSuccess: "تمت إضافة المهمة بنجاح!",
    toastWarningMessage: "لا يمكن أن يكون محتوى المهمة فارغًا",
    toastWarningProcess: "فشلت إضافة المهمة.",

    addButtonTitle: "إنشاء مهمة",

    addTodoTitle: "إضافة مهمة",
    todoInputLabel: "محتوى المهام",
    todoInputPlaceholder:
      "في هذا المجال ، يمكنك إدخال ملاحظاتك ومهامك فيما يتعلق بعملك الذي يتعين عليك إنجازه.",

    dateTitle: "البعثات المؤرخة",
    notFoundText: "لا توجد مهام محفوظة لتاريخها.",

    statusWaiting: "انتظار",
    statusCompleted: "أنجز",

    updateToast: "تم تحديث حالة المهمة بنجاح.",
    insertToast: "تمت إضافة المهمة بنجاح.",
    deleteToast: "تم حذف المهمة بنجاح.",

    deleteTaskQuestion: "هل تريد حذف المهمة؟",
    deleteTaskDescription: "سيتم حذفه من قائمة المهام الخاصة بك.",
  },

  /**
   * Cüzdan sayfasının bulunduğu key.
   * https://app.salonrandevu.com/wallet
   */
  wallet: {
    cardTitle: "رصيدك الإجمالي",
    amountInputLabel: "مبلغ الشحن ",
    amountButtonTitle: "ميزان الحمل",

    remainingSMS: "رصيد الرسائل القصيرة المتبقي",
    licenseDetail: "العبوة المستعملة",
    licenseRemaining: "رخصة متبقية",
    appointmentRemaining: "رصيد التعيين المتبقي",
    paymentForm: "الحسابات المصرفية / نموذج إشعار الدفع",
    buy: "شراء",
    package_prices: "أسعار الحزم",
    kdv: "غير شاملة لضريبة القيمة المضافة",

    load_balance: "شحن الرصيد",

    includesAll: "مضمن في جميع التراخيص",

    see_more: "عرض المزيد",
    see_less: "عرض أقل",

    licenseHeading: "شراء ترخيص",
    smsHeading: "شراء باقة SMS",
    appointmentHeading: "احصل على رصيد موعد",
    sms_price: "أسعار باقة الرسائل القصيرة + الواتساب",
    sms_package: "حزم الرسائل القصيرة",
    wp_package: "حزم واتساب",

    licenses: "التراخيص",
    message_packages: "حزم الرسائل",
    extra_license: "حزم إضافية",

    bardcode_extra_package: "قارئ الباركود",
    whatsapp_extra_package: "رسائل WhatsApp الفورية",
    online_link_extra_package: "صفحة حجز المواعيد",

    bardcode_extra_package_tooltip:
      "أثناء تتبع المخزون، يمكنك إضافة المنتجات عن طريق مسح رموز الباركود الخاصة بها. باستخدام تطبيقنا، يمكنك بسهولة مسح رموز الباركود باستخدام كاميرا هاتفك المحمول وإجراء عمليات البيع.",
    whatsapp_extra_package_tooltip:
      "يتم إنشاء اتصال فوري مع حسابك على WhatsApp. يمكنك إرسال الرسائل بالنقر على أيقونة WhatsApp إذا كنت تستخدم الإصدار عبر الويب أو التواصل بسرعة من خلال تطبيق WhatsApp إذا كنت تستخدم الإصدار المحمول.",
    online_link_extra_package_tooltip:
      "يقوم هذا القسم بتنشيط الرابط اللازم لعملائك لإنشاء طلبات حجز لعملك. من خلال هذا الرابط، يمكن لعملائك إنشاء طلبات حجز على مدار الساعة، محددين التاريخ والوقت ونوع الخدمة.",
    withEFT: "عن طريق النقل",
    buyButtonTitle: "اشتري",
    monthly_payment: "الدفع الشهري",
    yearly_payment: "ادفع سنوياً",
    monthly: "شهرياً",

    check: {
      title: "هل تؤكد الشراء؟",
      confirmButtonTitle: "يوافق",
      discardButtonTitle: "يستسلم",

      alertText:
        "هل توافق على شراء {package_name} مقابل {credit_card_amount} برصيدك الحالي؟",
      packageChangeAlert:
        "سيتم حساب الأيام الـ {remaining_day} من حزمة {active_package} وفقًا لسعر الوحدة وإضافتها إلى حزمة {new_package} على أنها {formula} أيام.",
    },

    paymentType: {
      title: "الرجاء تحديد طريقة الدفع",
      creditCard: "بطاقة إئتمان",
      eft: "التحويل البنكي / التحويل الإلكتروني",
    },

    balanceErrorToast: "الرجاء إدخال المبلغ ليتم تحميله!",
    balanceAddWarnToast: "لم يتمكن من إتمام المعاملة!",

    packages: {
      silver: "باقة فضية",
      platinium: "باقة بلاتينية",
      gold: "باقة ذهبية",
      diamond: "باقة ماسية",
    },

    packagesName: {
      silver: "فضية",
      platinium: "بلاتينية",
      gold: "ذهبية",
      diamond: "ماسية",
    },

    count: "%s قطعة",
  },

  "platform/photo": {
    photoUploadToast: "بدأ تحميل صورتك.",
    insertToast: "اكتمل تحميل الصورة بنجاح.",
    deleteToast: "تم حذف الصورة بنجاح.",
    anexample: "مثال مظاهرة",
    uploadPhotoAlertTitle: "تحميل صورة العرض",
    selectPhotoTitle: "اختر صورة",

    photoUploadErrorToast: "فشلت إضافة الصور.",
    deletePhotoErrorToast: "لا يمكن حذف الصورة.",

    photoInfo: "يمثل الصورة التي سيتم عرضها على شاشات البحث والقائمة.",
  },

  "platform/settings": {
    offOn: "مغلق يعمل",
    chooseColor: "اختر اللون",
    platformVisibleTitle: "رؤية الأعمال",
    platformVisibleDescription:
      "هذا هو الإعداد لظهور / عدم ظهور نشاطك التجاري في عمليات بحث العملاء على منصة مواعيد الصالون. عند إيقاف تشغيل هذا الإعداد ، لن يظهر عملك في عمليات البحث على منصة مواعيد الصالون.",

    platformPriceTitle: "رؤية رسوم الخدمة",
    platformPriceDescription:
      "إنه إعداد لإخفاء رسوم الخدمة الخاصة بك على salonandevu.com وصفحة حجز المواعيد الخاصة بنشاطك التجاري. عند إيقاف هذا الإعداد، لن تظهر تكلفة خدماتك في أي من المنطقتين.",
    platformCommentTitle: "رؤية آراء العملاء",
    platformCommentDescription:
      "إنه إعداد لإخفاء التقييمات التي تم إجراؤها حول عملك على salonandevu.com وصفحة حجز المواعيد المحددة لشركتك. عند إيقاف هذا الإعداد، لن تظهر أي تعليقات للعملاء حول نشاطك التجاري في أي من المنطقتين.",
    platfromAppointmentTitle: "تأكيد الموعد التلقائي",
    platfromAppointmentDescription:
      "إنه إعداد التأكيد التلقائي لطلبات المواعيد الخاصة بك. عند تشغيل هذا الإعداد، تتم الموافقة تلقائيًا على طلبات المواعيد التي تتلقاها.",
    platformColorSelectionTitle: "تعديل لون المنصة",
    platformColorSelectionDescription:
      "يمكنك تغيير لون صفحة حجز المواعيد المحددة لشركتك من هذه القائمة.",
    platformVideoURLTitle: "عنوان URL لمنصة الفيديو",
    platformVideoURLDescription:
      "سيتم عرض عنوان URL لفيديو YouTube الذي تدخله للزائرين في صفحة حجز المواعيد المحددة لشركتك.",

    onlineAppointmentTitle: 'إعداد تعارض طلب التعيين',
    onlineAppointmentDesc: "عند تنشيط هذا الإعداد، يمكنك تلقي طلبات مواعيد متعددة لنفس الموظف والوقت. إذا تركته غير نشط، وإذا كان لديك طلب تعيين لنفس الموظف والوقت، فلن يتم تقديم أي طلب آخر لذلك الموظف والوقت.",

    platformVideoURLInputLabel: "رابط الفيديو",
    platformVideoURLInputPlaceholder: "أدخل عنوان URL للفيديو",
    appointmentVerificationCode: "رمز تأكيد الموعد",
    appointmentVerificationCodeDescription:
      "إنه إعداد رمز التحقق للمواعيد التي يتم إجراؤها من صفحة حجز المواعيد المحددة لشركتك. عند تحديد هذا الإعداد على تشغيل، سيتم إرسال رمز التحقق عبر الرسائل القصيرة / الواتس اب من صفحة الموعد الخاص بك للتحقق وتأكيد أرقام عملائك، حتى لو كانت أرقامهم مسجلة في النظام.",
    appointmentVerificationCodeDeliveryType: "نوع إرسال رمز تأكيد الموعد",
    appointmentVerificationCodeDeliveryTypeDescription:
      "يُستخدم هذا الإعداد لإرسال رموز التحقق للمواعيد التي تم إجراؤها في صفحة حجز المواعيد المحددة لشركتك. من خلال طريقة التحقق التي تختارها، يتم تحديد طريقة إرسال رمز التحقق الذي سيتم إرساله إلى عميلك من صفحة الموعد الخاص بك.",
    colorDialogTitle: "اختيار اللون",
    colorDialogCancelTitle: "إلغاء اللون",
    colorDialogSelectTitle: "حسنا",

    updateSettingsSuccessToast: "تم تحديث إعدادات المنصة بنجاح.",
    updateSettingsWarnToast: "لم يتمكن من تحديث إعدادات المنصة.",
    updateSettingsButtonTitle: "تحديث الإعدادات",
  },

  "platform/categories": {
    howto: {
      title: "كيف يصنع؟",
      content: [
        "على اليسار توجد الخدمات التي قمت بإنشائها (خدماتك الحالية) ، وعلى اليمين توجد الخدمات التي ستظهر على المنصة.",
        "حدد الخدمات التي تريد إضافتها إلى المنصة.",
        "قم بتوسيع الفئة التي ستظهر تحتها الخدمات المحددة على المنصة.",
        "إذا كانت الخدمات التي اخترتها تتطابق مع الخدمات الموجودة ضمن الفئة التي قمت بتوسيعها ، فحدد تلك الخدمة وانقر فوق الزر 'نقل'.",
      ],
    },
    knowrequired: {
      title: "ما تحتاج إلى معرفته",
      content: [
        "يمكنك تحديد ونقل خدمات متعددة في نفس الوقت.",
        "لن تظهر خدماتك التي قمت بترحيلها إلى النظام الأساسي بعد الآن في علامة التبويب الخدمات الحالية الخاصة بك.",
        "لا يمكنك تعيين خدمة لأكثر من اسم خدمة فئة.",
        "إذا كانت الخدمات التي اخترتها تتطابق مع الخدمات الموجودة ضمن الفئة التي قمت بتوسيعها ، فحدد تلك الخدمة وانقر فوق الزر 'نقل'.",
        "يمكنك استخدام علامة التقاطع الموجودة بجوار اسم الخدمة لإزالة الخدمات التي لم تتطابق مع علامة التبويب الخدمات الحالية الخاصة بك.",
        "عند إضافة خدمة جديدة ، تمت أيضًا إضافة اختيار فئة الخدمة للنظام الأساسي إلى صفحة 'إضافة خدمة جديدة'.",
      ],
      label1: "إذا كانت الخدمة أو الخدمات التي أنشأتها لا تتطابق مع الفئات أو المعاملات على المنصة",
      label2: "iletisim@salonrandevu.com",
      label3: "يمكنك إبلاغ طلبك عبر البريد الإلكتروني إلى",
    },
    alertTitle: "لعرض خدماتك على منصة حجز صالون، يجب عليك مطابقة الخدمات التي أنشأتها مع خدمات المنصة.",
    serviceTitle: "خدماتك الحالية",
    transferButtonTitle: "نقل",
    categoriesTitle: "الفئات",
  },

  /**
   * İşletme Ayarları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings
   */
  settings: {
    idInputLabel: "كود القاعة",
    nameInputLabel: "اسم القاعة",
    mailInputLabel: "عنوان البريد الإلكتروني للقاعة",
    phoneInputLabel: "رقم هاتف القاعة",
    aboutCompanyLabel: "حول الأعمال",

    currencyType: "العملة الافتراضية",
    countryInputLabel: "اختيار البلد",
    cityInputLabel: "انتخابات المحافظات",
    districtInputLabel: "اختيار المنطقة",
    addressInputLabel: "افتح العنوان",
    mapsInputLabel: "إحداثيات خرائط Google للأعمال",
    mapsInputHint: "على سبيل المثال: 41.009336 ، 28.660129",
    currentLocationButtonTitle: "استخدام موقعي الفوري",

    instagramInputLabel: "اسم مستخدم Instagram",
    facebookInputLabel: "اسم مستخدم صفحة Facebook",
    websiteInputLabel: "عنوان URL لصفحة الويب",
    withoutAtHint: "أدخل بدون علامة @",

    priceInputLabel: "إعداد معلومات السعر",
    priceInputHint:
      "إنه الإعداد لإظهار معلومات السعر للمعاملة المحددة أم لا إلى عملائك الذين سيطلبون موعدًا من خلال عنوان URL لنشاطك التجاري.",

    commentsInputLabel: "إعداد آراء العملاء",
    commentsInputHint:
      "لتعيين ما إذا كنت تريد إظهار تعليقات عملائك على الاستطلاعات في عنوان URL الخاص بشركتك أم لا.",
    systemLanguage: "يمكن ضبط تغيير لغة النظام على اللغة الإنجليزية / التركية.",
    inputOn: "يفتح",
    inputOff: "مغلقة",

    urlInputLabel: "عنوان URL لموعد العمل",
    openOnBrowserButtonTitle: "افتح في المتصفح",
    copyLinkButtonTitle: "انسخ الرابط",
    copyLinkToast: "تم نسخ الرابط بنجاح.",
    updateButtonTitle: "تحديث المعلومات",

    geolocationErrorToast: "متصفحك لا يدعم تحديد الموقع الجغرافي.",
    locationErrorToast:
      "تعذر الحصول على موقعك ، يرجى المحاولة مرة أخرى في وقت لاحق.",
    updateToast: "تم تحديث المعلومات بنجاح.",

    currentPassword: "كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديدة",
    newPasswordAgain: "تأكيد كلمة المرور الجديدة",
    updateInfo: "تحديث المعلومات",

    uploadPhotoWarnToast: "يتم إعداد الشعار...",
    uploadPhotoSuccessToast: "تم تحميل الشعار بنجاح.",

    steps: [
      "الخطوة 1",
      "الخطوة 2",
      "الخطوة 3",
      "الخطوة 4",
    ],
    stepBack: "عودة",
    stepNext: "التالي",
    shortURL: "رابط حجز الأعمال المختصر",
    shortURLWarnToast: "التقصير غير محدد. يرجى الاتصال بممثلينك.",
    countryCode: "رمز البلد",
    dialCode: "رمز الاتصال",

    noLogoTitle: "لم يتم العثور على شعار لهذه الشركة.",
    updateLogo: "تحديث الشعار",
    setLogo: "انقر هنا لتحميل شعار لهذه الشركة.",
    settingsAlertText: "أنت على وشك إجراء تغييرات على وقت الموعد / البداية / النهاية / التكرار!! ستعود جميع الساعات المغلقة في ملفات موظفيك إلى الوضع الافتراضي، وسيتعين عليك إعادة تنظيمها. هل تؤكد؟",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/hours
   */
  "settings/hours": {
    businessCategory: "فئة الشركة",
    startInputLabel: "وقت بدء العمل",
    endInputLabel: "وقت انتهاء العمل",
    periodBetweenInputLabel: "تردد الموعد (دقيقة)",
    startInputLabelHour: "وقت البدء",
    startInputLabelMinute: "من الدقائق",
    intervalInputLabel: "الفترة (دقائق)",

    endInputLabelHour: "وقت الانتهاء",
    endInputLabelMinute: "من الدقائق",

    updateButtonTitle: "تحديث المعلومات",

    updateToast: "تم تحديث المعلومات بنجاح.",
    compareErrorToast:
      "لا يمكن أن يكون حقل وقت الانتهاء أصغر من حقل وقت البدء.",
  },

  /**
   * İşletme Galerisi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/gallery
   */
  "settings/gallery": {
    numberPhoto: "إجمالي عدد الصور التي يمكنك تحميلها",
    uploadButtonTitle: "انقر فوق هذه المنطقة لتحميل الصورة.",
    anexample: "عرض مثال",
    nextPhoto: "الصورة التالية",
    uploadPhotoButtonTitle: "رفع",

    checkAllButtonTitle: "حدد كافة الصور",
    deleteSelectedButtonTitle: "حذف الصور المحددة ({count})",

    delete: {
      // Modal
      title: "هل تؤكد الحذف؟",
      confirmButtonTitle: "يوافق",
      discardButtonTitle: "يستسلم",

      alertBoldText: "هل توافق على هذا العمل؟",
      alertText:
        "إذا قمت بإجراء عملية الحذف ، فستتم إزالة الصور المحذوفة من جميع الصفحات المرتبطة.",
    },

    photoUploadToast: "بدأ تحميل صورتك.",
    insertToast: "اكتمل تحميل الصورة بنجاح.",
    deleteToast: "تم حذف الصورة بنجاح.",

    photoMorePictureError: "لا يمكن تحميل أكثر من 8 صور.",

    enterprise_gallery_info:
      "يمكنك إضافة صور مكان عملك من هذه المنطقة. سيتم عرض هذه الصور على منصة مواعيد الصالون وصفحة عملك.",
  },

  "settings/service-gallery": {
    numberPhoto: "إجمالي عدد الصور التي يمكنك تحميلها",
    uploadButtonTitle: "انقر فوق هذه المنطقة لتحميل الصورة.",
    anexample: "عرض مثال",

    photoDelete: "حدد الصورة المراد حذفها",

    checkAllButtonTitle: "حدد كافة الصور",
    deleteSelectedButtonTitle: "حذف الصور المحددة ({count})",

    delete: {
      // Modal
      title: "هل تؤكد الحذف؟",
      confirmButtonTitle: "يوافق",
      discardButtonTitle: "يستسلم",

      alertBoldText: "هل توافق على هذا العمل؟",
      alertText:
        "إذا قمت بإجراء عملية الحذف ، فستتم إزالة الصور المحذوفة من جميع الصفحات المرتبطة.",
    },

    photoUploadToast: "بدأ تحميل صورتك.",
    insertToast: "اكتمل تحميل الصورة بنجاح.",
    deleteToast: "تم حذف الصورة بنجاح.",

    photoMoreUploadError: "لا يمكن تحميل أكثر من 8 صور.",

    service_photo_info:
      "يمكنك إضافة صور متعلقة بالخدمات التي تقدمها في هذه المنطقة ، وسيتم عرض هذه الصور على منصة مواعيد الصالون وصفحة عملك.",
  },

  /**
   * Sistem Logları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/logs
   */
  "settings/logs": {
    headings: {
      createdAt: "تاريخ الصفقة",
      message: "عملية الوصف",
      detail: "تفاصيل الصفقة",
      ip: "عنوان IP",
    },

    categoryList: {
      title: "فئات السجلات",
      all: "جميع السجلات",
      cus: "سجلات العملاء",
      rec: "سجلات الإيصالات",
      staf: "سجلات الموظفين",
      appo: "سجلات المواعيد",
      sms: "سجلات إعدادات SMS"
    },
  },

  /**
   * 404 sayfasının bulunduğu key.
   * https://app.salonrandevu.com/404
   */
  notFound: {
    heroHeadingText: "عذرا ، لم نتمكن من العثور عليه!",
    boldText:
      "معذرةً ، لم نتمكن من العثور على الصفحة التي تبحث عنها أو تحاول الانتقال إليها.",
    text: "ربما تم حذفه أو نقله أو عدم إنشائه.",
  },

  care: {
    careTitle: "نحن في صيانة...",
    careLabel1: "لتقديم خدمة أفضل لكم,",
    careLabel2: "سيتم إجراء الصيانة بين %s و %v.",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/agreement/index
   */

  "settings/agreements": {
    agreementTemplates: "قوالب الاتفاق",
    createNewAgreement: "إنشاء اتفاق جديد",
    editAgreement: "تحرير الاتفاق",
    agreementClauses: "أحكام الاتفاق",
    agreements: "اتفاقات",

    delete: "حذف",
    edit: "تحرير",
    close: "إغلاق",

    yesOrNo: "نعم / لا",

    createTamplateArea: {
      personalInformation: "معلومات شخصية",
      paymentInformation: "معلومات الدفع",
      clausesInformation: "معلومات البنود",
      label: "قالب جديد",
      placeHolder: "قالب اتفاق جديد",
      saveTamplate: "حفظ القالب",
      editTamplate: "تحرير القالب",
      cancel: "إلغاء",
      placeHolders: "معلومات الشركة",
    },

    personal_info: {
      name_and_surname: "الاسم واللقب",
      phone: "رقم الهاتف",
      birth_date: "تاريخ الميلاد",
      job: "الوظيفة",
      id_number: "الرقم الوطني",
      tax_number: "الرقم الضريبي",
      address: "العنوان",
    },
    payment_info: {
      staff_name: "اسم الموظف",
      service: "الخدمة",
      total_amount: "المبلغ الإجمالي",
    },

    createClausesArea: {
      createNewClauses: "إنشاء بند جديد",
      title: "عنوان البند",
      context: "محتوى البند",
      yesOrNo: "إضافة استفسار نعم / لا",
      update: "تحديث",
      save: "حفظ",
      cancel: "إلغاء",
      fillAreaComplatelty: "يرجى ملء جميع الحقول بشكل كامل.",
    },

    createPdf: {
      companyNamePlaceHolder: "اسم الشركة",
      agreementTitle: "عنوان الاتفاق",
    },

    agreementsList: {
      date: "التاريخ",
      nameSurname: "الاسم واللقب",
      agreementName: "اسم الاتفاق",
      size: "الحجم",
      actions: "الإجراءات",
      startDate: "تاريخ البدء",
      endDate: "تاريخ الانتهاء",
      searchCustomer: "البحث عن العميل",
    },

    agreementRequstFail:
      "حدث خطأ غير متوقع. يرجى الاتصال بممثل العميل الخاص بك.",

    agreementClausesSavedSucces: "تم حفظ بند الاتفاق بنجاح.",
    agreementClausesEditSucces: "تم تحرير بند الاتفاق بنجاح.",
    agreementClausesDeleteSucces: "تم حذف بند الاتفاق بنجاح.",

    agreementSaveSucces: "تم حفظ قالب الاتفاق بنجاح.",
    agreementEditSucces: "تم تحرير قالب الاتفاق بنجاح.",
    agreementDeleteSucces: "تم حذف قالب الاتفاق بنجاح.",

    deleteClausesModal: {
      title: "هل ترغب في حذف هذا البند من الاتفاق؟",
      delete: "حذف",
      cancel: "إلغاء",
      expTitle: "لا يمكن التراجع عن هذا الإجراء!",
      expDetail: "سيتم حذف البند بشكل دائم.",
    },

    deleteAgreementModal: {
      title: "هل ترغب في حذف هذا الاتفاق؟",
      delete: "حذف",
      cancel: "إلغاء",
      expTitle: "لا يمكن التراجع عن هذا الإجراء!",
      expDetail: "سيتم حذف الاتفاق بشكل دائم.",
    },

    agreement: {
      yes: "نعم",
      no: "لا",
      signiture: "التوقيع",
    },

    smsAproove: {
      title: "هل ترغب في إرسال الاتفاق المسجل عبر الرسائل النصية؟",
      send: "إرسال",
      cancel: "إلغاء",
      textFirst: "هل أنت متأكد من رغبتك في إرسال الاتفاق إلى",
      textSecond: "عبر الرسائل النصية؟",
    },

    wpAproove: {
      title: "هل ترغب في إرسال الاتفاق المسجل عبر WhatsApp؟",
      send: "إرسال",
      cancel: "إلغاء",
      textFirst: "هل أنت متأكد من رغبتك في إرسال الاتفاق إلى",
      textSecond: "عبر WhatsApp؟",
    },

    deleteAgreement: {
      title: "هل ترغب حقًا في حذف هذا الاتفاق؟",
      aproove: "تأكيد وحذف",
      cancel: "إلغاء",
      infoTitle: "سيتم حذف الاتفاق بشكل دائم.",
      infoContext:
        "لا يمكن التراجع عن هذا الإجراء. سيتم حذف الاتفاق بشكل دائم.",
    },
  },

  /**
   * Paket yükselt uyarısının bulunduğu key.
   */
  planUpgrade: {
    heroHeadingText: "ترقية الحزمة الخاصة بك!",
    boldText: " لاستخدام هذه الوحدة ، تحتاج إلى ترقية الحزمة.",
    text: "إذا كنت تعتقد أن هناك مشكلة ، يمكنك الوصول إلينا من خلال قنوات الاتصال الخاصة بنا.",
    showPackagesButtonTitle: "مشاهدة الحزم",
    callUsButtonTitle: "اتصل بنا - 0 (212) 706 0510",
  },

  /**
   * İşletme URL'si sayfasının bulunduğu key.
   * https://app.salonrandevu.com/isletme/22
   */
  businessURL: {
    callButtonTitle: "اتصل الآن (0 {phone})",
    instagramHeading: "اتبع على Instagram!",
    instagramText: "@ {username} على Instagram",

    facebookHeading: "تابعنا على Facebook!",
    facebookText: "@ {username} على Facebook!",

    aboutHeading: "معلومات العمل",
    phoneText: "هاتف",
    mailText: "بريد إلكتروني",
    addressText: "عنوان",

    appointment: {
      // Tab
      tab: "قم بإنشاء موعد",
      title: "إنشاء طلب موعد",

      nameInputLabel: "اسمك",
      surnameInputLabel: "اللقب الخاص بك",
      phoneInputLabel: "جوال",
      serviceInputLabel: "اختيار الخدمة",
      serviceInputHint: "اختر من بين الخدمات",
      staffInputLabel: "اختيار الموظف",
      staffInputHint: "حدد الموظف الذي سيعتني بك في موعدك",
      staffInputCompanyHint:
        "حدد الموظف الذي سيعتني بك في موعدك. (في قائمة الموظف ، يتم سرد الموظفين الذين يقدمون الخدمة التي حددتها.)",
      dateInputLabel: "تاريخ الموعد",
      dateInputHint:
        "يمكنك إما كتابة تاريخ الموعد يدويًا أو تحديده بالنقر فوق رمز التقويم.",

      availableHoursButtonTitle: "البحث في الساعات المتاحة",

      check: {
        // Modal
        title: "إرسال طلب موعد",
        confirmButtonTitle: "أجل",
        discardButtonTitle: "لا",

        alertBoldText: "عزيزي {name} {surname}",
        alertText: "هل توافق على إنشاء موعدك في {date} ، الوقت {time}؟",

        priceText:
          "تم تحديد الرسوم العامة للمعاملة {service} التي اخترتها على أنها {amount} ₺ بواسطة الشركة.",
        priceBoldText:
          "يتم تقديم هذا السعر لأغراض إعلامية ولا يشكل مبلغًا محددًا للمعاملة.",

        noteInputLabel: "ملاحظة موعد (اختياري)",
        noteInputHint: "٪ s / 255 حرفًا",
      },

      insertToast: "تم إنشاء طلب موعدك بنجاح.",

      appointment_button: "إحجز موعد",

      appointmentComingText: "هل ستحضر موعدك المحدد في %s؟",
      appointmentConfirmationText: "تم تأكيد موعدك. شكرًا لك.",
      appointmentCancelingText1: "سيتم إلغاء موعدك. هل أنت متأكد؟",
      appointmentCancelingText2: "تم إلغاء موعدك. شكرًا.",
      appointmentDatePostponeText: "هل ترغب في إعادة جدولة موعدك إلى تاريخ آخر؟",
      businessPhoneText: "هاتف العمل: ",
      getDirectionText: "احصل على الاتجاهات",
      appointmentRatingSuccessToast: "شكرًا لك على تخصيص الوقت لتقييمنا!",
      appointmentRatingWarnToast: "حقل تقييم العمل وتقييم الموظف إلزامي!",
      evaluationSurverTitle: "استبيان التقييم",
      evaluationSurverSubtitle: "استبيان تقييم رضا العملاء",
      evaluationSurverText1: "هذا الاستبيان هو مساحة يمكنك من خلالها التعبير عن مستوى رضاك عن العمل وموظفيه.",
      evaluationSurverText2: "سيتم إخفاء اسمك ولقبك وإرساله إلى العمل وفقًا لمبدأ الخصوصية.",
      evaluationSurverQuestion1: "قيم العمل على مقياس من 0 إلى 5",
      evaluationSurverQuestion2: "قيم الموظف/الموظفين على مقياس من 0 إلى 5",
      evaluationSurverInputLabel: "اكتب تجاربك وآرائك",
      routeToBusinessURL: "يتم توجيهك إلى صفحة العمل",

      appointmentHourErrorTitle: "*لم يتم العثور على وقت مناسب للمواعيد في التاريخ المحدد.",
      appointmentAccept: "تأكيد الموعد",
      appointmentAcceptCodeText: "يرجى إدخال رمز التأكيد المكون من 4 أرقام المرسل إلى هاتفك لتأكيد موعدك.",
      wrongCodeErrorText: "رمز غير صالح",
      sendAgainText: "إعادة إرسال رمز التأكيد",
    },

    servicesGallery: {
      title: "معرض الخدمة",
    },

    services: {
      // Tab
      tab: "قائمة الخدمات",
      title: "قائمة الخدمات",
      processTime: "وقت المعالجة: {time} دقيقة",
    },

    staffs: {
      //Tab
      tab: "قائمة موظف",
      title: "قائمة موظف",
    },

    comments: {
      // Tab
      tab: "تعليقات العملاء",
      title: "تعليقات",
    },
  },

  "support/live_support": {
    liveSupportRequestTitle: "طلب دعم مباشر",
    appointmentDate: "تاريخ ووقت الموعد",
    appointmentRequestTitle: "طلبات الدعم",
    createAppointmentButtonTitle: "إحجز موعد",

    enterPhoneLabel: "يرجى إدخال رقم الهاتف",
    name: "اسمك",
    surname: "اللقب الخاص بك",
    supportrequestsubject: "موضوع طلب الدعم",
    supportrequestdetail: "تفاصيل طلب الدعم",
    hours: "ساعة",

    nameInputLabel: "اسم",
    nameInputHint: "من فضلك أدخل إسمك",

    surnameInputLabel: "الكنية",
    surnameInputHint: "الرجاء إدخال اسمك الأخير",

    phoneNumberInputLabel: "رقم الهاتف",

    supportRequestAboutLabel: "موضوع طلب الدعم",
    supportRequestAboutHint: "اكتب موضوع طلب الدعم",

    dateInputLabel: "تاريخ الموعد",
    noAvailableHours: "لا يوجد وقت للاختيار من بينها.",

    supportRequestDetailLabel: "تفاصيل طلب الدعم",
    supportRequestDetailHint:
      "يرجى تقديم شرح مفصل حول المشكلة التي تطلب الدعم لها.",
    supportRequestDetailHelperText:
      "يرجى شرح الإجراءات التي اتخذتها بالتفصيل بشأن هذا الموضوع والمشكلة التي واجهتها. ستمكننا التفاصيل التي تقدمها من مساعدتك بأسرع الطرق وأكثرها دقة.",

    appointmentCancelDescription:
      "هام: يرجى تذكر إلغاء موعدك إذا تم حل مشكلتك قبل وقت الدعم.",

    headings: {
      appointmentDate: "تاريخ الموعد",
      appointmentAbout: "موضوعات",
      appointmentDetail: "التفاصيل",
      name: "اسم اللقب",
      phoneNumber: "هاتف",
      appointmentStatus: "حالة التعيين",
      cancelButtonTitle: "يلغي",

      replacements: {
        status: {
          waiting: "انتظار",
          done: "ناقش",
          cancel: "تم إلغاؤه",
        },
      },
    },

    alertInfo: {
      text1:
        " سوف نتصل بك على 02121060510 في الوقت الذي تختاره. من فضلك على الرغم من الحاجة للاتصال عن بعد",
      text2: "برنامج الاتصال عن بعد ALPEMIX",
      text3: "قم بتنزيله وتشغيله. في اي وقت تريد",
      text4: "العملاء السلبيون",
      text5: "يمكنك الوصول إلى معلومات العميل من علامة التبويب.",
    },

    createLiveSupportRequestToast: "تم إنشاء طلب موعدك بنجاح.",
    cancelLiveSupportRequestToast: "تم إلغاء طلب الموعد بنجاح.",
  },

  /**
   * Birden fazla yerde kullanılan sabit tanımlar bu alanda yazılmaktadır.
   */
  component: {
    actionHeadingText: "المعاملات",
    actionHeadingTextRating: "إجراءات التعليق",
    detailEditButtonTitle: "التفاصيل والتحرير",
    validationErrorText: "قم بتحرير الحقول بالتنسيق المطلوب",
    loadingText: "تحميل...",
    loadingTextWithoutDot: "تحميل",

    "customer/customer_detail": {},

    smartBanner: {
      // Loginde mobil uygulama yayında banner'ı
      onAirText: "تطبيقنا مباشر!",
    },

    timerButton: {
      waitText: "انتظر",
    },

    excelButton: {
      title: "تنزيل بتنسيق Excel",
    },

    datePickerInput: {
      // Datepicker Input
      cancelLabel: "إلغاء",
      clearLabel: "ينظف",
      okLabel: "إكمال",
    },

    dateRangeStats: {
      // Dashboard'daki istatistik kartları
      //       يوميًا
      // أسبوعي
      // شهريا
      today: "يوميًا",
      week: "أسبوعي",
      month: "شهريا",
      lastWeek: "اخر 7 ايام",
      lastMonth: "آخر 30 يومًا",
      yesterday: "أمس",
      last_15_days: "آخر 15 يومًا",
    },

    CADialog: {
      // Customer Add Dialog
      title: "إنشاء سريع للعميل",
      createButtonTitle: "إنشاء العميل",
      discardButtonTitle: "يستسلم",

      nameInputLabel: "اسم الزبون",
      nameInputPlaceholder: "أدخل اسم العميل",

      surnameInputLabel: "الاسم الأخير للعميل",
      surnameInputPlaceholder: "أدخل لقب العميل",

      phoneInputLabel: "جوال",

      updateCustomerDetails: "تحديث العميل",
      changeCutomertoActive: "تنشيط العميل",
      changeCutomertoPassive: "قم بتعطيل العميل",

      genderInputLabel: "جنس",
      genderMale: "الولد",
      genderFemale: "النساء",

      birthdayInputLabel: "تاريخ الميلاد",
      birthdayInputInvalidMessage: "الرجاء إدخال التنسيق المطلوب. يوم شهر سنة",

      maritalStatusInputLabel: "الحالة المدنية",
      maritalStatusMarried: "متزوج",
      maritalStatusSingle: "أعزب",

      delete: {
        title: "هل تؤكد الحذف؟",
        confirmButtonTitle: "أجل",
        discardButtonTitle: "لا",
        alertText: "هذه العملية لا رجوع فيها!",
        alertTitleText:
          "إذا قمت بحذف هذا العميل ، فقد لا تتمكن من الوصول إلى معلومات العميل مرة أخرى.",
      },

      checkingForeingNumber: "وضع علامة كرقم أجنبي",

      confirmChangeCustomerTitle: "هل توافق على الضبط السلبي؟",
      confirmCustomerTitle:
        "إذا قمت بإلغاء تنشيط هذا العميل ، فلن تتمكن من رؤية العميل مرة أخرى في مجالات مثل المواعيد / الفواتير.",
      confirmTitle1: "في اي وقت تريد",
      confirmTitle2: "العملاء السلبيون",
      confirmTitle3: "يمكنك الوصول إلى معلومات العميل من علامة التبويب.",

      secondPhoneInputLabel: "رقم الهاتف الثاني",
      secondPhoneInputPlaceholder: "إذا كان متاحًا ، أدخل رقم الهاتف الثاني.",

      mailInputLabel: "عنوان البريد الالكتروني",
      mailInputPlaceholder:
        "إذا كان متاحًا ، أدخل عنوان البريد الإلكتروني الخاص بك.",

      descriptionInputLabel: "شروح",

      workStatusInputLabel: "حالة العمل",
      workStatusWorking: "عمل",
      workStatusUnemployed: "لا يعمل",
      workStatusEmployer: "صاحب العمل",
      workStatusStudent: "التلميذ",
      workStatusHouseWife: "سيدة منزل",

      relatedBusinessInputLabel: "المؤسسة ذات الصلة",
      relatedBusinessInputPlaceholder:
        "يرجى تحديد المؤسسة التي جاء منها العميل.",

      sendSmsInputLabel: "حالة إرسال الرسائل القصيرة",
      sendSmsTrue: "ارسلها",
      sendSmsFalse: "لا ترسل",

      advancedOptions: "خيارات متقدمة",
      advancedOptionsHide: "إخفاء",
      advancedOptionsShow: "يعرض",

      insertToast: "تم إنشاء تسجيل العميل بنجاح!",
    },

    appointment_notification: {
      description: "لديك موعد جديد. نتمنى لكم عمل جيد.",
      closeButtonTitle: "حسنا",
      appointmentsButtonTitle: "تعيينات",
    },

    allServicesDialog: {
      serviceCategoryTitle: "فئات الخدمة",
      selectedServicesTitle: "الخدمات التي تختارها",
      loading: "تحميل...",
      noSelectedService: "لا توجد خدمة مختارة.",
      notFoundService: "لا يمكن العثور على الخدمة التي تبحث عنها.",
      transferButtonTitle: "نقل الخدمات المختارة",

      successToast: "تم نقل خدماتك بنجاح.",
    },

    sms_review: {
      platformTitle: "المنصة المراد إرسال الرسالة عليها",
      smsReviewTitle: "معاينة الرسالة",
      totalNumberTitle: "إجمالي عدد الأشخاص المراد إرسالهم:",
      totalWpCreditTitle: "إجمالي رصيد الواتساب:",
      totalSMSCreditTitle: "إجمالي رصيد الرسائل القصيرة:",
      smsContentTitle: "محتوى الرسالة:",

      inUseSMSCredit: "إجمالي أرصدة الرسائل القصيرة للاستخدام:",
      inUseWPCredit: "إجمالي رصيد WhatsApp للاستخدام:",

      sendButtonTitle: "يرسل",
      discardButtonTitle: "يلغي",
    },

    staffOrderDialog: {
      staffOrderTitle: "فرز الموظفين",
      closeButtonTitle: "أغلق",
    },

    NADDialog: {
      createAppointment: "قم بإنشاء موعد",
    },

    OARDialog: {
      title: "طلبات المواعيد عبر الإنترنت",
    },

    filterAppointmentDialog: {
      title: "تصفية المواعيد",
    },

    dayWeekButton: {
      day: "يوم",
      week: "أسبوع",
    },

    semiCirclePie: {
      noDisplayChartDescription:
        "لا يتم عرض الرسم البياني لأن القيم تساوي الصفر.",
    },
  },

  panel: {
    businessNumber: "رقم العمل",
    licenseRemaining: "ايام متبقية",
    sms_credit: "رسالة قصيرة",
    wp_notConnected: "الجهاز غير متصل",
    searchCustomer: "البحث عن عميل",
    remainingAppointment: "غادر الموعد",
    changeLanguage: "تغيير اللغة",
  },

  panelTabs: {
    moreFavoriteErrorToast: "يمكنك إضافة ما يصل إلى 7 مفضلات.",
  },
  licenseDetails: {
    currentPackage: "(الحزمة الحالية)",
    features: "الميزات",
    silver: [
      "حتى 3 موظفين",
      'هدية خاصة "50 موعدًا" للأعضاء الجدد',
      'هدية خاصة "100 رسالة قصيرة" للأعضاء الجدد',
      "100 موعد جديد فقط ب 119₺",
    ],
    gold: [
      "حتى 5 موظفين",
      "عدد غير محدود من المواعيد",
      "إدارة عدة فروع",
      "ممثل خاص للعملاء",
      "معرض الأعمال",
      "إرسال الرسائل القصيرة",
      'هدية خاصة "250 رسالة قصيرة" للأعضاء الجدد',
    ],
    platinum: [
      "حتى 7 موظفين",
      "عمليات الفاتورة",
      "إدارة الحزم",
      "إدارة المخزون",
      "عمليات العلاوة",
      'هدية خاصة "500 رسالة قصيرة" للأعضاء الجدد',
      "تقارير متقدمة",
      "عدد غير محدود من المواعيد",
      "موقع على الإنترنت لحجز المواعيد",
      "إدارة عدة فروع",
      "ممثل خاص للعملاء",
    ],
    diamond: [
      "حتى 10 موظفين",
      "إضافة توقيع إلكتروني",
      "إدارة العقود",
      "عمليات الفواتير",
      "إدارة الحزم",
      'هدية خاصة "2000 رسالة قصيرة" للأعضاء الجدد',
      "إدارة المخزون",
      "عمليات العلاوة",
      "تقارير متقدمة",
      "مواعيد غير محدودة",
      "موقع الويب للحجز عبر الإنترنت",
      "إدارة عدة فروع",
      "ممثل خاص للعملاء",
      "تكامل مع WhatsApp",
      "قارئ الباركود",
      "رابط تأكيد الحضور للمواعيد",
      "رابط الحجز عبر الإنترنت",
    ],
    includesAllLicence: [
      "إدارة الأعمال",
      "إدارة العملاء",
      "إدارة الموظفين",
      "إرسال رسائل قصيرة",
      "تذكير بالمواعيد",
      "إدارة المواعيد",
      "معرض الأعمال",
      "قائمة المهام",
    ],

    packageNames: {
      goldM: "حزمة الذهب (شهر واحد)",
      goldY: "حزمة الذهب (12 شهرًا)",
      platM: "حزمة البلاتين (شهر واحد)",
      platY: "حزمة البلاتين (12 شهرًا)",
      diaM: "حزمة الألماس (شهر واحد)",
      diaY: "حزمة الألماس (12 شهرًا)",
    },

    renewalOpportunity: "فرصة تجديد الترخيص الخاصة بك",
    licenseTooltipGold: "مناسب للشركات التي ترغب في إدارة المواعيد",
    licenseTooltipPlat: "مناسب للشركات التي ترغب في إدارة المواعيد، المحاسبة الأولية، والعمولات",
    licenseTooltipDia: "مناسب للشركات التي ترغب في إدارة العقود، المواعيد، المحاسبة الأولية، والعمولات",
    allSpecs: "عرض جميع الميزات",
    worth: "الأرباح",
    monthlyPaymentTitle: "/ شهريًا",
    buyNowTitle: "اشتري الآن",
    moreDesc: "احصل على المزيد من المعلومات",
    allFeatures: "جميع الميزات",
    renewalTitle: "لا تفوت فرصة تجديد الترخيص!",
    yearly: "سنويًا",
    yearlyTitle: "دفع (12 شهرًا)",
    buyDetailsTitle: "تفاصيل الشراء",
    payNowTitle: "ادفع الآن",
    paymentPage: "الأسعار على صفحة الدفع",
    taxNotIn: "ضريبة القيمة المضافة غير مشمولة.",
    taxIn: "+مبلغ ضريبة القيمة المضافة",

    lastDate: "آخر %s أيام",

    firstBuyText: "عرض خاص لأول عملية شراء",
    discountText: " أسعار مخفضة!",
    goldPlusText: "هل ترغب في الاستفادة من ميزات Gold Plus؟",
    platPlusText: "هل ترغب في الاستفادة من ميزات Platinium Plus؟",
    plusSpecsTitle: "ميزات حزمة PLUS",
    instead: "بدلاً من",
    only: "فقط",
    takeOffTitle: "إزالة",

    wpIntegrationTitle: " تكامل WhatsApp",
    appointmentLinkTitle: "رابط لحضور الموعد",
    thousandCreditGiftTitle: "هدية 1000 SMS-WhatsApp!",
    thousandCreditTitle: "1000 SMS - WhatsApp",
    barcodeReaderTitle: "قارئ الباركود",
  },
  tutorial: {
    description: "أكمل المهام واحصل على الهدايا",
    is_success: "جمع المكافآت",
    title: "مهام",
    button_wheel: "تدور العجلة",
    wheel_title: "أدر العجلة واربح هديتك!",
    wheel_prize: "لقد فزت: أكمل المهام لتحصل على المكافأة!",
  },
  campaigns: {
    footer: {
      salonAPP: "#1 تطبيق صالون",
      title1: "إذا كنت تبحث عن الجودة والموثوقية في إدارة الصالون،",
      title2: "فأنت في المكان الصحيح.",
      getInfo: "أريد الحصول على مزيد من المعلومات",
      askWp: "اسأل عبر WhatsApp",
      wouldLike: "هل تود أن نخبرك عن مزايا .app المميزة؟",
      name: "اسمك",
      surname: "اسم العائلة",
      phone: "رقم هاتفك",
      companyName: "اسم شركتك",
      callMe: "اتصل بي",
      sendMessageErrorToast: "فشلت عملية الإرسال.",
      sendMessageWarnToast: "يرجى إدخال الاسم الأول والاسم الأخير أو رقم الهاتف بشكل كامل.",
    },
    header: {
      title1: "الجمال لصالونك",
      title2: "هنا",
      title3: `سواء كنت في المنزل أو في الإجازة أو في الصالون أو في السيارة أو في أي مكان آخر،
               مع منصة إدارة الصالون الأكثر شمولاً في تركيا، تطبيق Salon Randevu،
               يسير كل شيء كما تريد. يمكنك إدارة المواعيد والتحكم في الموظفين
               والتحقق من المخزون وإرسال رسائل SMS مخصصة لعملائك. كل شيء هنا
               مصمم ليسهل عليك إدارة صالونك.`,
      title4: `عن طريق اختيار إحدى الحزم المناسبة لك أعلاه، يمكنك الاستفادة من
               المحتوى المخصص لك.`,
    },
    aboutApp: [
      {
        title: "صفحة حجز الصالون",
        context:
          "وفّر لعملائك إمكانية حجز المواعيد عبر الإنترنت من خلال صفحة الحجز الخاصة بصالونك. تخلّص من قيود ساعات العمل ومنع فقدان العملاء المحتملين باستخدام نظام الحجز عبر الإنترنت.",
      },
      {
        title: "إدارة العملاء",
        context:
          "سهّل تتبع العملاء من خلال إنشاء ملفات تعريف مفصلة وسجل مواعيد لكل عميل. عزّز ولاء العملاء من خلال تقديم خدمات مخصصة لكل منهم.",
      },
      {
        title: "إدارة المحاسبة",
        context:
          "احتفظ بوضعك المالي تحت السيطرة أثناء تسجيل الدخل والمصروفات. كل شيء سيكون تحت سيطرتك مع تقارير المبيعات اليومية والشهرية، والذمم المدينة، ومكافآت الموظفين، والمخزون، ومبيعات المنتجات.",
      },
      {
        title: "إدارة المواعيد",
        context:
          "منع الخسائر المالية الناتجة عن المواعيد الفائتة وتوفير الوقت من خلال هذا الأتمتة. إنه ميزة يجب أن يستخدمها أولئك الذين يقدرون كل دقيقة.",
      },
      {
        title: "تطبيق الجوال",
        context:
          "قم بإدارة صالونك على مدار الساعة باستخدام تطبيق Salon Randevu APP سواء كنت على الكمبيوتر أو في طريقك.",
      },
      {
        title: "التقارير المتقدمة",
        context:
          "مع التقارير المتقدمة، يمكنك عرض وتقييم أداء عملك في الفترات اليومية والأسبوعية والشهرية، وإجراء المقارنات اللازمة.",
      },
      {
        title: "رسائل واتساب",
        context:
          "أصبح من الممكن الآن إرسال تذكيرات بالمواعيد، واستطلاعات، وإشعارات عبر واتساب بدلاً من الرسائل النصية.",
      },
      {
        title: "العقود الإلكترونية الموقعة",
        context:
          "تخلّص من العقود الورقية واحفظ عقودك مع كافة التفاصيل لكل عميل واحصل على توقيعات رقمية.",
      },
      {
        title: "نظام الفواتير",
        context:
          "يوفر نظام إدارة الفواتير المتقدم لمستخدمينا إمكانية عرض ومتابعة المدفوعات لعمليات البيع بالحزمة أو الخدمات أو بيع المنتجات على صفحة واحدة.",
      },
      {
        title: "تقارير الموظفين",
        context:
          "مصدر دخلك الأساسي هو الوقت. لزيادة الدخل إلى الحد الأقصى، ترغب في أن يقدم موظفوك أفضل خدمة دائماً. من الضروري التركيز بنسبة 100% وتقديم الخدمة لتصبح شركة مثالية.",
      },
      {
        title: "إشعارات الرسائل النصية",
        context:
          "جعل تطبيق Salon Randevu APP التذكيرات سهلة وممتعة. أرسل التذكيرات بسهولة وسرعة للتأكد من أن عملائك لا يفوتون أي موعد.",
      },
      {
        title: "إدارة المخزون",
        context:
          "تابع مبيعات المنتجات وحالة المخزون باستخدام تطبيق Salon Randevu وتجنب الأخطاء. حافظ على بياناتك محدثة ودقيقة باستخدام التحكم في المخزون، وتابع مبيعات منتجاتك من خلال التقارير اليومية والشهرية.",
      },
    ],
    sayAboutUs: {
      title1: "ما يحبه مستخدمونا أكثر",
      title2: "الميزات",
      title3: "تطبيق ويب وجوال مصمم بشكل رائع وسهل الاستخدام",
      title4: "قم بإدارة أعمالك بسهولة باستخدام الميزات الغنية",
      title5: "دعم مباشر على مدار الساعة طوال أيام الأسبوع كلما احتجت إليه",
      title6: `"في عطلات نهاية الأسبوع، أو في المساء، أو عندما كنت خارج المركز،
              كان حجز موعد يعني دائمًا حمل دفتر وقلم. الآن، الدفتر
              والقلم خرجا تمامًا من حياتي؛ أستطيع حجز موعد
              من هاتفي على الفور وعلى مدار 24/7، وهو راحة
              كبيرة بالنسبة لي. من وجهة نظر عملائي، منذ لحظة
              الانتقال إلى النظام، لاحظت تغييرًا كبيرًا في الصورة،
              وقالوا لي إن هذا البرنامج احترافي للغاية..."`,
      title7: `"منذ أن بدأنا عضويتنا في صالون المواعيد، كان
              الفريق بأكمله متعاونًا جدًا وكانوا يقدمون المساعدة
              في كل شيء. أود أن أشكر السيدة إيليف بشكل خاص على
              جهودها وتفانيها. لقد قللت البرنامج بشكل كبير
              من عبء العمل لدينا، وأنا أوصي به بشدة."`,
      title8: `"برنامج رائع، التطبيق ناجح جدًا:
              يعمل بسلاسة في المواعيد، سجلات العملاء،
              المحاسبة، ومع ميزاته الواسعة. إنه
              لا غنى عنه، وأنا أوصي به بشدة."`,
    },
    featureOffApp: {
      head: "ميزات التطبيق",
      title1: "الميزات الأكثر استخدامًا في تطبيق حجز الصالونات",
      title2: "صفحة حجز الصالونات",
      title3: "إدارة العملاء",
      title4: "إدارة المحاسبة",
      title5: "إدارة المواعيد",
      title6: "تطبيق الهاتف المحمول",
      title7: "تقارير متقدمة",
      title8: "رسائل WhatsApp",
      title9: "عقد موقّع إلكترونيًا",
      title10: "نظام الفواتير",
      title11: "تقارير الموظفين",
      title12: "إشعارات SMS",
      title13: "إدارة المخزون",
    },
    statistics: {
      title1: "الأفضل",
      title2: "البرمجيات",
      title3: "الدعم المباشر الفوري",
      title4:
        "فريقنا جاهز لمساعدتك في أي وقت! يمكنك التواصل معنا 24/7 عبر بيانات الاتصال الخاصة بنا.",
      title5: "سهولة الاستخدام",
      title6:
        "تعرف على راحة التحكم في جميع معلومات صالونك في أي وقت عبر لوحة الويب أو التطبيق المحمول.",
      title7: "100% أمان",
      title8:
        "بياناتك آمنة معنا! نحن نحفظ ونحمي جميع المعلومات المتعلقة بصلونك وبك بأمان.",
      title9: "العقد بتوقيع إلكتروني",
    },
  },
};

export default langAR;
